import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Container, CssBaseline } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js';
import Foot from '../../Control/Header-Footer/foot.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import './stylesPodcats.css'
import PlayProgress from '../Extras/Banner.js'

export default function Index() {
  const [widthResize, setWidthResize] = useState(window.innerWidth)


  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    height: 50,
    marginLeft: '15px',
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
    fontStyle: 'normal',
    fontFamily: 'Sofia Sans'
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });



  useEffect(() => {
    window.addEventListener('resize', resize);
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <CssBaseline />
      <div align="center">
        {widthResize > 750 ? <Header post={"Inicio"} /> : <HeaderMovil />}
      </div>
      <PlayProgress />
      <br />
      <Container color='#fff'>
        <div>
          <font style={{ fontFamily: 'Sofia Sans', align: 'center' }} size="6" color="#4EBBB7"><strong>Ciencia&Esencia en el 1er foro de Divulgación de la Ciencia, Tecnología e Innovación del Cauca</strong></font>
        </div><br />
        <Container color='#fff'>
          <div >
            <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E">Sabemos que una parte fundamental en la generación de conocimiento, es el compartir experiencias y saberes con actores desde diferentes perspectivas y contextos. </font><br />
          </div><br />
          <div >
            <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E">Es por eso que este 06 de octubre 2023, en la Casa de la Moneda, Popayán, podremos disfrutar de un foro, talleres gratuitos y exposiciones alrededor de la CTeI. Entrada totalmente libre.</font><br />
          </div>
          <br />

          <br /><br />

          <div >
            <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E"> <strong> Entrada totalmente libre.</strong></font><br />
          </div>
        </Container>
        {
          widthResize > 750 ?
            <div align='center'>
              <iframe title="Diplomado" width='70%' height="455" src={'https://www.youtube.com/embed/cNJIwgnRUuo'}></iframe> </div> :
            <iframe title="Diplomado" width='100%' height="255" src={'https://www.youtube.com/embed/cNJIwgnRUuo'}></iframe>

        }

        <Accordion defaultExpanded={true}>
          <AccordionSummary         >
            <ExpandMoreIcon sx={{ color: '#23088E' }} />
            <Container >
              <font className='subtitle' size="5.3" color="#23088E"><strong>Ir a página oficial del evento para participar de los talleres y foro</strong></font>
            </Container>
          </AccordionSummary>
          <AccordionDetails>
            <Container >

              <ThemeProvider theme={customTheme}>
                {widthResize > 750 ?
                  <BootstrapButton variant="contained" target='_blank' href="https://valeriafierro1.wixsite.com/foro-de-la-ciencia">
                    Ver página del evento
                  </BootstrapButton>
                  : 
                  <BootstrapButton variant="contained" target='_blank' href="https://valeriafierro1.wixsite.com/foro-de-la-ciencia">
                    Ver página del evento
                  </BootstrapButton>}
              </ThemeProvider>


            </Container>
            <br />
          </AccordionDetails>
        </Accordion>
        <Card>
          <Container>
            <br /><br />
            <div>
              <font className='textCont' size="5" color="#4EBBB7"> <strong> ¿Cómo me puedo inscribir?</strong></font>  <br />
              <font className='textCont' size="5" color="#23088E"> <strong> Quiero participar de los talleres:</strong> debes ingresar al portal web oficial del evento e inscribirte en los talleres que desees. Cupos limitados.</font><br /><br />
              <font className='textCont' size="5" color="#23088E"> <strong> Solo quiero asistir al foro y ver las exposiciones:</strong> no requiere de inscripción previa. </font><br />
            </div>
            <br /><br />
            <div>
              <font className='textCont' size="5" color="#4EBBB7"> <strong> ¿De qué espacios puedo participar?</strong></font> <br />
              <font className='textCont' size="5" color="#23088E"><strong> Todos los participantes podrán acceder a:</strong></font> <br />
              <font className='textCont' size="5" color="#23088E"> <strong>-</strong> Foro con personas invitadas a nivel nacional y de todos los municipios del Cauca.</font><br />
              <font className='textCont' size="5" color="#23088E"><strong>-</strong> Stand con exposiciones de proyectos de investigación en instituciones educativas de Popayán.<br />
                
              </font>

            </div>
            <br /><br />
            <div>
              <font className='textCont' size="5" color="#4EBBB7"> <strong>¿Cuánto cuesta participar? </strong></font><br />
              <font className='textCont' size="5" color="#23088E">El ingreso al evento es totalmente libre para todo público y no requiere de inscripción previa. No se va a solicitar a los participantes dinero en el proceso de inscripción ni durante el evento. </font>

            </div>
            <br /><br />
          </Container>
        </Card>

      </Container>

      {widthResize > 750 ? <Foot /> : <FootMovil />}
    </div>
  );
}
