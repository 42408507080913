import React, { useEffect, useState } from 'react';
import { Button, Container, CssBaseline, Grid, InputLabel, Pagination, Stack } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js';
import Foot from '../../Control/Header-Footer/foot.js';
import logo from '../../Imagenes/Eslogandelproyecto.png';
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import PodCats from './DataPodCast.js';
import PodCatsMovil from './DataPodCastMovil.js';
import { Subregion } from '../Extras/Referencias.js'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import './stylesPodcats.css'
import axios from 'axios';
import { SubRegionUrl, PodcastUrl, compUrls } from '../../Control/Autentication/urlsServer.js';
import PlayProgress from '../Extras/Banner.js'

export default function Index() {

  const [featuredPosts, setFeaturedPosts] = useState([])
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const [indexlist, setindexlist] = useState(0)
  const [DataIndex, setDataIndex] = useState(0)

  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const [dataSubregion, setDataSubregion] = useState('')
  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    height: 50,
    top: 28,
    marginLeft: '15px',
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
    fontStyle: 'normal',
    fontFamily: 'Sofia Sans'
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });

  const GetPublicationsSubregiones = async () => {
    let listdata = []
    let listPodcats = []
    let listNumber = 0
    if (dataSubregion !== 'Cauca') {
      await axios.get(SubRegionUrl + dataSubregion, compUrls)
        .then(response => {
          listNumber = response.data.length / 3
          listPodcats = response.data.reverse()
          for (let index = 0; index < listNumber; index++) {
            listdata.push(listPodcats.splice(0, 3));
          }
          setDataIndex(listdata.length)
          setFeaturedPosts(listdata)
        });
    }
    else {
      GetPublications();
    }
  }

  const GetPublications = async () => {
    let listdata = []
    let listPodcats = []
    let listNumber = 0
    await axios.get(PodcastUrl, compUrls)
      .then(response => {
        listNumber = response.data.length / 3
        listPodcats = response.data.reverse()
        for (let index = 0; index < listNumber; index++) {
          listdata.push(listPodcats.splice(0, 3));
        }
        setDataIndex(listdata.length)
        setFeaturedPosts(listdata)
      });
  }


  const onChangePagination = (e) => {
    setindexlist(e.target.innerText - 1);
  }

  const handleChange = () => {
    setDataSubregion(document.getElementById("selecteList").value)
  }


  useEffect(() => {
    GetPublications();
    window.addEventListener('resize', resize);
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <CssBaseline />
      <div align="center">
        {widthResize > 750 ? <Header post={"PodCats"} /> : <HeaderMovil />}
      </div>
      <PlayProgress />
      <br />
      <Container color='#fff'>
        {widthResize > 750 ?
          <div style={{ margin: '16px 1px -3% 20%' }}>
            <img src={logo} width='790' alt='1' />
            <br /><br />
          </div> :
          <div style={{ marginLeft: '-20px' }}>
            <img src={logo} width='490' alt='1' />
          </div>
        }
        <div>
          <font style={{ fontFamily: 'Sofia Sans' }} size="6" color="#23088E"><strong>La ciencia en un Podcast</strong></font>
        </div>
        <div >
          <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E">Conoce los saberes ancestrales y la ciencia en el territorio del departamento del Cauca, en compañía de comunidades Afro, Indígenas y Campesinas. </font><br />
        </div>
        <br /><br />
        <div className='topMenuInputs'>
          <div>
            <InputLabel shrink >
              <font style={{ fontFamily: 'Sofia Sans' }} className="inputLabel" size="6">Subregión</font>
            </InputLabel>
            <select
              id='selecteList'
              className='selecteList'
              defaultValue="Cauca"
              style={{ fontFamily: 'Sofia Sans' }}
              onChange={handleChange}
            >
              {Subregion.map((options, i) => (
                <option
                  key={i} value={options}>
                  {options}
                </option>
              ))}
            </select>
          </div>
          <ThemeProvider theme={customTheme}>
            {widthResize > 750 ?
              <BootstrapButton onClick={GetPublicationsSubregiones} variant="contained" >
                Buscar
              </BootstrapButton>
              : <BootstrapButton onClick={GetPublicationsSubregiones} variant="contained" >
                Buscar
              </BootstrapButton>}
          </ThemeProvider>
        </div>
        <br /><br /><br />
        {widthResize > 750 ?
          featuredPosts.length !== 0 ? (
            <Grid style={{ fontFamily: 'Sofia Sans' }} container spacing={1}>
              {featuredPosts[indexlist].map((post, i) => (
                post.disablePublication === true ?
                  <PodCats key={i} post={post} index={post.id} /> : ""
              ))}
            </Grid>)
            : ""
          :
          featuredPosts.length !== 0 ? (
            <div>
              {featuredPosts[indexlist].map((post, i) => (
                post.disablePublication === true ?
                  <div>
                    <PodCatsMovil key={i} post={post} index={post.id} />
                    {i + 1 !== featuredPosts.length ?
                      <div>
                        <div style={{ background: '#4EBBB7', color: '#fff', height: '5px' }}>.</div>
                        <br /><br />
                      </div>
                      : ""}
                  </div> :
                  ""
              ))
              }
            </div>)
            : ""
        }
      </Container>

      <div style={{ marginLeft: widthResize > 750 ? '45%' : '35%' }}>
        <Stack spacing={2}>
          <Pagination hideNextButton={true} hidePrevButton={true} count={DataIndex} variant="outlined" color="secondary" onChange={onChangePagination} />
        </Stack>
      </div>
      {widthResize > 750 ? <Foot /> : <FootMovil />}
    </div>
  );
}
