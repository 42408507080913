import React, { useState } from 'react';
import { Button, CircularProgress, Container, IconButton } from '@mui/material';
import { Baneer } from '../../Control/Autentication/urlsServer';
import MmsIcon from '@mui/icons-material/Mms';
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axios from 'axios';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const [estadoConsulta, setEstadoConsulta] = useState(false)

  const handleChangeRegisterfile = e => {
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }
    ))
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  const PostMaterial = async () => {
    await axios.post(Baneer, consolaSeleccionada,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
        }
      })
      .then(response => {
        console.log(response.data);
        alert("Se resgistro correctamente, actualiza la tabla")
        setEstadoConsulta(true)
        setConsolaSeleccionada({
          'imagen':null, 
          'imagenResposi':null})
      });
  }


  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <Container>         
      {estadoConsulta === true ?
          <div style={{position:'absolute', marginLeft:'20%', marginTop:'1%'}} >
            <CircularProgress size={70}/>
          </div> : ""}
        <Container >
          <div style={{marginTop:'15%', display:'inline-flex', marginLeft:'42%'}}>
            <div>
              <font position='start' size="4" color="#3A2872"> Banner Web </font><br />
              <IconButton component="label">
                <input hidden type="file" variant="standard" name="imagen" required label='imagen' onChange={handleChangeRegisterfile} />
                <MmsIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </div>
            <div style={{marginLeft:'15%'}}>
              <font position='start' size="4" color="#3A2872"> Banner responsi </font><br />
              <IconButton component="label">
                <input hidden type="file" accept="image/x-png,image/gif,image/jpeg"  variant="standard" name="imagenResposi" required label='imagen' onChange={handleChangeRegisterfile} />

                <MmsIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </div>
            </div>
            </Container>
        <br />
        <br />
        <div align="center">
          <ThemeProvider theme={customTheme}>
            <BootstrapButton disabled={estadoConsulta} onClick={PostMaterial} variant="contained">Guardar</BootstrapButton>
          </ThemeProvider>
        </div>
      </Container>
    </div>
  );
}
