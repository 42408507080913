import { Box, Card, IconButton, Modal, Button, Container, CardMedia } from '@mui/material';
import { useState } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisualizadorPodcats from './VisualizadorPodcast'
import Visualizadordatoscuruios from './VisualizadordatosCuriosos'
import Visualizadorinfografias from './Visualizadorinfografias'
import VisualizadorPodcastTop from './VisualizadorPodcastTop'
import VisualizadorMaterial from './VisualizadorMaterial'
import VisualizadorLinkinfociencia from './VisualizadorLinkinfociencia'
import VisualizadorInfoMapa from './VisualizadorInfoMapa'
import VisualizadorProyecto from './VisualizadorProyecto'
import { CommentsCteiDeleteUrl, CommentsDeleteUrl, compUrls, Base, Baneerup } from '../../Control/Autentication/urlsServer.js';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
export default function PodcastActions(rowId) {
  const type = rowId.type
  const ImagenInfo = rowId.rowId.row.imagen
  const imagenResposi = rowId.rowId.row.imagenResposi
  const [showMedia, setShowMedia] = useState(false);

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }
  
  const deleteCometario = async () => {
    var id = rowId.rowId.row.id
    if (rowId.rowId.row.type === "ctei") {
      await axios.delete(CommentsCteiDeleteUrl + id, compUrls)
        .then(response => {
          setShowMedia(!showMedia);
          console.log(response.data);
        });
    }
    else {
      await axios.delete(CommentsDeleteUrl + id, compUrls)
        .then(response => {
          setShowMedia(!showMedia);
          console.log(response.data);
        });
    }
  }

  const deleteBanner = async () => {
    var id = rowId.rowId.row.id
    await axios.delete(Baneerup + id, compUrls)
      .then(response => {
        console.log(response.data);
      });
  }


  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });

  const mediaPlay = (
    <div>
      {type !== 'Comentarios' && type !== 'material' && type !== 'Banner' ?
        <Card sx={{
          borderRadius: 5,
          width: '98%',
          overflowY: 'auto',
          height: 650,
          backgroundColor: "#fff",
          position: 'absolute',
          top: '5%',
          left: '1%'
        }}>
          <div align='end'>
            <IconButton onClick={() => { setShowMedia(!showMedia); }}>
              <CloseIcon sx={{ fontSize: 35, }} />
            </IconButton>
          </div>
          {
            type === 'Podcas' ? <VisualizadorPodcats row={rowId} new={false} /> :
              type === 'DatosCuriosos' ? <Visualizadordatoscuruios row={rowId} new={false} /> :
                type === 'infografias' ? <Visualizadorinfografias row={rowId} new={false} /> :
                  type === 'TopPodcast' ? <VisualizadorPodcastTop row={rowId} new={false} /> :
                    type === 'LinkInfociencia' ? <VisualizadorLinkinfociencia row={rowId} new={false} /> :
                      type === 'InfografiaMapa' ? <VisualizadorInfoMapa row={rowId} new={false} /> :
                        type === 'Proyectos' ? <VisualizadorProyecto row={rowId} new={false} /> : ""
          }
        </Card>
        :
        <Card sx={{
          borderRadius: 5,
          width: type === 'material' ? '50%' : type === 'Comentarios' ? '30%' : '90%',
          overflowY: 'auto',
          height: type === 'material' ? '40%' : type === 'Comentarios' ? '30%' : '100%',
          backgroundColor: "#fff",
          position: 'absolute',
          top: type !== 'Banner' ? '30%' : '0%',
          left: type === 'material' ? '25%' : type === 'Comentarios' ? '40%' : '5%'
        }}>
          <div align='end'>
            <IconButton onClick={() => { setShowMedia(!showMedia); }}>
              <CloseIcon sx={{ fontSize: 35, }} />
            </IconButton>
          </div>

          {type === 'material' ? <VisualizadorMaterial row={rowId} new={false} />
            : type === 'Comentarios' ?
              <div style={{ fontFamily: 'Sofia Sans' }}>
                <Container>
                  <div>
                    <font position='start' size="4" color="#3A2872"><strong> Gestor de Comentarios</strong> </font><br />
                    <font position='start' size="4" color="#3A2872"> Esta Seguro de borrar el comentario </font><br />
                  </div>
                  <br />
                  <div align="center" >
                    <ThemeProvider theme={customTheme}>
                      <BootstrapButton onClick={deleteCometario} variant="contained">Sí</BootstrapButton>
                      <BootstrapButton sx={{ marginLeft: '5%' }} onClick={() => { setShowMedia(!showMedia); }} variant="contained">No</BootstrapButton>
                    </ThemeProvider>
                  </div>
                </Container>
              </div> : <div style={{ fontFamily: 'Sofia Sans' }}>
                <Container>
                  <div>
                    <font position='start' size="4" color="#3A2872"><strong> Banner web</strong> </font><br />
                    
                    <CardMedia
                      height={360}
                      component="img"
                      image={Base + ImagenInfo}
                      alt={'Banner'}
                    />

                    <font position='start' size="4" color="#3A2872"><strong> Banner responsi</strong> </font><br />
                    <CardMedia
                      height={360}
                      component="img"
                      image={Base + imagenResposi}
                      alt={'Banner'}
                    />
                  </div>
                  <br />
                </Container>
              </div>
          }
        </Card>
      }
    </div>)

  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
      }}
    >
      {type !== 'Banner' ?
        <IconButton
          sx={{
            color: '#4CBAB7',
            width: 40,
            height: 40,
          }}
          onClick={() => { setShowMedia(!showMedia); }}
        >
          {type === 'Comentarios' ?
            <DeleteIcon sx={{ fontSize: 35, }} />
            :
            <VisibilityIcon sx={{ fontSize: '30px' }} />}
        </IconButton> :
        <div>
          <IconButton
            sx={{
              color: '#4CBAB7',
              width: 40,
              height: 40,
            }}
            onClick={() => { deleteBanner(); alert('Se borro correctamente, actualiza la tabla') }}
          >
            <DeleteIcon sx={{ fontSize: 35, }} />
          </IconButton>
          <IconButton
            sx={{
              color: '#4CBAB7',
              width: 40,
              height: 40,
            }}
            onClick={() => { setShowMedia(!showMedia); }}
          >
            <VisibilityIcon sx={{ fontSize: '30px' }} />
          </IconButton>
        </div>}

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>
    </Box>
  );
};


