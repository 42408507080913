import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Badge, CardMedia, IconButton, Modal, Snackbar } from '@mui/material';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Descarga from '../../Imagenes/Descarga.svg';
import CompartirPublication from './compartir.js'
import CompartirPublicationMovil from './compartirMovil'
import Comments from './Comments'
import CommentsMovil from './CommentsMovil'
import { ReactionsCteiUrl, ReactionsCteiPostUrl, Base, compUrls,imagenInfografiasUp } from '../../Control/Autentication/urlsServer';
import '../MenucTel/stylesProyecto.css'
import { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function FeaturedPost(props) {
  const { post } = props;

  const [status, setstatus] = useState(false);

  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [like, setLike] = useState(null);

  const [fileData, setfileData] = useState('');
  
  const GetimagenInfografias = async () => {
    await axios.get(imagenInfografiasUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { 
        setfileData(response.data);
      })
  }

  const [showMedia, setShowMedia] = useState(false);
  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }
  const [modalComent, setModalComent] = useState(false);
  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  const [modalCompartir, setModalCompartir] = useState(false);
  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }
  const mediaPlay = (
    <div style={{
      width: widthResize > 750 ? '40%' : 420,
      height: '90%',
      position: 'absolute',
      top: widthResize > 750 ? 50 : 310,
      left: widthResize > 750 ? '30%' : '-2%',
      borderStyle: 'solid',
      borderColor: '#fff',
      backgroundColor: "#fff",
    }}>
    <img src={Base + fileData.infografiasImagen} width='100%' height='100%' alt={post.titleinfografias} />

    </div>)
  const bodyComent = (
    <div>
      {widthResize > 750 ?
        <div>
          <Comments key={post.id} post={post} />
        </div> :
        <div style={{
          height: 700,
          position: 'absolute',
          bottom: '15%',
          right: '100%',
          borderStyle: 'solid',
        }}>
          <CommentsMovil key={post.id} post={post} />
        </div>}
    </div>
  )


  const bodyCompartir = (
    <div>
      {widthResize > 750 ?
        <div>
          <CompartirPublication key={post.id} post={post} />
        </div> :
        <div >
          <CompartirPublicationMovil key={post.id} post={post} />
        </div>}
    </div>)

  const GetRectionsinition = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsCteiUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsCteiPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const resize = () => {
    setWidthResize(window.innerWidth)
  }
  
  if (fileData === '') {
    GetimagenInfografias();
  }

  React.useEffect(() => {
    GetRectionsinition();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  });

  return (
    <div style={{ width: '105%'}} >
      <div align="center">
        <div>
          <font style={{ fontFamily: 'Sofia Sans', fontWeight: 'bold' }} color="#3A2872" size="5">{post.titleinfografias} </font>
        </div>
        <br />

        <CardMedia
          component='img'
          height='40%'
          maxHeight='40%'
          image={Base + fileData.infografiasImagen}
          alt={post.titleinfografias}
          onClick={() => widthResize > 750 ? setShowMedia(!showMedia) : ""}
          sx={{
            minWidth: '80%',
            maxWidth: '80%',
            borderRadius: 8,
            width: '80%',
            cursor: 'pointer',
            borderStyle: 'solid',
            borderColor: post.colorPublication
          }} />
        <br />
        <div >
          {cookies.get('emailVerified') === 'true' ?
            <IconButton target='_blank' href={Base + post.PublicationsFile}>
              <img src={Descarga} width='40' alt='40' />
            </IconButton> :
            <IconButton onClick={() => setOpen(!open)} target='_blank' >
              <img src={Descarga} width='40' alt='40' />
            </IconButton>}
          <IconButton onClick={abrirCerrarModalComent}>
            <img src={coment} width='40' alt='40' />
          </IconButton>

          <IconButton disabled={status} onClick={GetRections}  >
            <Badge badgeContent={like} color='error'>
              <img src={Interativo} width='40' alt='40' />
            </Badge>
          </IconButton>
          <IconButton onClick={abrirCerrarModalCompartir} >
            <img src={Compartir} width='40' alt='40' />
          </IconButton>
        </div>

      </div>
      <br />
      <Typography variant="subtitle1" paragraph>
        <font style={{ fontFamily: 'Sofia Sans', lineHeight: '1%' }} size="5" color='#3A2872' > {post.Descriptioninfografias}</font>
      </Typography>
      <div />

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert align='center' onClose={handleClose} severity="warning" sx={{ width: '80%' }}>¡Debes de iniciar sesión para descargar contenido!</Alert>
      </Snackbar>
    </div>
  );
}

export default FeaturedPost;
