import * as React from 'react';
import LogComunidad from '../../Imagenes/iconoComunidad.png'
import Logmic from '../../Imagenes/iconomic.png'
import LogMunic from '../../Imagenes/municipios.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './stylesInicio.css'

export default function foot() {
  return (
    <>
      <Card sx={{ display: 'flex' }}>
        <CardContent sx={{ flex: 1 }}>
          <div align="center" >
            <img src={LogComunidad} width='290' alt='Comunidad' />
            <div className='textInformativo' style={{ marginTop: -30 }}>
              <font className='titleInitio' size="5.5" color="#3A2872"> Comunidad de práctica</font><br />
              <font size="5" color="#3A2872">  Trabajamos con </font><br />
              <font size="5" color="#3A2872">  gestores territoriales + </font><br />
              <font size="5" color="#3A2872">  Líderes comunitarios +</font><br />
              <font size="5" color="#3A2872">  comité de radio </font><br />
            </div><br /><br />
          </div>
        </CardContent>
        <CardContent sx={{ flex: 1 }}>
          <br />
          <div align="center">
            <img src={Logmic} width='80' alt='Podcast' />
            <br />
            <div className='textInformativo'>
              <font className='titleInitio' size="5.5" color="#3A2872"> 168 Podcast producidos </font><br />
              <font size="5" color="#3A2872">  El Podcast como herramienta </font><br />
              <font size="5" color="#3A2872">  narrativa y de divulgación de</font><br />
              <font size="5" color="#3A2872">  los saberes del Cauca</font><br />
            </div>
            <br /><br />
          </div>
        </CardContent>
        <CardContent sx={{ flex: 1 }}>
          <br />
          <div align="center"> <img src={LogMunic} width='130' alt='Subregiones ' />
            <br />
            <div className='textInformativo'>
              <font className='titleInitio' size="5.5" color="#3A2872"> 7 Subregiones  del Cauca </font><br />
              <font size="5" color="#3A2872">  Investigamos y validamos los </font><br />
              <font size="5" color="#3A2872">  procesos CTel del territorio y su  </font><br />
              <font size="5" color="#3A2872">  pertinencia en la comunidad</font><br />
            </div><br /><br />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
