import React, { useState } from 'react';
import { Button, CircularProgress, Container, IconButton } from '@mui/material';
import { InfografiaMapa } from '../../Control/Autentication/urlsServer';
import MmsIcon from '@mui/icons-material/Mms';
import { Subregion } from '../Extras/Referencias.js'
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axios from 'axios';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const [estadoConsulta, setEstadoConsulta] = useState(false)

  const handleChangeRegisterfile = e => {
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }
    ))
  }
  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }
  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  const PostInfografiaMapa = async () => {
    await axios.post(InfografiaMapa, consolaSeleccionada,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
        }
      })
      .then(response => {
        console.log(response.data);
        setEstadoConsulta(false);
        alert("Se resgistro correctamente, actualiza la tabla")
        setConsolaSeleccionada({
          "Subregion": "Pacífico",
          "imagen": null,
          "imagenResponsi": null,
          "titulo": "",
        })
      });
  }


  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <Container>
      {estadoConsulta === true ?
          <div style={{position:'absolute', marginLeft:'47%', marginTop:'40%'}} >
            <CircularProgress size={70}/>
          </div> : ""}
        <Container >
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872">Titulo </font><br />
              <input className='inputs' type='text' variant="standard" name="titulo" required label='titulo' value={consolaSeleccionada && consolaSeleccionada.titulo} onChange={handleChangeRegister} />
            </div>
            <div>
                <font position='start' size="4" color="#3A2872"> SubRegion</font><br />
                <select
                  id='Subregion'
                  className='inputs'
                  name='Subregion'
                  defaultValue={consolaSeleccionada.Subregion}
                  style={{ width: '165%', fontFamily: 'Sofia Sans' }}
                  onChange={handleChangeRegister}
                >
                  {Subregion.map((options, i) => (
                    <option
                      disabled={options === 'Cauca' ? true : false}
                      key={i} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
              </div>
          </div>
          <br/>
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> imagen Web </font><br />
              <IconButton component="label">
                <input hidden type="file" variant="standard" name="imagen" required label='imagen' onChange={handleChangeRegisterfile} />
                <MmsIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </div>
            <div style={{ marginLeft: '15%' }}>
              <font position='start' size="4" color="#3A2872"> imagen Resposi </font><br />
              <IconButton component="label">
                <input hidden type="file" accept="image/x-png,image/gif,image/jpeg"  variant="standard" name="imagenResponsi" required label='imagenResponsi' onChange={handleChangeRegisterfile} />

                <MmsIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </div>
          </div>
        </Container>
        <br />

        <br />
        <div align="center">
          <ThemeProvider theme={customTheme}>
            <BootstrapButton disabled={estadoConsulta} onClick={()=>{PostInfografiaMapa();setEstadoConsulta(true);}} variant="contained">Guardar</BootstrapButton>
          </ThemeProvider>
        </div>
      </Container>
    </div>
  );
}
