import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Checkbox, CircularProgress, Container, IconButton, TextareaAutosize, Typography } from '@mui/material';
import MmsIcon from '@mui/icons-material/Mms';
import Compartir from '../../Imagenes/Compartir.png';
import Comenten from '../../Imagenes/data1.svg'
import { Base, urlFileUpdate, urlFile, compUrls, imagenPublicationsUp, imagenPublications } from '../../Control/Autentication/urlsServer';
import axios from 'axios';
import { Color, Meses } from '../Extras/Referencias.js'
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row

  const [estadoConsulta, setEstadoConsulta] = useState(false)
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const [media, setMedia] = useState(false)
  const [filePublications, setfilePublications] = useState({
    'PublicationsFile': '',
    'PublicationsImagen': '',
    'publication': consolaSeleccionada.id
  })
  const [fileData, setfileData] = useState('');
  const [fileData2, setfileData2] = useState('');

  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + consolaSeleccionada.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }


  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const handleChangeCheck = e => {
    const { name, checked } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: checked,
    }
    ))
  }

  const restData = () => {
    setConsolaSeleccionada(
      {
        'colorPublication': '#F7AC5B',
        'disablePublication': false,
        'DescriptionPublication': '',
        'SubRegionRegister': '',
        'mesPublication': 'Enero',
        'TypePublication': 'datosCuriosos',
        'codeVideoPublication': '',
        'durationPublication': '',
        'titlePublication': '',
        'urlAudioPublication': '',
        'userCreator': 1
      }
    )
    setfilePublications({
      'PublicationsFile': '',
      'PublicationsImagen': '',
      'publication': consolaSeleccionada.id
    });
  }

  const PutPublications = async () => {
    await axios.put(urlFileUpdate + consolaSeleccionada.id, consolaSeleccionada, compUrls)
      .then(response => {
        if (media === true) {
          deleteImagenPublications()
        }
        else {
          alert('Se registro correctamente, actualiza la tabla')
          setEstadoConsulta(false)
        }
      });
  }

  const PostPublications = async () => {
    await axios.post(urlFile, consolaSeleccionada, compUrls)
      .then(response => {
        if (media === true) {
          deleteImagenPublications(response.data.id)
        }
        else {
          restData()
          setEstadoConsulta(false)
          alert('Se registro correctamente, actualiza la tabla')
        }
      })

  }

  const deleteImagenPublications = async (dataid) => {
    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    await axios.delete(imagenPublicationsUp + id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { PostImagenPublications(id) })
      .catch(error => { PostImagenPublications(id) })
  }

  const PostImagenPublications = async (dataid) => {
    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    var dataimg = {
      'PublicationsFile': filePublications.PublicationsFile,
      'PublicationsImagen': filePublications.PublicationsImagen,
      'publication': id
    }
    await axios.post(imagenPublications, dataimg, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setEstadoConsulta(false)
        restData();
        alert('Se registro correctamente, actualiza la tabla');
      })
  }

  const  handleChangeRegisterfile=(e) =>{
    setfilePublications(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }

    ))
    var files = e.target.files;
    for (var i = 0, f; f = files[i]; i++) {
         if (f.type!=='image/jpeg') {
              continue;
         }
     
         var reader = new FileReader();
         
         reader.onload = (function(theFile) {
             return function(e) {
              setfileData2(e.target.result)
             };
         })(f);

         reader.readAsDataURL(f);
     }
}



  
  const datoscuriosos = (
    <div>
      <Card sx={{
        borderRadius: 8,
        width: '29%',
        height: '80%',
        backgroundColor: "#fff",
        position: 'absolute',
        right: '10%',
        borderStyle: 'solid',
        borderColor: consolaSeleccionada.colorPublication,
      }}>
        {fileData !== '' ?
          <CardMedia
            height="35%"
            component="img"
            image={media === false || fileData2 === '' ? Base + fileData.PublicationsImagen:fileData2}
            alt={consolaSeleccionada.titlePublication}
          /> : ""}
        <CardActions>
          <div className='topMenuBlog'>
            <div>
              <font className='h3' width="100px" size="3" >{consolaSeleccionada.datePublication} </font>
            </div>
            <div>
              <IconButton >
                <img className={consolaSeleccionada.id === 0 ? "changecolorR" : consolaSeleccionada.id === 1 ? "changecolorM" : "changecolorA"} src={Comenten} width='40' alt='40' />
              </IconButton>
              <IconButton >
                <img className={consolaSeleccionada.id === 0 ? "changecolorR" : consolaSeleccionada.id === 1 ? "changecolorM" : "changecolorA"} src={Compartir} width='40' alt='40' />
              </IconButton>
            </div>
          </div>
        </CardActions>
        <CardContent >
          <Typography component="h2" variant="h5" fontFamily={'Sofia Sans'}>
            <font className='titleInitio' size="5" color={consolaSeleccionada.colorPublication}> {consolaSeleccionada.titlePublication} </font>
          </Typography>
          <TextareaAutosize
            maxRows={8}
            className='textModal'
            value={consolaSeleccionada && consolaSeleccionada.DescriptionPublication}
            style={{
              width: '100%',
              maxWidth: '100%',
              minWidth: '100%',
              Height: '80%',
              maxHeight: '90%',
              minHeight: '5%',
            }}
            disabled
          />
          <div align='end'>
            <Button sx={{ fontFamily: 'Sofia Sans', textTransform: 'capitalize', margin: '2px' }}>
              <font className='titleInitio' size="4" color={consolaSeleccionada.colorPublication}> Cerrar</font>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  if (fileData === '') {
    GetImagenPublications();
  }
  return (

    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div style={{ display: 'flex' }}>
        <Container>
          {estadoConsulta === true ?
            <div style={{ position: 'absolute', marginLeft: '47%', marginTop: '40%' }} >
              <CircularProgress size={70} />
            </div> : ""}
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Titulo </font><br />
              <input className='inputs' type='text' variant="standard" name="titlePublication" required label='Titulo' value={consolaSeleccionada && consolaSeleccionada.titlePublication} onChange={handleChangeRegister} />
            </div>

            <div>
              <font position='start' size="4" color="#3A2872"> Tipo de publicacion</font><br />
              <input disabled className='inputs' type='text' variant="standard" name="TypePublication" required label='Tipo de publicacion' value={consolaSeleccionada && consolaSeleccionada.TypePublication} onChange={handleChangeRegister} />
            </div>
          </div>
          <br />
          <div >
            <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
            <textarea style={{ height: '152px', width: '70%', maxHeight: '152px', maxWidth: '70%' }} value={consolaSeleccionada && consolaSeleccionada.DescriptionPublication} type='text' variant="standard" name="DescriptionPublication" required className="inputAreatext" label='Descripción del proyecto' onChange={handleChangeRegister} />
            <br />
          </div>
          <br />
          <div style={{ display: 'flex' }}>

            <div>
              <font position='start' size="4" color="#3A2872"> Mes de publicacion:</font><br />
              <select
                id='mesPublication'
                className='inputs'
                name='mesPublication'
                defaultValue={consolaSeleccionada.mesPublication}
                style={{ width: '90%', fontFamily: 'Sofia Sans' }}
                onChange={handleChangeRegister}
              >
                {Meses.map((options, i) => (
                  <option
                    key={i} value={options}>
                    {options}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginLeft: '3%' }}>
              <font position='start' size="4" color="#3A2872"> Color</font><br />
              <select
                id='colorPublication'
                className='inputs'
                name='colorPublication'
                defaultValue={consolaSeleccionada.colorPublication}
                style={{ width: '100%', fontFamily: 'Sofia Sans' }}
                onChange={handleChangeRegister}
              >
                {Color.map((options) => (
                  <option value={options.value}>
                    {options.color}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <ThemeProvider theme={customTheme}>
              <BootstrapButton onClick={() => { setMedia(!media) }} variant="contained">{media === false ? "Editar Archivos media" : "No editar Archivos media"}</BootstrapButton>
            </ThemeProvider>
            {media === true ?
              <div style={{ marginLeft: '5%' }}>
                <font position='start' size="4" color="#3A2872"> Subir imagen </font><br />
                <IconButton component="label">
                  <input name='PublicationsImagen' accept="image/x-png,image/gif,image/jpeg"  onChange={handleChangeRegisterfile} hidden type="file" />
                  <MmsIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div> : ""}
            <div style={{ marginLeft: '10%' }}>
              <Checkbox
                onChange={handleChangeCheck}
                name='disablePublication'
                checked={consolaSeleccionada.disablePublication}
                label="Disponible al publico."
              />
              <font size="3" color="#3A2872">Disponible al publico.</font>
            </div>

          </div>
          <br />

        </Container>

        <div align="center" style={{ marginTop: '30%' }}>
          <ThemeProvider theme={customTheme}>
            {data.new === true ?
              <BootstrapButton disabled={estadoConsulta} onClick={()=>{PostPublications();setEstadoConsulta(true)}} variant="contained">Guardar</BootstrapButton> :
              <BootstrapButton disabled={estadoConsulta} onClick={()=>{PutPublications();setEstadoConsulta(true)}} variant="contained">Guardar</BootstrapButton>}
          </ThemeProvider>
        </div>
        <Container >
          <div style={{ marginTop: '1%' }}>
            {datoscuriosos}
          </div>
        </Container>
      </div>
    </div>
  );
}
