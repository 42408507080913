import React, { useState } from 'react';
import { Button, CircularProgress, Container } from '@mui/material';
import { materialVideoPut, materialVideo, compUrls } from '../../Control/Autentication/urlsServer';
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axios from 'axios';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row
  const [estadoConsulta, setEstadoConsulta] = useState(false)
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const ModulosDiplomado = ['Módulo Inicio', 'Módulo 1', 'Módulo 2', 'Módulo 3', 'Módulo 4', 'Módulo 5', 'Módulo 6', 'Módulo 7',]
  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }


  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  const putMaterial = async () => {
    await axios.put(materialVideoPut + consolaSeleccionada.id, consolaSeleccionada, compUrls)
      .then(response => {
        console.log(response.data);
        setEstadoConsulta(false);
        alert('Se registro correctamente, actualiza la tabla');
      });
  }

  const PostMaterial = async () => {
    await axios.post(materialVideo, consolaSeleccionada, compUrls)
      .then(response => {
        console.log(response.data);
        alert('Se registro correctamente, actualiza la tabla');
        setEstadoConsulta(false)
      });
  }


  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div style={{ display: 'flex' }}>
        <Container>
          {estadoConsulta === true ?
            <div style={{ position: 'absolute', marginLeft: '47%', marginTop: '40%' }} >
              <CircularProgress size={70} />
            </div> : ""}
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Fecha </font><br />
              <input className='inputs' type='text' variant="standard" name="dateMateria" required label='dateMateria' value={consolaSeleccionada && consolaSeleccionada.dateMateria} onChange={handleChangeRegister} />
            </div>
            <div>

              <div>
                <font position='start' size="4" color="#3A2872"> Modulo</font><br />
                <select
                  id='modulo'
                  className='inputs'
                  name='modulo'
                  defaultValue={consolaSeleccionada.modulo}
                  style={{ width: '165%', fontFamily: 'Sofia Sans' }}
                  onChange={handleChangeRegister}
                >
                  {ModulosDiplomado.map((options, i) => (
                    <option
                      disabled={options === 'Cauca' ? true : false}
                      key={i} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />

          <div>
            <font position='start' size="4" color="#3A2872"> Url de video(Youtube) </font><br />
            <input className='inputs' type='text' variant="standard" name="codeVideoMaterial" required label='TicodeVideoMaterialtulo' value={consolaSeleccionada && consolaSeleccionada.codeVideoMaterial} onChange={handleChangeRegister} />
          </div>
          <br />
          <div align="center">
            <ThemeProvider theme={customTheme}>
              {data.new === true ?
                <BootstrapButton disabled={estadoConsulta} onClick={() => { PostMaterial(); setEstadoConsulta(true); }} variant="contained">Guardar</BootstrapButton> :
                <BootstrapButton disabled={estadoConsulta} onClick={() => { putMaterial(); setEstadoConsulta(true); }} variant="contained">Guardar</BootstrapButton>}
            </ThemeProvider>
          </div>
        </Container>
      </div>
    </div>
  );
}
