import React, { useEffect, useState } from 'react';
import { Button, Container, Box, ButtonGroup, Card, IconButton, Modal, CircularProgress } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot.js'
import './stylesCms.css'
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, esES } from '@mui/x-data-grid';
import axios from 'axios';
import VisualizadorPodcast from './VisualizadorPodcast.js';
import Visualizadordatoscuruios from './VisualizadordatosCuriosos.js'
import Visualizadorinfografias from './Visualizadorinfografias.js'
import VisualizadorBanner from './VisualizadorBanner.js'
import VisualizadorlinkInfociencia from './VisualizadorLinkinfociencia.js'
import VisualizadorInfoMapa from './VisualizadorInfoMapa.js'
import { userAdminUp, proyectsData, urlFileUpdate, CommentsCteipostUrl, CommentspostUrl, materialVideo, infografias, podcastTop, DatosCuriososUrl, PodcastUrl, compUrls, Baneer, InfografiaMapa, linkInfociencia } from '../../Control/Autentication/urlsServer.js';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import PodcastActions from './Actions.js';
import Cookies from 'universal-cookie';

const componentDidMount = () => {
  if ((cookies.get('disableAdmin')) !== 'true') {
    window.location.href = "/Soucer/admin/login";
  }
}
const cookies = new Cookies();

export default function Cms() {

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({ 'user': '', 'contraseña': '', 'confirmation': '', 'contraseñanueva': '' });
  const [estadoConsulta, setEstadoConsulta] = useState(false)
  var dataPodcast = {
    'colorPublication': '#F7AC5B',
    'disablePublication': false,
    'DescriptionPublication': '',
    'SubRegionRegister': 'Pacífico',
    'TypePublication': 'Podcast',
    'codeVideoPublication': '',
    'durationPublication': '',
    'titlePublication': '',
    'urlAudioPublication': '',
    'userCreator': 1
  }

  var datoscuriosos = {
    'colorPublication': '#F7AC5B',
    'disablePublication': false,
    'DescriptionPublication': '',
    'SubRegionRegister': '',
    'mesPublication': 'Enero',
    'TypePublication': 'datosCuriosos',
    'codeVideoPublication': '',
    'durationPublication': '',
    'titlePublication': '',
    'urlAudioPublication': '',
    'userCreator': 1
  }

  var datainfociencia = {
    'Descriptioninfografias': '',
    'PublicationsFile': '',
    'PublicationsImagen': '',
    'SubRegionRegister': '',
    'colorPublication': '#F7AC5B',
    'dateLastUpdate': '',
    'dateinfografias': '',
    'disablePublication': false,
    'id': '',
    'palabrasclaves': '',
    'titleinfografias': '',
    'userCreator': 1
  }

  var dataBanner = { 'imagen': null, 'imagenResposi': null }

  var dataLink = {
    'Categoria': "Artículos de interés de proyectos de CTeI en el Cauca",
    'descripcion': "",
    'link': "",
    'titulo': ""
  }

  var dataInfoMapa = {
    "Subregion": "Pacífico",
    "imagen": '',
    "imagenResponsi": '',
    "titulo": "",
  }

  const [type, setType] = useState('')
  const [data, setData] = useState([])
  const [actions, setActions] = useState(false)
  const [usermodal, setUsermodal] = useState(false)
  const columns = [

    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'titlePublication',
      headerName: 'Titulo',
      width: 220,
    },
    {
      field: 'datePublication',
      headerName: 'Fecha de publicacion',
      width: 150,
    },
    {
      field: 'dateLastUpdate',
      headerName: 'Fecha de actualizacion',
      width: 150,
    },
    {
      field: 'TypePublication',
      headerName: 'Tipo de publicacion',
      width: 150,
    },
    {
      field: 'DescriptionPublication',
      headerName: 'Descripcion',
      width: 160,
    },
    {
      field: 'SubRegionRegister',
      headerName: 'Region',
      width: 160,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsDatos = [

    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'titlePublication',
      headerName: 'Titulo',
      width: 260,
    },
    {
      field: 'datePublication',
      headerName: 'Fecha de publicacion',
      width: 150,
    },
    {
      field: 'disablePublication',
      headerName: 'Disponibilidad',
      width: 150,
    },
    {
      field: 'TypePublication',
      headerName: 'Tipo de publicacion',
      width: 150,
    },
    {
      field: 'DescriptionPublication',
      headerName: 'Descripcion',
      width: 260,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsBanner = [

    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },

    {
      field: 'imagen',
      headerName: 'Imagen',
      width: 760,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsinfo = [

    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'titleinfografias',
      headerName: 'Titulo',
      width: 260,
    },
    {
      field: 'dateLastUpdate',
      headerName: 'Fecha de actualizacion',
      width: 150,
    },
    {
      field: 'SubRegionRegister',
      headerName: 'Región',
      width: 150,
    },
    {
      field: 'Descriptioninfografias',
      headerName: 'Descripcion',
      width: 380,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsComentario = [

    {
      field: 'id',
      headerName: 'ID',
      width: 1
    },
    {
      field: 'datecomment',
      headerName: 'Fecha',
      width: 100
    },
    {
      field: 'commentsUser',
      headerName: 'Comentario',
      width: 860,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]

  const columnsMaterial = [

    {
      field: 'id',
      headerName: 'ID',
      width: 1
    },
    {
      field: 'dateMateria',
      headerName: 'Fecha',
      width: 200
    },
    {
      field: 'modulo',
      headerName: 'Modulo',
      width: 400
    },
    {
      field: 'codeVideoMaterial',
      headerName: 'Codigo de video(Youtube)',
      width: 400,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]

  const columnslink = [

    {
      field: 'id',
      headerName: 'ID',
      width: 1
    },
    {
      field: 'titulo',
      headerName: 'Titulo',
      width: 300
    },
    {
      field: 'Categoria',
      headerName: 'Categoria',
      width: 100
    },
    {
      field: 'date',
      headerName: 'Fecha',
      width: 200,
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 200
    },
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      width: 200,
    },


    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]
  const columnsInfoMapa = [

    {
      field: 'id',
      headerName: 'ID',
      width: 1
    },
    {
      field: 'titulo',
      headerName: 'Titulo',
      width: 300
    },
    {
      field: 'Subregion',
      headerName: 'Subregion',
      width: 100
    },
    {
      field: 'date',
      headerName: 'Fecha',
      width: 200,
    },
    {
      field: 'imagenResponsi',
      headerName: 'Imagen resposi',
      width: 200
    },
    {
      field: 'imagen',
      headerName: 'Imagen Web',
      width: 200,
    },


    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]

  const columnsproyect = [

    {
      field: 'id',
      headerName: 'ID',
      width: 1
    },
    {
      field: 'nameproyect',
      headerName: 'Titulo',
      width: 300
    },
    {
      field: 'munipalitylocation',
      headerName: 'Municipio',
      width: 100
    },
    {
      field: 'date',
      headerName: 'Fecha de terminacion',
      width: 200,
    },
    {
      field: 'emailConcta',
      headerName: 'Correo de contacto ',
      width: 200
    },
    {
      field: 'phoneConcta',
      headerName: 'Teléfono',
      width: 200,
    },


    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      renderCell: (params) => (
        <PodcastActions rowId={params} type={type} />
      ),
    },
  ]

  const [ModalpodcastAlert, setModalpodcastAlert] = useState(false);

  const abrirCerrarModalpodcastAlert = () => {
    setModalpodcastAlert(!ModalpodcastAlert)
  }

  const abrirCerraractions = () => {
    setActions(!actions)
  }

  const abrirCerrarUser = () => {
    setUsermodal(!usermodal);
    resetuser();
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 15,
    lineHeight: 1.5,
    borderRadius: '15px',
    color: '#fff',
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const BootstrapButton2 = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });

  const GetPublicationsPodcas = async () => {
    setType("Podcas")
    await axios.get(PodcastUrl, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetPublicationsDatosCuriosos = async () => {
    setType("DatosCuriosos")
    await axios.get(DatosCuriososUrl, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetPublicationspodcastTop = async () => {
    setType("TopPodcast")
    await axios.get(podcastTop, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });

  }

  const GetPublicationsComentarios = async () => {
    setType("Comentarios")
    var datacoments = []
    await axios.get(CommentsCteipostUrl, compUrls)
      .then(response => {
        response.data.map((data) => datacoments.push(data))
      });

    await axios.get(CommentspostUrl, compUrls)
      .then(response => {
        response.data.map((data) => datacoments.push(data))
      });
    setData(datacoments);
    setEstadoConsulta(false);
  }

  const GetPublicationsinfografias = async () => {
    setType("infografias")
    await axios.get(infografias, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetPublicationsmaterial = async () => {
    setType("material")
    await axios.get(materialVideo, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false)
      });
  }

  const GetPBaneer = async () => {
    setType("Banner")
    await axios.get(Baneer, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetProyectos = async () => {
    setType("Proyectos")
    await axios.get(proyectsData, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetInfografiaMapa = async () => {
    setType("InfografiaMapa")
    await axios.get(InfografiaMapa, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }

  const GetlinkInfociencia = async () => {
    setType("LinkInfociencia")
    await axios.get(linkInfociencia, compUrls)
      .then(response => {
        setData(response.data);
        setEstadoConsulta(false);
      });
  }


  const deleteTop = () => {
    data.forEach((data) => {
      var consolaSeleccionada = {
        'colorPublication': data.colorPublication,
        'disablePublication': data.disablePublication,
        'DescriptionPublication': data.DescriptionPublication,
        'SubRegionRegister': data.SubRegionRegister,
        'TypePublication': data.TypePublication,
        'codeVideoPublication': data.codeVideoPublication,
        'durationPublication': data.durationPublication,
        'titlePublication': data.titlePublication,
        'urlAudioPublication': data.urlAudioPublication,
        'topPublications': false,
        'userCreator': data.userCreator
      }
      axios.put(urlFileUpdate + data.id, consolaSeleccionada, compUrls)
        .then(response => {
          GetPublicationspodcastTop();
          setModalpodcastAlert(!ModalpodcastAlert);
          setEstadoConsulta(false);
        });
    })
  }

  const resetuser = () => {
    setConsolaSeleccionada({
      'user': '',
      'contraseña': '',
      'confirmation': '',
      'contraseñanueva': ''
    })
  }

  const useruppassword = () => {
    var datosUp = {
      'user': 'adminCiencia&esencia',
      'contraseña': consolaSeleccionada.confirmation
    }
    axios.put(userAdminUp + consolaSeleccionada.contraseña, datosUp, compUrls)
      .then(response => {
        abrirCerrarUser()
        alert('Se actualizo correctamente')
        setEstadoConsulta(false);
      })
      .catch(error => {
        alert('Error intenta nuevamente')
        setEstadoConsulta(false);
      })
  }


  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }
    ))
  }
  const podcastAlert = (
    <Card sx={{
      borderRadius: 5,
      width: '40%',
      overflowY: 'auto',
      height: '35%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '30%',
      left: '30%'
    }}>
      <div align='end'>
        <IconButton onClick={() => { setModalpodcastAlert(!ModalpodcastAlert) }}>
          <CloseIcon sx={{ fontSize: 35, }} />
        </IconButton>
      </div>
      <div style={{ fontFamily: 'Sofia Sans' }}>
        <Container>
          <div>
            <font position='start' size="4" color="#3A2872"><strong> Gestor de TOP </strong> </font><br />
            <font position='start' size="4" color="#3A2872"> ¿Deseas agregar los podcast del mes?</font><br />
            <font position='start' size="4" color="#3A2872"> (Recuerda que con esta accion, se eliminaran los del mes anterior)</font><br />
          </div>
          <br />
          <div align="center" >
            <ThemeProvider theme={customTheme}>
              <BootstrapButton onClick={() => { deleteTop() }} variant="contained">Sí</BootstrapButton>
              <BootstrapButton sx={{ marginLeft: '5%' }} onClick={() => { setModalpodcastAlert(!ModalpodcastAlert) }} variant="contained">No, solo deseo verlos</BootstrapButton>
            </ThemeProvider>
          </div>
        </Container>
      </div>

    </Card>
  )

  const userUP = (
    <Card sx={{
      borderRadius: 5,
      width: '50%',
      overflowY: 'auto',
      height: '65%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '20%',
      left: '25%'
    }}>
      <div align='end'>
        <IconButton onClick={() => { abrirCerrarUser() }}>
          <CloseIcon sx={{ fontSize: 35, }} />
        </IconButton>
      </div>
      <div style={{ fontFamily: 'Sofia Sans' }}>
        <Container>


          <div align='center'>
            <font position='start' size="4" color="#3A2872"><strong> ¿Deseas cambiar la contraseña de acceso? </strong> </font><br />

            <font position='start' size="4" color="#3A2872"> Ingresa la informacion en los campos</font><br />
            <div style={{ width: '50%' }}>
              <br />
              <font position='start' size="4" color="#3A2872">Contraseña actuar*</font>
              <input type='password' name="contraseña" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.contraseña} onChange={handleChangeRegister} />
            </div>
            <br />
            <div style={{ width: '50%' }}>
              <font position='start' size="4" color="#3A2872">Nueva contraseña</font>
              <input type='password' name="contraseñanueva" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.contraseñanueva} onChange={handleChangeRegister} />
            </div>
            <br />
            <div style={{ width: '50%' }}>
              <font position='start' size="4" color="#3A2872">Confirme nueva contraseña*</font>
              <input type='password' name="confirmation" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.confirmation} onChange={handleChangeRegister} />
              {consolaSeleccionada.confirmation !== consolaSeleccionada.contraseñanueva && consolaSeleccionada.confirmation !== '' ?
                <font position='start' size="4" color="red">Contraseñas no son iguales</font> : ""}

              <br />
            </div>
          </div>
          <br />
          <div align="center" >
            <ThemeProvider theme={customTheme}>
              <BootstrapButton disabled={consolaSeleccionada.confirmation === consolaSeleccionada.contraseñanueva && consolaSeleccionada.contraseñanueva !== '' ? false : true} onClick={() => { useruppassword() }} variant="contained">Confirmar</BootstrapButton>
              <BootstrapButton sx={{ marginLeft: '5%' }} onClick={() => { abrirCerrarUser() }} variant="contained">Cancelar</BootstrapButton>
            </ThemeProvider>
          </div>
        </Container>
      </div>

    </Card>
  )

  const actionsdata = (
    <Card sx={{
      borderRadius: 5,
      width: '80%',
      overflowY: 'auto',
      height: '80%',
      backgroundColor: "#fff",
      position: 'absolute',
      top: '10%',
      left: '5%'
    }}>
      <div align='end'>
        <IconButton onClick={() => { setActions(!actions) }}>
          <CloseIcon sx={{ fontSize: 35, }} />
        </IconButton>
      </div>
      <div>
        {
          type === 'Podcas' ? <VisualizadorPodcast rowId={dataPodcast} type={type} new={true} /> :
            type === 'DatosCuriosos' ? <Visualizadordatoscuruios rowId={datoscuriosos} type={type} new={true} /> :
              type === 'infografias' ? <Visualizadorinfografias rowId={datainfociencia} type={type} new={true} /> :
                type === 'Banner' ? <VisualizadorBanner rowId={dataBanner} type={type} new={true} /> :
                  type === 'LinkInfociencia' ? <VisualizadorlinkInfociencia rowId={dataLink} type={type} new={true} /> :
                    type === 'InfografiaMapa' ? <VisualizadorInfoMapa rowId={dataInfoMapa} type={type} new={true} /> : ""
        }
      </div>
    </Card>
  )

  const closeEntry = () => {
    window.location.href = "/Soucer/admin/login";
    cookies.set('disableAdmin', false, { path: "/" });
  }

  useEffect(() => {
    componentDidMount();
  }, []);
  return (
    <div>
      <div align="center">
        <Header />
      </div>
      <div>
        {estadoConsulta === true ?
          <div style={{position:'absolute', marginLeft:'47%', marginTop:'40%'}} >
            <CircularProgress size={70}/>
          </div> : ""}

        <Container>
          <br /><br />
          <div>
            <font className='titleSus' size="7" color="#4EBBB7">¡Hola Admin Aquí, podrás actualizar y agregar el contenido de la página! ¡Todo en solo sitio! </font><br />
          </div>
          <Box >
            <div>
              <ThemeProvider theme={customTheme}>
                <BootstrapButton onClick={() => { closeEntry() }} sx={{ marginLeft: '90%', width: '15%', fontSize: '100%' }} variant="contained">Salir</BootstrapButton>

                <BootstrapButton onClick={() => { setUsermodal(!usermodal) }} sx={{ marginLeft: '90%', marginTop: '2%', width: '15%', fontSize: '100%' }} variant="contained">Cambiar contraseña</BootstrapButton>
                <ButtonGroup variant="text" >
                  <BootstrapButton2 sx={{ background: type === "Podcas" ? '#4EBBB7' : '#fff', color: type === "Podcas" ? '#fff' : '#4EBBB7', }} onClick={() => { setEstadoConsulta(true); GetPublicationsPodcas() }}>Podcast</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "DatosCuriosos" ? '#4EBBB7' : '#fff', color: type === "DatosCuriosos" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetPublicationsDatosCuriosos() }}>Datos Curiosos</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "infografias" ? '#4EBBB7' : '#fff', color: type === "infografias" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetPublicationsinfografias() }}>Infografías</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "material" ? '#4EBBB7' : '#fff', color: type === "material" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetPublicationsmaterial() }}>Material Educativo</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Comentarios" ? '#4EBBB7' : '#fff', color: type === "Comentarios" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetPublicationsComentarios() }}>Comentarios</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "TopPodcast" ? '#4EBBB7' : '#fff', color: type === "TopPodcast" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); setModalpodcastAlert(!ModalpodcastAlert); GetPublicationspodcastTop(); }}>Top Podcast</BootstrapButton2>
                </ButtonGroup>
                <ButtonGroup variant="text" >
                  <BootstrapButton2 sx={{ background: type === "Banner" ? '#4EBBB7' : '#fff', color: type === "Banner" ? '#fff' : '#4EBBB7', }} onClick={() => { setEstadoConsulta(true); GetPBaneer() }}>Imagenes del banner</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "LinkInfociencia" ? '#4EBBB7' : '#fff', color: type === "LinkInfociencia" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetlinkInfociencia() }}>Datos de infociencia</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "InfografiaMapa" ? '#4EBBB7' : '#fff', color: type === "InfografiaMapa" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetInfografiaMapa() }}>Imagenes de mapa</BootstrapButton2>
                  <BootstrapButton2 sx={{ background: type === "Proyectos" ? '#4EBBB7' : '#fff', color: type === "Proyectos" ? '#fff' : '#4EBBB7' }} onClick={() => { setEstadoConsulta(true); GetProyectos() }}>Proyectos registrados</BootstrapButton2>
                </ButtonGroup>
              </ThemeProvider>
            </div>
          </Box>

          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={data}
              columns={
                type === "Comentarios" ? columnsComentario :
                  type === "DatosCuriosos" ? columnsDatos :
                    type === "infografias" ? columnsinfo :
                      type === "material" ? columnsMaterial :
                        type === "Banner" ? columnsBanner :
                          type === "LinkInfociencia" ? columnslink :
                            type === "InfografiaMapa" ? columnsInfoMapa :
                              type === "Proyectos" ? columnsproyect :
                                columns
              }

              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 6,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
          <div align='center' style={{ marginTop: '-4%', marginRight: '10%' }}>
            {type !== 'Comentarios' && type !== 'TopPodcast' && type !== 'material' && type !== '' && type !== 'Proyectos' ?
              <ThemeProvider theme={customTheme}>
                <BootstrapButton onClick={() => setActions(!actions)} sx={{ marginLeft: '8.5%' }} variant="contained">Agregar {type} </BootstrapButton>
              </ThemeProvider> : ""}
          </div>
        </Container>
        <br /><br />
        <Foot />
      </div>
      <Modal
        open={ModalpodcastAlert}
        onClose={abrirCerrarModalpodcastAlert}>
        {podcastAlert}
      </Modal>
      <Modal
        open={actions}
        onClose={abrirCerraractions}>
        {actionsdata}
      </Modal>

      <Modal
        open={usermodal}
        onClose={abrirCerrarUser}>
        {userUP}
      </Modal>
    </div>
  );
}
