import Inition from '../../Vistas/MenuInicial';
import PodCats from '../../Vistas/MenuPodCats';
import Event from '../../Vistas/MenuEventos';
import Infociencia from '../../Vistas/MenuInfociencia';
import Material from '../../Vistas/MenuEntrenate';
import Ctel from '../../Vistas/MenucTel/CTelProyect';
import ProyectosCtei from '../../Vistas/MenucTel';
import Mapa from '../../Vistas/MenuMapa';
import ParticipacionProyect from '../../Vistas/MenucTel/Participacion';
import Suscripcion from '../../Vistas/Menususcripcion';
import CMSAdmin from '../../Vistas/MenuCMS/indexCms'
import CMSAdminLogin from '../../Vistas/MenuCMS/Login'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function RouterDomin() {
  return (
    <Router>
      <div className="container">
        <Switch>
          <Route path="/Menu/Suscripcion" exact>
            <Suscripcion />
          </Route>
          
          <Route path="/Soucer/admin/login" exact>
            <CMSAdminLogin />
          </Route>
          
          <Route path="/Menu/evento" exact>
            <Event />
          </Route>
          <Route path="/Menu/Podcast" exact>
            <PodCats />
          </Route>
          <Route path="/Menu/Blog" exact>
            <Infociencia />
          </Route>
          <Route path="/Menu/Material" exact>
            <Material />
          </Route>
          <Route path="/Menu/Mapa" exact>
            <Mapa />
          </Route>
          <Route path="/Menu/Inicio" exact>
            <Inition />
          </Route>
          <Route path="/Menu/ProyectsCtel" exact>
            <Ctel />
          </Route>
          <Route path="/Menu/ProyectsParticipacion" exact>
            <ParticipacionProyect />
          </Route>
          <Route path="/Menu/CtelP" exact>
            <ProyectosCtei />
          </Route>
          <Route path="/Menu/CMS/admin" exact>
            <CMSAdmin />
          </Route>
          <Route path="/" exact>
            <Inition />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
export default RouterDomin;
