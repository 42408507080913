import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Badge, Box, IconButton } from '@mui/material';
import Musik from '../../Imagenes/Spotify.png'
import Interativo from '../../Imagenes/interacion.png'
import Youtube from '../../Imagenes/Youtube.png'
import './stylesInicio.css'
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { ReactionsUrl, ReactionsPostUrl, compUrls, Base } from '../../Control/Autentication/urlsServer';
import axios from 'axios';


function FeaturedPost(props) {
  const { post } = props;
  const [paused, setPaused] = React.useState(true);
  const [status, setstatus] = React.useState(false);
  const [like, setLike] = React.useState(props.likes.numberReaction);
  const GetRections = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  return (

    <Grid item md={4}>
      {paused === false ?
        <iframe
          title={post.titlePublication}
          width={0}
          height={0}
          allow='autoplay'
          src={'https://www.youtube.com/embed/' + post.codeVideoPublication + '?autoplay=1'}
          alt={post.titlePublication}
        />
        :
        ""
      }
      <Card sx={{
        borderRadius: 8, maxHeight: 620, minHeight: 620,
        minWidth: 290, maxWidth: 355,
        backgroundColor: props.index === 0 ? '#EA5D5D' : props.index === 1 ? "#F7AC5B" : "#941B80"
      }
      }>

        <div className='topMenu1' >
          <div>
            <Typography
              component="span"
              variant="subtitle1"
              color="#fff"
              sx={{
                position: 'absolute',
                width: 350,
                p: 5,
                pt: 25
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: '-55%',
                }}
              >
                <IconButton
                  aria-label={paused ? 'play' : 'pause'}
                  onClick={() => setPaused(!paused)}
                >
                  {paused ? (
                    <PlayArrowRounded
                      sx={{ fontSize: '6rem' }}
                      htmlColor='#fff'
                    />
                  ) : (
                    <PauseRounded sx={{ fontSize: '6rem' }} htmlColor='#fff' />
                  )}
                </IconButton>
              </Box>
            </Typography>
          </div></div>
        <CardMedia
          height={260}
          sx={{ marginTop: -3 }}
          component="img"
          image={Base + props.imgen.PublicationsImagen}
          alt={post.titlePublication}
        />
        <CardContent>
          <Typography variant="subtitle1" fontStyle='normal' >
            <div className='topMenu1'>
              <div>
                <font className='textInformativo' width="10%" size="3" color="#fff"> {post.datePublication} </font>
              </div>
              <div>
                <IconButton align='center' target='_blank' href={post.urlAudioPublication}>
                  <img src={Musik} width='40' alt='40' />
                </IconButton>
                <IconButton disabled={status} onClick={GetRections} >
                  <Badge badgeContent={like} color='error'>
                    <img src={Interativo} width='40' alt='40' />
                  </Badge>
                </IconButton>
                <IconButton target='_blank' href={'https://www.youtube.com/watch?v=' + post.codeVideoPublication}>
                  <img src={Youtube} width='40' alt='40' />
                </IconButton>
              </div>
            </div>
          </Typography>
          <Typography component="h2" variant="h5" fontStyle='normal' >
            <font className='titleInitio' size="5" color="#fff"> {post.titlePublication} </font>
          </Typography>
          <Typography variant="subtitle1" fontStyle='normal'>
            <font className='textInformativo' size="3" color="#fff">{post.durationPublication} min </font>
          </Typography>
          <font className='textInformativo' size="4" color="#fff">{post.DescriptionPublication}</font>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default FeaturedPost;
