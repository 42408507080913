export const baseUrlUser = 'https://cienciayesencia-cauca.com/api/users'
export const baseUrlEntry = 'https://cienciayesencia-cauca.com/api/userRegister/email/'
export const urlFile = 'https://cienciayesencia-cauca.com/api/publications'
export const PodcastUrl= 'https://cienciayesencia-cauca.com/api/publicationsPodcast/type'
export const podcastTop = 'https://cienciayesencia-cauca.com/api/publicationsTop'
export const SubRegionUrl = 'https://cienciayesencia-cauca.com/api/publicationsSubRegion/region/'
export const DatosCuriososUrl = 'https://cienciayesencia-cauca.com/api/publicationsDatosCuriosos/type'
export const publicationsDatosCuriosos="https://cienciayesencia-cauca.com/api/publicationsDatosCuriosos/mes/"
export const infografiasUrl = 'https://cienciayesencia-cauca.com/api/infografiasSubRegion/region/'
export const infografias = 'https://cienciayesencia-cauca.com/api/infografias'
export const infografiasUp = 'https://cienciayesencia-cauca.com/api/infografiasUp/id/'
export const ReactionsUrl = 'https://cienciayesencia-cauca.com/api/rectionsAdd/id/'
export const ReactionsPostUrl = 'https://cienciayesencia-cauca.com/api/rections'
export const ReactionsCteiUrl = 'https://cienciayesencia-cauca.com/api/rectionsCteiAdd/id/'
export const ReactionsCteiPostUrl = 'https://cienciayesencia-cauca.com/api/rectionsCtei'
export const CommentsUrl = 'https://cienciayesencia-cauca.com/api/commentsSlection/Publications/'
export const CommentspostUrl = 'https://cienciayesencia-cauca.com/api/comments'
export const CommentsDeleteUrl = 'https://cienciayesencia-cauca.com/api/commentsUP/id/'
export const CommentsCteiUrl = 'https://cienciayesencia-cauca.com/api/commentsCteiSlection/Publications/'
export const CommentsCteipostUrl = 'https://cienciayesencia-cauca.com/api/commentsCtei'
export const CommentsCteiDeleteUrl = 'https://cienciayesencia-cauca.com/api/commentsCteiUP/id/'
export const podcastToptUrl = 'https://cienciayesencia-cauca.com/api/podcastTop'
export const material = 'https://cienciayesencia-cauca.com/api/material'
export const proyectsData = 'https://cienciayesencia-cauca.com/api/proyect'
export const materialVideoFilterUrl = 'https://cienciayesencia-cauca.com//api/materialVideoGet/Modulo/'
export const materialVideo = 'https://cienciayesencia-cauca.com//api/materialVideo'
export const materialVideoPut= 'https://cienciayesencia-cauca.com//api/materialVideoAdd/id/'
export const materialFilterUrl = 'https://cienciayesencia-cauca.com/api/materialGet/Modulo/'
export const urlFileUpdate = 'https://cienciayesencia-cauca.com/api/publicationsData/id/'
export const Base="https://cienciayesencia-cauca.com"
export const Baneerup = 'https://cienciayesencia-cauca.com/api/BaneerUP/id/'
export const Baneer = 'https://cienciayesencia-cauca.com/api/Baneer'
export const InfografiaMapa = 'https://cienciayesencia-cauca.com/api/InfografiaMapa'
export const linkInfociencia = 'https://cienciayesencia-cauca.com/api/linkInfociencia'
export const linkInfocienciaUp = 'https://cienciayesencia-cauca.com/api/LinkInfocienciaUp/id/'
export const imagenPublications = 'https://cienciayesencia-cauca.com/api/imagenPublications'
export const imagenPublicationsUp = 'https://cienciayesencia-cauca.com/api/imagenPublicationsUp/id/'
export const InfografiaMapaUp = "https://cienciayesencia-cauca.com/api/InfografiaMapaUp/SubRegions/"
export const imagenInfografias = "https://cienciayesencia-cauca.com/api/imagenInfografias"
export const imagenInfografiasUp = "https://cienciayesencia-cauca.com/api/imagenInfografiasUp/id/"
export const user = 'https://cienciayesencia-cauca.com/api/Login/'
export const userAdminUp = 'https://cienciayesencia-cauca.com/api/userAdminUp/'

// // // ***************************************************************************************************************

// export const Base = "http://127.0.0.1:8000"
// export const imagenInfografias = "http://127.0.0.1:8000/api/imagenInfografias"
// export const imagenInfografiasUp = "http://127.0.0.1:8000/api/imagenInfografiasUp/id/"
// export const InfografiaMapaUp = "http://127.0.0.1:8000/api/InfografiaMapaUp/SubRegions/"
// export const baseUrlUser = 'http://127.0.0.1:8000/api/users'
// export const baseUrlEntry = 'http://127.0.0.1:8000/api/userRegister/email/'
// export const urlFile = 'http://127.0.0.1:8000/api/publications'
// export const urlFileUpdate = 'http://127.0.0.1:8000/api/publicationsData/id/'
// export const PodcastUrl = 'http://127.0.0.1:8000/api/publicationsPodcast/type'
// export const podcastTop = 'http://127.0.0.1:8000/api/publicationsTop'
// export const SubRegionUrl = 'http://127.0.0.1:8000/api/publicationsSubRegion/region/'
// export const DatosCuriososUrl = 'http://127.0.0.1:8000/api/publicationsDatosCuriosos/type'
// export const publicationsDatosCuriosos = "http://127.0.0.1:8000/api/publicationsDatosCuriosos/mes/"
// export const infografiasUrl = 'http://127.0.0.1:8000/api/infografiasSubRegion/region/'
// export const infografias = 'http://127.0.0.1:8000/api/infografias'
// export const infografiasUp = 'http://127.0.0.1:8000/api/infografiasUp/id/'
// export const ReactionsUrl = 'http://127.0.0.1:8000/api/rectionsAdd/id/'
// export const ReactionsPostUrl = 'http://127.0.0.1:8000/api/rections'
// export const ReactionsCteiUrl = 'http://127.0.0.1:8000/api/rectionsCteiAdd/id/'
// export const ReactionsCteiPostUrl = 'http://127.0.0.1:8000/api/rectionsCtei'
// export const CommentsUrl = 'http://127.0.0.1:8000/api/commentsSlection/Publications/'
// export const CommentsDeleteUrl = 'http://127.0.0.1:8000/api/commentsUP/id/'
// export const CommentspostUrl = 'http://127.0.0.1:8000/api/comments'
// export const CommentsCteiUrl = 'http://127.0.0.1:8000/api/commentsCteiSlection/Publications/'
// export const CommentsCteipostUrl = 'http://127.0.0.1:8000/api/commentsCtei'
// export const CommentsCteiDeleteUrl = 'http://127.0.0.1:8000/api/commentsCteiUP/id/'
// export const podcastToptUrl = 'http://127.0.0.1:8000/api/podcastTop'
// export const materialFilterUrl = 'http://127.0.0.1:8000/api/materialGet/Modulo/'
// export const material = 'http://127.0.0.1:8000/api/material'
// export const materialVideoFilterUrl = 'http://127.0.0.1:8000/api/materialVideoGet/Modulo/'
// export const materialVideo = 'http://127.0.0.1:8000/api/materialVideo'
// export const materialVideoPut = 'http://127.0.0.1:8000/api/materialVideoAdd/id/'
// export const proyectsData = 'http://127.0.0.1:8000/api/proyect'
// export const Baneer = 'http://127.0.0.1:8000/api/Baneer'
// export const Baneerup = 'http://127.0.0.1:8000/api/BaneerUP/id/'
// export const InfografiaMapa = 'http://127.0.0.1:8000/api/InfografiaMapa'
// export const linkInfociencia = 'http://127.0.0.1:8000/api/linkInfociencia'
// export const linkInfocienciaUp = 'http://127.0.0.1:8000/api/LinkInfocienciaUp/id/'
// export const imagenPublications = 'http://127.0.0.1:8000/api/imagenPublications'
// export const imagenPublicationsUp = 'http://127.0.0.1:8000/api/imagenPublicationsUp/id/'
// export const user = 'http://127.0.0.1:8000/api/Login/'
// export const userAdminUp = 'http://127.0.0.1:8000/api/userAdminUp/'
export const compUrls = {
  headers: {
    'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}