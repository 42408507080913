import React, { useState } from 'react';
import { Badge, Button, CardContent, CardMedia, Checkbox, CircularProgress, Container, IconButton, Typography } from '@mui/material';
import MmsIcon from '@mui/icons-material/Mms';
import Musik from '../../Imagenes/Spotify2.png';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Youtube from '../../Imagenes/youtube2.svg'
import { Base, urlFileUpdate, urlFile, ReactionsPostUrl, imagenPublications, imagenPublicationsUp, compUrls } from '../../Control/Autentication/urlsServer';
import axios from 'axios';
import { Subregion } from '../Extras/Referencias.js'
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row
  const [estadoConsulta, setEstadoConsulta] = useState(false)
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)

  const [media, setMedia] = useState(false)
  const [filePublications, setfilePublications] = useState({
    'PublicationsFile': '',
    'PublicationsImagen': '',
    'publication': consolaSeleccionada.id
  })
  const [fileData, setfileData] = useState('');
  const [fileData2, setfileData2] = useState('');

  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + consolaSeleccionada.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }

  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const handleChangeCheck = e => {
    const { name, checked } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: checked,
    }
    ))
  }


  const  handleChangeRegisterfile=(e) =>{
    setfilePublications(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }

    ))
    var files = e.target.files;
    for (var i = 0, f; f = files[i]; i++) {
         if (f.type!=='image/jpeg') {
              continue;
         }
     
         var reader = new FileReader();
         
         reader.onload = (function(theFile) {
             return function(e) {
              setfileData2(e.target.result)
             };
         })(f);

         reader.readAsDataURL(f);
     }
}
  const podcast = (
    <div >
      <div style={{ display: 'flex' }}>
      {media === true&&fileData2!==''?
      <CardMedia
          component="img"
          height={190}
          sx={{ minWidth: 150, maxWidth: 150, borderRadius: 3 }}
          image={fileData2}
          alt={consolaSeleccionada.titlePublication}
        />  
:
        <CardMedia
          component="img"
          height={190}
          sx={{ minWidth: 150, maxWidth: 150, borderRadius: 3 }}
          image={Base + fileData.PublicationsImagen}
          alt={consolaSeleccionada.titlePublication}
        />
        }
        <div >
          <img src={Youtube}
            alt={selection.titlePublication}
            style={{
              cursor: 'pointer'
            }} className='Youtube' />
          < br />
        </div>
        <CardContent sx={{ marginLeft: '-3rem' }}>
          <Typography variant="h5">
            <div className='topMenuP'>
              <div>
                <font style={{ fontFamily: 'Sofia Sans', fontWeight: 600 }} color="#4EBBB7" size="4"  >{consolaSeleccionada && consolaSeleccionada.titlePublication} | </font>  <font style={{ fontFamily: 'Sofia Sans' }} size="4" color="#c7c7c9"> {consolaSeleccionada && consolaSeleccionada.durationPublication}min</font>
              </div>
              <div>
                <IconButton >
                  <img src={coment} width='30' alt='40' />
                </IconButton>
                <IconButton >
                  <img src={Compartir} width='30' alt='40' />
                </IconButton>
                <IconButton  >
                  <img src={Musik} width='30' alt='40' />
                </IconButton>
                <IconButton disabled >
                  <Badge badgeContent={0} color='error'>
                    <img src={Interativo} width='30' alt='40' />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            <font style={{ fontFamily: 'Sofia Sans' }} className='h1' size="3" color='#23088E' > {consolaSeleccionada && consolaSeleccionada.DescriptionPublication}</font>
          </Typography>
        </CardContent>
        <div />
      </div>
      <Typography variant="subtitle1" >
        <font style={{ fontFamily: 'Sofia Sans' }} className='h1' size="3" color="#c7c7c9">Publicación: {consolaSeleccionada && consolaSeleccionada.datePublication}</font>
      </Typography>
      <br />
    </div>
  )

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  const restData = () => {
    setConsolaSeleccionada(
      {
        'colorPublication': '#F7AC5B',
        'disablePublication': false,
        'DescriptionPublication': '',
        'SubRegionRegister': 'Pacífico',
        'TypePublication': 'Podcast',
        'codeVideoPublication': '',
        'durationPublication': '',
        'titlePublication': '',
        'urlAudioPublication': '',
        'userCreator': 1
      }
    );
    setfilePublications({
      'PublicationsFile': '',
      'PublicationsImagen': '',
      'publication': consolaSeleccionada.id
    });
  }

  const PutPublications = async () => {
    await axios.put(urlFileUpdate + consolaSeleccionada.id, consolaSeleccionada, compUrls)
      .then(response => {
        if (media === true) {
          deleteImagenPublications()
        }
        else {
          restData();
          setEstadoConsulta(false);
          alert('Se registro correctamente, actualiza la tabla');
        }
      });
  }

  const PostPublications = async () => {
    await axios.post(urlFile, consolaSeleccionada, compUrls)
      .then(response => {
        const datapost = {
          publicationRelations: response.data.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
          })
        if (media === true) {
          deleteImagenPublications(response.data.id)
        }
        else {
          setEstadoConsulta(false);
          alert('Se registro correctamente, actualiza la tabla')
        }


      })
  }

  const deleteImagenPublications = async (dataid) => {
    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    await axios.delete(imagenPublicationsUp + id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { PostImagenPublications(id) })
      .catch(error => { PostImagenPublications(id) })
  }

  const PostImagenPublications = async (dataid) => {

    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    var dataimg = {
      'PublicationsFile': filePublications.PublicationsFile,
      'PublicationsImagen': filePublications.PublicationsImagen,
      'publication': id
    }
    await axios.post(imagenPublications, dataimg, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        restData();
        setEstadoConsulta(false);
        alert('Se registro correctamente, actualiza la tabla');
      })
  }

  if (fileData === '') {
    GetImagenPublications();
  }

  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div style={{ display: 'flex' }}>
        <Container>
          {estadoConsulta === true ?
            <div style={{ position: 'absolute', marginLeft: '47%', marginTop: '40%' }} >
              <CircularProgress size={70} />
            </div> : ""}
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Titulo </font><br />
              <input className='inputs' type='text' variant="standard" name="titlePublication" required label='Titulo' value={consolaSeleccionada && consolaSeleccionada.titlePublication} onChange={handleChangeRegister} />
            </div>
            <div>
              <div>
                <font position='start' size="4" color="#3A2872"> SubRegion</font><br />
                <select
                  id='SubRegionRegister'
                  className='inputs'
                  name='SubRegionRegister'
                  defaultValue={consolaSeleccionada.titlePublication}
                  style={{ width: '165%', fontFamily: 'Sofia Sans' }}
                  onChange={handleChangeRegister}
                >
                  {Subregion.map((options, i) => (
                    <option
                      disabled={options === 'Cauca' ? true : false}
                      key={i} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Duracion </font><br />
              <input className='inputs' type='text' variant="standard" name="durationPublication" required label='Duracion' value={consolaSeleccionada && consolaSeleccionada.durationPublication} onChange={handleChangeRegister} />
            </div>
            <div>
              <font position='start' size="4" color="#3A2872"> Tipo de publicacion</font><br />
              <input disabled className='inputs' type='text' variant="standard" name="TypePublication" required label='Tipo de publicacion' value={consolaSeleccionada && consolaSeleccionada.TypePublication} onChange={handleChangeRegister} />
            </div>
          </div>
          <br />
          <div >
            <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
            <textarea style={{ height: '152px', width: '70%', maxHeight: '152px', maxWidth: '70%' }} value={consolaSeleccionada && consolaSeleccionada.DescriptionPublication} type='text' variant="standard" name="DescriptionPublication" required className="inputAreatext" label='Descripción del proyecto' onChange={handleChangeRegister} />
            <br />
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Codigo de video (Youtube)</font><br />
              <input className='inputs' type='text' variant="standard" name="codeVideoPublication" required label='Codigo de video (Youtube)' value={consolaSeleccionada && consolaSeleccionada.codeVideoPublication} onChange={handleChangeRegister} />
            </div>
            <div>
              <font position='start' size="4" color="#3A2872"> Url de audio(Spotify)</font><br />
              <input className='inputs' type='text' variant="standard" name="urlAudioPublication" required label='Url de audio(Spotify)' value={consolaSeleccionada && consolaSeleccionada.urlAudioPublication} onChange={handleChangeRegister} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <ThemeProvider theme={customTheme}>
              <BootstrapButton onClick={() => { setMedia(!media) }} variant="contained">{media === false ? "Editar Archivos media" : "No editar Archivos media"}</BootstrapButton>

              {media === true ?
                <div style={{ marginLeft: '5%' }}>
                  <font position='start' size="4" color="#3A2872"> Subir imagen </font><br />
                  <IconButton component="label">
                    <input name='PublicationsImagen' accept="image/x-png,image/gif,image/jpeg"  onChange={handleChangeRegisterfile} hidden type="file" />
                    <MmsIcon sx={{ fontSize: '50px' }} />
                  </IconButton>
                </div> : ""} </ThemeProvider>
          </div>
          <br />
        </Container>
        <Container >
          <div style={{ marginTop: '10%' }}>
            {podcast}
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Checkbox
              onChange={handleChangeCheck}
              name='disablePublication'
              checked={consolaSeleccionada.disablePublication}
              label="Disponible al publico."
            />
            <font size="3" color="#3A2872">Disponible al publico.</font>
            <br />
            <Checkbox
              onChange={handleChangeCheck}
              name='topPublications'
              checked={consolaSeleccionada.topPublications}
              label="Top en los podcast"
            />
            <font size="3" color="#3A2872">Top en los podcast.</font>
            <br />
          </div>
          <br />
          <div align="center">
            <ThemeProvider theme={customTheme}>
              {data.new === true ?
                <BootstrapButton disabled={estadoConsulta} onClick={() => { PostPublications(); setEstadoConsulta(true) }} variant="contained">Guardar</BootstrapButton> :
                <BootstrapButton disabled={estadoConsulta} onClick={() => { PutPublications(); setEstadoConsulta(true) }} variant="contained">Guardar</BootstrapButton>}
            </ThemeProvider>
          </div>
        </Container>
      </div>
    </div>
  );
}
