import React, { useState } from 'react';
import { Button, Container } from '@mui/material';
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { user, compUrls } from '../../Control/Autentication/urlsServer';
import axios from 'axios';
import logo from '../../Imagenes/Eslogandelproyecto.png'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function Login() {
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({ 'contraseña': '', 'user': '' })

  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const restData = () => {
    setConsolaSeleccionada({ 'contraseña': '', 'user': '' });
  }

  const getUser = async () => {
    await axios.get(user + consolaSeleccionada.user + '/' + consolaSeleccionada.contraseña, compUrls)
      .then(response => {
        if (response.status === 200 && response.data.length !== 0) {
          cookies.set('disableAdmin',true,{ path: "/" });
          validacionUser();
        }
        else {
          restData();
          alert('Credenciales incorrectas')
        }
      })
      .catch(error => {
        restData();
        alert('Credenciales incorrectas')
      })
  }

  const validacionUser = () => {
    window.location.href = "/Menu/CMS/admin"
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });


  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div align='center'>
        <img style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "/Menu/Inicio" }} className='imag' alt="inicio" src={logo} width={620} />
      </div>

      <Container>
        <div align='center' style={{ display: 'flex', marginLeft: '30%' }}>
          <div>
            <font position='start' size="4" color="#3A2872"> Nombre de usuario </font><br />
            <input className='inputs' type='text' variant="standard" name="user" required label='Duracion' value={consolaSeleccionada && consolaSeleccionada.user} onChange={handleChangeRegister} />
          </div>
          <div>
            <font position='start' size="4" color="#3A2872"> Contraseña</font><br />
            <input className='inputs' type='password' variant="standard" name="contraseña" required label='Tipo de publicacion' value={consolaSeleccionada && consolaSeleccionada.contraseña} onChange={handleChangeRegister} />
          </div>
        </div>
      </Container><br />
      <Container >
        <div align="center">
          <ThemeProvider theme={customTheme}>
            <BootstrapButton onClick={getUser} variant="contained">Login</BootstrapButton>
          </ThemeProvider>
        </div>
      </Container>
    </div>
  );
}
