import * as React from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Badge, CardMedia, IconButton, Modal } from '@mui/material';
import Musik from '../../Imagenes/Spotify2.png';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Youtube from '../../Imagenes/youtube2.svg'
import Comments from '../Extras/Comments.js'
import CompartirPublication from '../Extras/compartir.js'
import { ReactionsUrl, ReactionsPostUrl, compUrls, Base,imagenPublicationsUp } from '../../Control/Autentication/urlsServer';
import './stylesPodcats.css'
import { useState, useEffect } from 'react';
import axios from 'axios';

function FeaturedPost(props) {
  const post=props.post
  const index=props.index
  const [showMedia, setShowMedia] = useState(false);
  const [like, setLike] = useState(null);
  const [fileData, setfileData] = useState('');
  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { 
        setfileData(response.data);
      })
  }

  const [status, setstatus] = useState(false);

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }

  const [modalComent, setModalComent] = useState(false);
  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const [modalCompartir, setModalCompartir] = useState(false);
  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const GetRectionsinition = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)           
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const mediaPlay = (
    <div>
      <div style={{
        width: '60%',
        height:'60%',
        position: 'absolute',
        top: '18%',
        left: '20%',
        borderStyle: 'solid',
        borderColor: '#fff',
        backgroundColor: "#fff",
      }}>
        <CardMedia
          component='iframe'
          height='100%'
          image={'https://www.youtube.com/embed/' + post.codeVideoPublication}
          alt={post.titlePublication}
        />
      </div>
    </div>)

  const bodyComent = (
    <div>
      <Comments key={post.id} post={post} />
    </div>

  )


  const bodyCompartir = (
    <div>
      <CompartirPublication key={post.id} post={post} />
    </div>)
    
  if (fileData === ''|| index!==fileData.publication) {
    GetImagenPublications();
  }

  useEffect(() => { 
    GetRectionsinition();
  });

  return (
    <div style={{ height: '50%' }}>
      
      <div>
        <Typography variant="subtitle1" >
        <font style={{ fontFamily: 'Sofia Sans'}} className='h1' size="3" color="#c7c7c9">Publicación: {post.datePublication}</font>
      </Typography>
      </div>

      <div style={{ display: 'flex' }}>
      {fileData.PublicationsImagen !== undefined ?
        <CardMedia
          component="img"
          height={190}
          sx={{ minWidth: 300, maxWidth: 300, borderRadius: 3 }}
          image={Base + fileData.PublicationsImagen}
          alt={post.titlePublication}
        />:""}
        <div >
          <img src={Youtube} style={{
            cursor: 'pointer'
          }} className='changecolorYoutube' onClick={() => { setShowMedia(!showMedia) }} alt={post.titlePublication} />
        </div>
        <CardContent sx={{ marginLeft: '-3rem' }}>
          <Typography variant="h5">
            <div className='topMenuP'>

              <div style={{width:'70%'}}>
                <font style={{ fontFamily: 'Sofia Sans', fontWeight: 600, }} color="#4EBBB7" size="5"  >{post.titlePublication} | </font>  <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#c7c7c9"> {post.durationPublication}min</font>
              </div>
              <div>
                <IconButton onClick={abrirCerrarModalComent}>
                  <img src={coment} width='40' alt='40' />
                </IconButton>

                <IconButton onClick={abrirCerrarModalCompartir}>
                  <img src={Compartir} width='40' alt='40' />
                </IconButton>

                <IconButton target='_blank' href={post.urlAudioPublication}>
                  <img src={Musik} width='40' alt='40' />
                </IconButton>
                <IconButton disabled={status} onClick={GetRections} >
                  <Badge badgeContent={like} color='error'>
                    <img src={Interativo} width='40' alt='40' />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </Typography>
          <div style={{ marginTop:'0.5%'}}>
            <font style={{ fontFamily: 'Sofia Sans'}} className='h1' size="4" color='#23088E' > {post.DescriptionPublication}</font>
          </div>
        </CardContent>
        <div />
      </div>
      <br />
      <br />
      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>


      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>
    </div>
  );
}

export default FeaturedPost;
