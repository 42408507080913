import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import { authentication } from '../Autentication/ManageFirebase';
import LogoVertical from '../../Imagenes/Eslogandelproyecto.png'
import { Button } from '@mui/material';
import axios from 'axios';
import { baseUrlEntry, compUrls } from '../Autentication/urlsServer'
import Cookies from 'universal-cookie';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import item1 from '../../Imagenes/menu/Iconos menu para celular-01.svg'
import item2 from '../../Imagenes/menu/Iconos menu para celular-02.svg'
import item3 from '../../Imagenes/menu/Iconos menu para celular-03.svg'
import item4 from '../../Imagenes/menu/Iconos menu para celular-04.svg'
import item5 from '../../Imagenes/menu/Iconos menu para celular-05.svg'
const drawerWidth = 240;
const cookies = new Cookies();

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function HeaderMovil() {
  const [auth, setAuth] = React.useState(cookies.get('emailVerified'))
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAuth = (usuario) => {
    cookies.set('user', usuario, { path: "/" });
    cookies.set('displayName', usuario.displayName, { path: "/" });
    cookies.set('email', usuario.email, { path: "/" });
    cookies.remove('idUser');
    peticionGet();
  }
  const peticionGet = async () => {
    if (cookies.get('email') !== "") {
      await axios.get(baseUrlEntry + cookies.get('email'), compUrls)
        .then(response => {
          if (response.status === 200) {
            var respuesta = response.data;
            cookies.set('idUser', respuesta.userId);
            cookies.set('emailVerified', true);
            setAuth(cookies.get('emailVerified'))
            // window.location.href = "/Menu/Inicio"
          }
        })
        .catch(error => {
          if (error.response.status === undefined || error.response.status === 404) {
            window.location.href = "/Menu/Suscripcion"
            ErrorEntry();
          }
        })
    }
  }
  const ErrorEntry = () => {
    alert('registarse')
    cookies.set('emailVerified', false);
    cookies.remove('user');
    cookies.remove('displayName');
    cookies.remove('idUser');
    setAuth(cookies.get('emailVerified'))
  }

  const closeEntry = () => {
    alert('salir')
    window.location.href = "/Menu/Inicio"
    cookies.set('emailVerified', false);
    cookies.remove('user');
    cookies.remove('displayName');
    cookies.remove('idUser');
    setAuth(cookies.get('emailVerified'))
  }


  return (
    <Box sx={{ display: 'flex', background: '#4EBBB7' }}>
      <AppBar position="fixed" open={open} sx={{ background: '#4EBBB7' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Button sx={{
            fontStyle: 'normal',
            fontFamily: 'Sofia Sans',
            textTransform: 'none', fontSize: 35, color: '#fff'
          }} href="/Menu/Inicio" >
            Ciencia&Esencia
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          background: '#4EBBB7',
          color: '#fff',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            background: '#4EBBB7', color: '#fff'
          }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ background: '#4EBBB7', color: '#fff' }} /> : <ChevronRightIcon sx={{ background: '#4EBBB7', color: '#fff' }} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            background: '#4EBBB7'
          }}>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Inicio'} disablePadding>
            <ListItemButton href="/Menu/Inicio">
              <ListItemIcon>
                <CottageRoundedIcon sx={{ marginLeft: '10px', background: '#4EBBB7', color: '#fff' }} />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >Inicio</font>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Mapa interativo'} disablePadding>
            <ListItemButton href="/Menu/Mapa">
              <ListItemIcon>
                <img src={item4} width='40' alt='40' />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >Mapa interativo</font>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Nuestro Canal'} disablePadding>
            <ListItemButton href="/Menu/Podcast">
              <ListItemIcon>
                <img src={item2} width='40' alt='40' />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >Nuestro Canal</font>
            </ListItemButton>
          </ListItem>

          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'+CTeI'} disablePadding>
            <ListItemButton href="/Menu/CtelP">
              <ListItemIcon>
                <img src={item3} width='40' alt='40' />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >+CTeI</font>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Infociencia'} disablePadding>
            <ListItemButton href="/Menu/Blog">
              <ListItemIcon>
                <img src={item5} width='40' alt='40' />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >Infociencia</font>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Entrénate'} disablePadding>
            <ListItemButton href="/Menu/Material">
              <ListItemIcon>
                <img src={item1} width='40' alt='40' />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >Entrénate</font>
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ background: '#4EBBB7', color: '#fff' }} key={'Registrarse'} disablePadding>
            <ListItemButton onClick={() => { auth === 'true' ? closeEntry() : authentication("Google", handleAuth) }}>
              <ListItemIcon>
                <VpnKeyIcon sx={{ marginLeft: '10px', background: '#4EBBB7', color: '#fff' }} />
              </ListItemIcon>
              <font style={{ fontFamily: 'Sofia Sans' }} >{auth === 'true' ? 'Cerrar Sesión' : 'Regístrate'}</font>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <img width='130%' style={{ marginLeft: '-15px' }} src={LogoVertical} alt={'LogoVertical'} />
        </List>
      </Drawer>
      <Main
        onClick={() => { setOpen(false) }}
        sx={{
          background: '#4EBBB7'
        }} open={open}>

      </Main>
    </Box>
  );
}