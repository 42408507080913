import * as React from 'react';
import { useEffect, useState } from 'react';
import Img3 from '../../Imagenes/Imagenmedia.jpg';
import Audiolist from './Audiolist.js'
import notfound from '../../Imagenes/404-error.png'
import { Box, Container, Typography } from '@mui/material';

export default function MusicPlayerSlider(options) {
    const regionData = options.dataregion
    const listAudiosPod = options.props
    const [widthResize, setWidthResize] = useState(window.innerWidth)

    const resize = () => {
        setWidthResize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    return (
        <>
            <Box sx={{ alignItems: 'center' }}>
                <div align='center'>
                    <img
                        alt="can't win - Chilling Sunday"
                        src={Img3}
                        width='35%'
                    />
                </div>
                <div align='center'>
                    <Typography color="text.secondary" fontWeight={500} fontSize={18}>
                        {regionData}
                    </Typography>
                </div>
            </Box>
            <Container>
                <div style={{ overflowX: 'hidden', overflowY: 'scroll', height: widthResize > 1621 ? 400 : 300, width: '100%', display: 'flex' }}>
                    <div style={{ width: '95%' }}>
                        {listAudiosPod.length !== 0 ? (
                            listAudiosPod.map((post, i) => (
                                post.disablePublication === true ?
                                <Audiolist key={post.i} post={post} />
                                :""
                            ))
                        )
                            : <div align="center"><img width='50%' src={notfound} alt={'notfound'} /> </div>
                        }
                    </div>
                </div>
            </Container>
        </>
    );
}