import React, { useEffect, useState } from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { IconButton } from '@mui/material';
import users from '../../Imagenes/users.svg'
import users2 from '../../Imagenes/IconoChat2.svg'
import users3 from '../../Imagenes/Iconochat3.svg'
import users4 from '../../Imagenes/Iconochat4.svg'
import SendLogo from '../../Imagenes/sent.svg'
import '../MenuInfociencia/stylesBlog.css'
import { CommentsUrl, CommentspostUrl, CommentsCteipostUrl, CommentsCteiUrl, compUrls } from '../../Control/Autentication/urlsServer';
import axios from 'axios';

export default function ComentsPublication(props) {
  const { post } = props;
  const [listComents, setListComents] = useState([])
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    commentsUser: '',
    publicationComments: post.id
  })

  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const PostComments = async () => {
    await axios.post(post.titlePublication !== undefined ? CommentspostUrl : CommentsCteipostUrl, consolaSeleccionada, compUrls)
      .then(response => {

        if (post.titlePublication !== undefined) {
          GetComments(post.id);
        }
        else {
          GetCommentsinfo(post.id)
        }
        setConsolaSeleccionada({
          commentsUser: '',
          publicationComments: post.id
        })
      });
  }

  const GetComments = async (uid) => {
    await axios.get(CommentsUrl + uid, compUrls)
      .then(response => {
        setListComents(response.data);
      });
  }

  const GetCommentsinfo = async (uid) => {
    await axios.get(CommentsCteiUrl + uid, compUrls)
      .then(response => {
        setListComents(response.data);
      });
  }

  const ChatsComents = (post) => (
    (post.idIndex % 2) === 0 ?
      <li className="you">
        <div className="message" style={{ width: '75%' }}>
          <div className='nameUser'>
            <font color='#dcdf47'>{post.post.datecomment}</font>
            <br />
            <div >
              <TextareaAutosize
                className='textModal2'
                defaultValue={post.post.commentsUser}
                style={{
                  width: 270, maxWidth: 270, minWidth: 270,
                }}
                disabled
              />
            </div>
          </div>
          <div>
            {(post.idIndex % 3) === 1 ?
              <img src={users2} width={80} alt='40' /> :
              <img src={users4} width={80} alt='40' />}
          </div>
        </div>
      </li> :
      <li className="me">
        <div className="message" style={{ width: '75%' }}>

          {((post.idIndex) % 3) === 1 ?
            <img src={users} width={80} alt='40' /> :
            <img src={users3} width={80} alt='40' />}
          <div className='nameUser'>
            <font color='red'>{post.post.datecomment}</font>
            <br />

            <TextareaAutosize
              className='textModal2'
              defaultValue={post.post.commentsUser}
              style={{
                width: 250, maxWidth: 250, minWidth: 250,
              }}
              disabled
            />
          </div>
        </div>
      </li>
  );

  useEffect(() => {
    if (post.titlePublication !== undefined) {
      GetComments(post.id);
    }
    else {
      GetCommentsinfo(post.id)
    }

  }, [post]);

  return (
    <div className="modalComent">
      <main>
        <ul id="chat">
          {listComents.map((comments, index) => (
            <div >
              <ChatsComents key={comments.id} post={comments} idIndex={index + 1} />
            </div>
          ))}
        </ul>
        <footer>
          <div style={{
            width: '80%',
            display: 'flex', background: '#fff',
            alignContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          }}>
            <TextareaAutosize
              name='commentsUser'
              placeholder="Escribe tu comentario!"
              onChange={handleChangeRegister}
              value={consolaSeleccionada && consolaSeleccionada.commentsUser}
              style={{ maxHeight: 130, borderTopRightRadius: 100, borderBottomRightRadius: 100, borderColor: '#fff', width: '100%', outline: 'none' }}
            />
            <IconButton
              disabled={consolaSeleccionada.textcomment !== '' ? false : true}
              onClick={PostComments}
            >
              <img style={{ marginLeft: -25 }} src={SendLogo} width={20} alt='sent' />
            </IconButton>
          </div>
        </footer>
      </main>
    </div>
  );
}

