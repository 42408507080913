import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Checkbox, Button, Container, Snackbar, Alert, Link } from '@mui/material';
import { paises, departamentos } from '../Extras/Referencias'
import { proyectsData, compUrls, Base } from '../../Control/Autentication/urlsServer'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot'
import Cookies from 'universal-cookie';
import axios from 'axios';
import './stylesProyecto.css'

const cookies = new Cookies();
export default function Suscripcion() {
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [open, setOpen] = useState(false);
  const [value1, setvalue1] = useState(false);
  const [value2, setvalue2] = useState(false);
  const [valueCountry, setvalueCountry] = useState(false);

  const [opencountry, setOpencountry] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openPolitica, setOpenPolitica] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nameproyect: '',
    author: '',
    munipalitylocation: '',
    date: '',
    descriptions: '',
    nameconcta: '',
    lastnameconcta: '',
    emailConcta: '',
    usernameConcta: '',
    phoneConcta: '',
    countryConcta: '',
    departmentConcta: '',
    munipalityConcta: '',
    organizationsConcta: '',
    otherOrganizationsConcta: '',
  })

  const resize = () => {
    setWidthResize(window.innerWidth)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setOpenError(false)
  };

  const handleClosePolitica = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPolitica(false);
  };
  const handleClosecountry = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpencountry(false);
  };

  const onchangeCheckValue1 = () => {
    setvalue1(!value1)
  }
  const onchangeCheckValue2 = () => {
    setvalue2(!value2)
  }

  const peticionPostProyect = async () => {
    await axios.post(proyectsData, consolaSeleccionada, compUrls)
      .then(response => {
        RegistrosSucces();
        setOpen(!open)
      })
      .catch(error => {
        setOpenError(true)
      })
  }


  const handleChangeCoutry = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
      'countryConcta': document.getElementById("countryConcta").value,
      'departmentConcta': document.getElementById("listdepartament").value,
    }
    ))
    if (document.getElementById("countryConcta").value === "Colombia") {
      setvalueCountry(false)
    }
    else {
      setvalueCountry(true)
      setOpencountry(!opencountry)
      setConsolaSeleccionada(prevState => ({
        ...prevState,
        departmentConcta: '',
        munipalityConcta: ''

      }))
    }
  }
  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  const RegistrosSucces = () => {
    setConsolaSeleccionada(
      {
        nameproyect: '',
        author: '',
        munipalitylocation: '',
        date: '',
        descriptions: '',
        nameconcta: '',
        lastnameconcta: '',
        emailConcta: '',
        usernameConcta: '',
        phoneConcta: '',
        countryConcta: '',
        departmentConcta: '',
        munipalityConcta: '',
        organizationsConcta: '',
        otherOrganizationsConcta: '',
      }
    )
  }
  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 15,
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div>
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Inicio"} /> : <HeaderMovil />}
      </div>
      <div style={{ fontFamily: 'Sofia Sans' }}>

        <div>
          <Container ><br /><br />
            <div>
              <font className='titleSus' size="7" color="#4EBBB7">¡Únete a la comunidad del conocimiento y entérate de todos los saberes del Cauca! </font><br />
            </div>
            <font className='contetSus' size="5" color="#3A2872">Si quieres que te ayudemos a dar a conocer un proceso de ciencia, tecnología e innovación que se desarrolle e tu región para que muchas más personas conozcan de él, escríbenos! Nos pondremos en contacto contigo para conocer más de tu proyecto o el de tu comunidad. </font>
            <br /><br />
            <div style={{ display: 'flex' }}>
              <div>
                <font position='start' size="4" color="#3A2872"> Nombre del proyecto*</font>
                <input type='text' variant="standard" name="nameproyect" required className="inputMaterialclumns" label='NOMBRE' value={consolaSeleccionada && consolaSeleccionada.nameproyect} onChange={handleChangeRegister} />
              </div>
              <div>
                <font position='start' size="4" color="#3A2872"> ¿Quién lo realizó?*</font>
                <input type='text' variant="standard" name="author" required className="inputMaterialclumns" label='APELLIDO' value={consolaSeleccionada && consolaSeleccionada.author} onChange={handleChangeRegister} />
              </div>
            </div>
            <br />
            <div style={{ display: 'flex' }}>
              <div >
                <font position='start' size="4" color="#3A2872">Municipio dónde se ejecuta:</font>
                <input variant="standard" name="munipalitylocation" required className="inputMaterialclumns" label='MUNICIPIO' value={consolaSeleccionada && consolaSeleccionada.munipalitylocation} onChange={handleChangeRegister} />
              </div>
              <div style={{ marginRight: '9%' }}>
                <font position='start' size="4" color="#3A2872"> Estado:</font><br />
                <select
                  className='inputMaterialclumns'
                  id='status'
                  style={{ height: '65%', width: '151%' }}
                  name="status"
                  variant="standard"
                  onChange={handleChangeRegister}
                >
                  {
                    ['Terminado', 'Suspendido', 'En proceso'].map((options) => (
                      <option key={options} value={options}>
                        {options}
                      </option >
                    ))}
                </select >

              </div>

              <div style={{ marginRight: '5%' }}>
                <font position='start' size="4" color="#3A2872">Fecha de terminación:</font>
                <input type='date' style={{ width: '90%' }} variant="standard" name="date" required className="inputMaterialclumns" label='ORGANIZACION' value={consolaSeleccionada && consolaSeleccionada.date} onChange={handleChangeRegister} />
              </div>
            </div>
            <br />
            <div >
              <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
              <font size="4" color="#BCB7B7"> (¿cuáles fueron los objetivos y resultados obtenidos?) </font><br />
              <textarea style={{ height: '152px', width: '93%', maxHeight: '152px', MaxWidth: '93%' }} type='text' variant="standard" name="descriptions" value={consolaSeleccionada && consolaSeleccionada.descriptions} required className="inputMateriallistcountry5" label='Descripción del proyecto' onChange={handleChangeRegister} />
              <br />
            </div>
            <br />
            <div style={{ display: 'flex' }} >
              <div>
                <font position='start' size="4" color="#3A2872"> Nombres*</font><br />
                <input type='text' name="nameconcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.nameconcta} onChange={handleChangeRegister} />
              </div>
              <div style={{ marginLeft: '8%' }}>
                <font position='start' size="4" color="#3A2872"> Apellidos*</font><br />
                <input type='text' name="lastnameconcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.lastnameconcta} onChange={handleChangeRegister} />
              </div>
            </div>
            <br />
            <div style={{ display: 'flex' }} >
              <div >
                <font position='start' size="4" color="#3A2872"> Correo-Electrónico*</font>
                <input type='email' name="emailConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.emailConcta} onChange={handleChangeRegister} defaultValue={cookies.get('email')} />
              </div>
              <div style={{ marginLeft: '-0.5%' }}>
                <font position='start' size="4" color="#3A2872"> Nombre de usuario*</font>
                <input type='text' name="usernameConcta" className="inputMaterialclumns" onChange={handleChangeRegister} value={consolaSeleccionada && consolaSeleccionada.usernameConcta} defaultValue={cookies.get('display')} />
              </div>
            </div>
            <br />
            <div style={{ display: 'flex' }} >
              <div >
                <font position='start' size="4" color="#3A2872">Teléfono**</font><br />
                <input type='number' name="phoneConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.phoneConcta} onChange={handleChangeRegister} />
              </div>
              <div style={{ marginLeft: '8%' }}>
                <font position='start' size="4" color="#3A2872"> País*</font>
                <select
                  className='inputMaterialclumns'
                  id='countryConcta'
                  style={{ height: '65%', width: '151%' }}
                  name="countryConcta"
                  variant="standard"
                  defaultValue="Colombia"
                  onChange={handleChangeCoutry}
                >
                  {
                    paises.map((options) => (
                      <option key={options} value={options}>
                        {options}
                      </option >
                    ))}
                </select >
              </div>
            </div>
            <br />
            <div style={{ display: 'flex' }} >
              <div >
                <font position='start' size="4" color="#3A2872"> Departamento:</font>
                <select
                  className='selecteListCountryProyectCTI'
                  id='listdepartament'
                  disabled={valueCountry}
                  style={{ width: '190%' }}
                  name="departmentConcta"
                  variant="standard"
                  onChange={handleChangeRegister}
                >
                  {
                    departamentos.map((options) => (
                      <option key={options} value={options}>
                        {options}
                      </option >
                    ))}
                </select >
              </div>

              <div style={{ marginLeft: '27%' }}>
                <font position='start' size="4" color="#3A2872"> Municipio:</font><br />
                <input disabled={valueCountry} type='text' variant="standard" name="munipalityConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.munipalityConcta} onChange={handleChangeRegister} />
              </div>
            </div>
            <br />
            <div style={{ display: 'flex' }} >
              <div >
                <font position='start' size="4" color="#3A2872"> Organización:</font>
                <input type='text' variant="standard" name="organizationsConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.organizationsConcta} onChange={handleChangeRegister} />
              </div>
              <div style={{ marginLeft: '1%' }}>
                <font position='start' size="4" color="#3A2872"> Otra organización:</font>
                <input type='text' variant="standard" name="otherOrganizationsConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.otherOrganizationsConcta} onChange={handleChangeRegister} />
              </div>
            </div>
          </Container>
        </div>
        <br />
        <Container>
          <div >
            <Checkbox
              checked={value1}
              onChange={onchangeCheckValue1}
              label="Acepto todas las políticas y condiciones de la web"
            />
            <Link target='_blank' color="#3A2872" href={Base + "media/politicasdeweb/Politica_seguridad_informacion_10-10-2022.pdf"}>
              <font size="3" color="#3A2872"> Acepto todas las políticas y condiciones de la web</font>
            </Link>
            <br />
            <Checkbox
              checked={value2}
              onChange={onchangeCheckValue2}
              label="Acepto todo el tratamiento de datos."
            /><Link target='_blank' color="#3A2872" href={Base + "media/politicasdeweb/Politica_tratamiento_proteccion_datos_10-10-2022.pdf"}>
              <font size="3" color="#3A2872"> Acepto todo el tratamiento de datos.</font>
            </Link>
            <br />
          </div>
        </Container>
        <br />
        <div align='center'>
          <ThemeProvider theme={customTheme}>
            {value2 === false || value1 === false ?
              <BootstrapButton onClick={() => { setOpenPolitica(!openPolitica) }} variant="contained">¡Participar!</BootstrapButton>
              :
              <BootstrapButton onClick={peticionPostProyect} variant="contained">¡Participar!</BootstrapButton>}

          </ThemeProvider>
        </div>
        <br /><br />
        <Foot />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          ¡Se guardo correctamente!
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Revisa que los campos estén completos
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openPolitica} autoHideDuration={6000} onClose={handleClosePolitica}>
        <Alert onClose={handleClosePolitica} severity="warning" sx={{ width: '100%' }}>¡Debes de marcar todas las políticas de tratamiento y seguridad de la web!</Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={opencountry} autoHideDuration={6000} onClose={handleClosecountry}>
        <Alert onClose={handleClosecountry} severity="warning" sx={{ width: '100%' }}>¡Los campos departamento y municipio del contacto fueron inhabilitados, por que seleccionastes un país diferente que Colombia!</Alert>
      </Snackbar>
    </div>
  );
}
