import React from 'react';
import { Container } from '@mui/material';
import './stylesCms.css'

export default function Visualizador(data) {
  const consolaSeleccionada = data.new === true ? data.rowId : data.row.rowId.row

  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div>
             
        <Container >
        <font className='titleSus' size="7" color="#4EBBB7"> Proyectos CTeI registrados</font>
          <br /><br />
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Nombre del proyecto*</font>
              <input type='text' variant="standard" name="nameproyect" required className="inputMaterialclumns" label='NOMBRE' value={consolaSeleccionada && consolaSeleccionada.nameproyect} />
            </div>
            <div>
              <font position='start' size="4" color="#3A2872"> ¿Quién lo realizó?*</font>
              <input type='text' variant="standard" name="author" required className="inputMaterialclumns" label='APELLIDO' value={consolaSeleccionada && consolaSeleccionada.author} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div >
              <font position='start' size="4" color="#3A2872">Municipio dónde se ejecuta:</font>
              <input variant="standard" name="munipalitylocation" required className="inputMaterialclumns" label='MUNICIPIO' value={consolaSeleccionada && consolaSeleccionada.munipalitylocation} />
            </div>
            <div style={{ marginRight: '5%' }}>
              <font position='start' size="4" color="#3A2872"> Estado:</font><br />
              <input variant="standard" name="status"  style={{ width: '80%' }}  className="inputMaterialclumns" label='status' value={consolaSeleccionada && consolaSeleccionada.status} />
            </div>

            <div style={{ marginLeft: '1%' }}>
              <font position='start' size="4" color="#3A2872">Fecha de terminación:</font>
              <input type='date' style={{ width: '70%' }} variant="standard" name="date" required className="inputMaterialclumns" label='ORGANIZACION' value={consolaSeleccionada && consolaSeleccionada.date} />
            </div>
          </div>
          <br />
          <div >
            <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
            <font size="4" color="#BCB7B7"> (¿cuáles fueron los objetivos y resultados obtenidos?) </font><br />
            <textarea style={{ height: '152px', width: '93%', maxHeight: '152px', MaxWidth: '93%' }} type='text' variant="standard" name="descriptions" value={consolaSeleccionada && consolaSeleccionada.descriptions} required className="inputMateriallistcountry5" label='Descripción del proyecto' />
            <br />
          </div>
          <br />
          <div style={{ display: 'flex' }} >
            <div>
              <font position='start' size="4" color="#3A2872"> Nombres*</font><br />
              <input type='text' name="nameconcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.nameconcta} />
            </div>
            <div style={{ marginLeft: '8%' }}>
              <font position='start' size="4" color="#3A2872"> Apellidos*</font><br />
              <input type='text' name="lastnameconcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.lastnameconcta} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }} >
            <div >
              <font position='start' size="4" color="#3A2872"> Correo-Electrónico*</font>
              <input type='email' name="emailConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.emailConcta} />
            </div>
            <div style={{ marginLeft: '-0.5%' }}>
              <font position='start' size="4" color="#3A2872"> Nombre de usuario*</font>
              <input type='text' name="usernameConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.usernameConcta} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }} >
            <div >
              <font position='start' size="4" color="#3A2872">Teléfono**</font><br />
              <input type='number' name="phoneConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.phoneConcta} />
            </div>
            <div style={{ marginLeft: '8%' }}>
              <font position='start' size="4" color="#3A2872"> País*</font><br />
              <input type='text' name="countryConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.countryConcta} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }} >
            <div >
              <font position='start' size="4" color="#3A2872"> Departamento:</font><br/>
              <input type='text' name="departmentConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.departmentConcta} />
            </div>

            <div style={{ marginLeft: '8%' }}>
              <font position='start' size="4" color="#3A2872"> Municipio:</font><br />
              <input disabled type='text' variant="standard" name="munipalityConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.munipalityConcta} />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }} >
            <div >
              <font position='start' size="4" color="#3A2872"> Organización:</font>
              <input type='text' variant="standard" name="organizationsConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.organizationsConcta} />
            </div>
            <div style={{ marginLeft: '2%' }}>
              <font position='start' size="4" color="#3A2872"> Otra organización:</font>
              <input type='text' variant="standard" name="otherOrganizationsConcta" className="inputMaterialclumns" value={consolaSeleccionada && consolaSeleccionada.otherOrganizationsConcta} />
            </div>
          </div>
        </Container>
      </div>
      <br />
    </div>
  );
}
