import React, { useState } from 'react';
import { Checkbox, Button, Container, Alert, Snackbar, Link, CssBaseline } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot.js'
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import './stylesSuscripcion.css'
import { baseUrlUser, Base, compUrls } from '../../Control/Autentication/urlsServer.js'
import axios from 'axios';
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { paises, departamentos } from '../Extras/Referencias.js'
import Cookies from 'universal-cookie';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useEffect } from 'react';

const cookies = new Cookies();
export default function Suscripcion() {
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nameUser: '',
    lastNameUser: '',
    display: '',
    mailUser: '',
    telephoneUser: '',
    nameOrganization: '',
    country: 'Colombia',
    departament: '',
    municity: '',
    otherOrganization: '',
    indicador: '',
  })
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [value1, setvalue1] = useState(false);
  const [value2, setvalue2] = useState(false);
  const [valueCountry, setvalueCountry] = useState(false);

  const [opencountry, setOpencountry] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openPolitica, setOpenPolitica] = useState(false);
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const peticionPostUsuario = async () => {
    try {
      await axios.post(baseUrlUser, consolaSeleccionada, compUrls)
        .then(response => {
          console.log(response.data.userId);
          cookies.set('idUser', response.data.userId);
          RegistrosSucces();
          window.location.href = "/Menu/Inicio"
          cookies.set('user', consolaSeleccionada.nameUser, consolaSeleccionada.lastNameUser, { path: "/" });
          cookies.set('displayName', consolaSeleccionada.displayName, { path: "/" });
          cookies.set('email', consolaSeleccionada.email, { path: "/" });
          cookies.set('emailVerified', true, { path: "/" });

        })
    }
    catch (err) {
      setOpenError(!openError)
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false)
  };

  const handleClosePolitica = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPolitica(false);
  };
  const handleClosecountry = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpencountry(false);
  };

  const onchangeCheckValue1 = () => {
    setvalue1(!value1)
  }
  const onchangeCheckValue2 = () => {
    setvalue2(!value2)
  }
  const handleChangeCoutry = () => {
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      'country': document.getElementById("country").value,
      'departament': document.getElementById("departament").value,
    }

    ))
    if (document.getElementById("country").value === "Colombia") {
      setvalueCountry(false)
    }
    else {
      setvalueCountry(true)
      setOpencountry(!opencountry)
      setConsolaSeleccionada(prevState => ({
        ...prevState,
        departmentConcta: '',
        munipalityConcta: ''

      }))
    }
  }

  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
      mailUser: (cookies.get('email'))
    }
    ))
  }

  const RegistrosSucces = () => {
    setConsolaSeleccionada(
      {
        nameUser: '',
        lastNameUser: '',
        display: '',
        mailUser: '',
        telephoneUser: '',
        nameOrganization: '',
        country: '',
        departament: '',
        municity: '',
        otherOrganization: '',
        indicador: '',
      }
    )
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 15,
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>      
      <CssBaseline/>
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Suscribirse"} /> : <HeaderMovil />}
      </div>
      <Container>
        <Container color='#fff'><br /><br />
          <div>
            <font className='titleSus' size="7" color="#4EBBB7">¡Únete a la comunidad del conocimiento y entérate de todos los saberes del Cauca! </font><br />
          </div>
          <font className='contetSus' size="5" color="#3A2872">Para acceder a todo el material como PDF, videos y podcast, debes de suscribirte a la página.</font>
          <font className='contetSus2' size="5" color="#3A2872"> Esta acción es totalmente Gratuita </font>
          <font className='contetSus' size="5" color="#3A2872">y no tiene fecha de expiración.</font>
          <br /><br />
          <div className='Campos1'>
            <font position='start' size="4" color="#3A2872"> Nombres*</font><br />
            <input type='text' variant="standard" name="nameUser" required className="inputMaterial" label='NOMBRE' value={consolaSeleccionada && consolaSeleccionada.nameUser} onChange={handleChangeRegister} />
            <br /><br />
            <font position='start' size="4" color="#3A2872"> Apellidos*</font><br />
            <input type='text' variant="standard" name="lastNameUser" required className="inputMaterial" label='APELLIDO' value={consolaSeleccionada && consolaSeleccionada.lastNameUser} onChange={handleChangeRegister} />
            <br /><br />
          </div>

        </Container>
        <div className="input_container">
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872">Correo-Electrónico*</font>
            <input type='email' disabled name="mailUser" className="inputMateriallistcountry" value={consolaSeleccionada && consolaSeleccionada.email} onChange={handleChangeRegister} defaultValue={cookies.get('email')} />
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> Nombre de usuario*</font>
            <input type='text' name="display" className="inputMateriallistcountry2" onChange={handleChangeRegister} value={consolaSeleccionada && consolaSeleccionada.display} defaultValue={cookies.get('display')} />
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872">Teléfono*</font>
            <input type={'number'} name="telephoneUser" required className="inputMateriallistcountry" label='Telefono' value={consolaSeleccionada && consolaSeleccionada.telephoneUser} onChange={handleChangeRegister} />
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> País*</font>
            <select
              className='inputMateriallistcountry2'
              id='country'
              style={{ height: '50%', width: '140%' }}
              name="country"
              variant="standard"
              onChange={handleChangeCoutry}
            >
              {
                paises.map((options) => (
                  <option key={options} value={options}>
                    {options}
                  </option >
                ))}
            </select >
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> Departamento:</font>
            <select
              className='inputMateriallistcountry'
              id='departament'
              disabled={valueCountry}
              style={{ width: '105%' }}
              name="departament"
              variant="standard"
              onChange={handleChangeRegister}
            >
              {
                departamentos.map((options) => (
                  <option key={options} value={options}>
                    {options}
                  </option >
                ))}
            </select >
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> Municipio:</font>
            <input disabled={valueCountry} value={consolaSeleccionada && consolaSeleccionada.country === 'Colombia' ? consolaSeleccionada && consolaSeleccionada.municity : 'No aplica'} variant="standard" name="municity" required className="inputMateriallistcountry2" label='MUNICIPIO' onChange={handleChangeRegister} />
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> Organización:</font>
            <input type='text' variant="standard" name="nameOrganization" required className="inputMateriallistcountry" label='ORGANIZACION' value={consolaSeleccionada && consolaSeleccionada.nameOrganization} onChange={handleChangeRegister} />
            <br />
          </div>
          <div className='Campos2'>
            <font position='start' size="4" color="#3A2872"> Otra organización:</font>
            <input type='text' variant="standard" name="otherOrganization" required className="inputMateriallistcountry2" label='OTRA ORGANIZACION' value={consolaSeleccionada && consolaSeleccionada.otherOrganization} onChange={handleChangeRegister} />
          </div>
        </div>
        <br />

        <div className='checkInput'>
          <Checkbox
            checked={value1}
            onChange={onchangeCheckValue1}
            label="Acepto todas las políticas y condiciones de la web"
          />
          <Link target='_blank' color="#3A2872" href={Base + "media/politicasdeweb/Politica_seguridad_informacion_10-10-2022.pdf"}>
            <font size="3" color="#3A2872"> Acepto todas las políticas y condiciones de la web</font>
          </Link>
          <br />
          <Checkbox
            checked={value2}
            onChange={onchangeCheckValue2}
            label="Acepto todo el tratamiento de datos."
          /><Link target='_blank' color="#3A2872" href={Base + "media/politicasdeweb/Politica_tratamiento_proteccion_datos_10-10-2022.pdf"}>
            <font size="3" color="#3A2872"> Acepto todo el tratamiento de datos.</font>
          </Link>
          <br />
        </div>
      </Container>
      <br />
      <div align='center'>
        <ThemeProvider theme={customTheme}>
          <div align='center'>
            <ThemeProvider theme={customTheme}>
              {value2 === false || value1 === false ?
                <BootstrapButton onClick={() => { setOpenPolitica(!openPolitica) }} variant="contained">¡Guardar!</BootstrapButton>
                :
                <BootstrapButton onClick={peticionPostUsuario} variant="contained">¡Guardar!</BootstrapButton>}

            </ThemeProvider>
          </div>
        </ThemeProvider>
      </div>
      <br /><br />
      {widthResize > 750 ? <Foot /> : <FootMovil />}

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Revisa que los campos estén completos
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openPolitica} autoHideDuration={6000} onClose={handleClosePolitica}>
        <Alert onClose={handleClosePolitica} severity="warning" sx={{ width: '100%' }}>¡Debes de marcar todas las políticas de tratamiento y seguridad de la web!</Alert>
      </Snackbar>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={opencountry} autoHideDuration={6000} onClose={handleClosecountry}>
        <Alert onClose={handleClosecountry} severity="warning" sx={{ width: '100%' }}>¡Los campos: departamento y municipio fueron inhabilitados, por que seleccionastes un país diferente que Colombia!</Alert>
      </Snackbar>
    </div>
  );
}
