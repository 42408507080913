// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";

import { getFirestore } from 'firebase/firestore/lite';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyARaLNHeMpQR3nsh9NrhzVMMyI4cJXJ_Fs",
    authDomain: "cienciayesencia-4a479.firebaseapp.com",
    projectId: "cienciayesencia-4a479",
    storageBucket: "cienciayesencia-4a479.appspot.com",
    messagingSenderId: "22518451339",
    appId: "1:22518451339:web:785a48b9fb3b66290a188e",
    measurementId: "G-PYBL2M1QT7"
  };
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
let db = GetDB();
const auth = getAuth(firebase);

export const authentication = (proveedor, setAuth) => {
    var provider = new GoogleAuthProvider();
    if (proveedor === "Facebook")
        provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
        .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            //var credential = result.credential;
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            setAuth(user)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // ...
        });
}
///add values
export function GetDB() {
    var db = getFirestore(firebase);
    return db;
}

///persistencia
export const saveValue = (collection, value) => {
    var db = GetDB();
    db.collection(collection).add(value)
        .then((docRef) => {
            //   console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            //   console.error("Error adding document: ", error);
        });
}

///persistencia
export const saveValueWithId = (collection, doc, value) => {
    var db = GetDB();
    db.collection(collection).doc(doc).set(value)
        .then(() => {
            //   console.log("Document written with ID: ", doc);
        })
        .catch((error) => {
            //   console.error("Error adding document: ", error);
        });
}
//Leer datos datos

export const getfromDocument = async (collection, doc, query = "") => {
    var db = GetDB();
    return await db.collection(collection).doc(doc).get();
    // return db.collection(collection).doc(doc).get().then((coll) => {
    //     return coll
    // });
}


export const getfromCollection = async (collection, doc, query = "") => {
    return await db.collection(collection).get();
}
