import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LogComunidad from '../../Imagenes/iconoComunidad.png'
import Logmic from '../../Imagenes/iconomic.png'
import LogMunic from '../../Imagenes/municipios.png'
import { CardContent } from '@mui/material';

const steps = [
  {
    image: LogComunidad,
    label: 'Comunidad de práctica',
    text1: 'Trabajamos con',
    text2: 'gestores territoriales +',
    text3: 'Líderes comunitarios +',
    text4: 'comité de radio',
  },
  {
    image: Logmic,
    label: '168 Podcast producidos',
    text1: 'El PodCast como herramienta',
    text2: 'narrativa y de divulgación de',
    text3: 'los saberes del Cauca',
    text4: ''
  },
  {
    image: LogMunic,
    label: ' 7 Subregiones del Cauca',
    text1: 'Investigamos y validamos los',
    text2: 'procesos CTel del territorio y su',
    text3: 'pertinencia en la comunidad',
    text4: ''
  },
];

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <br />
        <div align="center"> <img src={steps[activeStep].image} width={steps[activeStep].label === 'Comunidad en práctica' ?
          '290' : steps[activeStep].label === '168 Podcast producidos' ? '80' : '130'} alt={steps[activeStep].label} />
          <br />
          <div className='textInformativo'>
            <font className='titleInitio' size="5.5" color="#3A2872"> {steps[activeStep].label}</font><br />
            <font size="5" color="#3A2872">{steps[activeStep].text1}</font><br />
            <font size="5" color="#3A2872">{steps[activeStep].text2}</font><br />
            <font size="5" color="#3A2872">{steps[activeStep].text3}</font><br />
            <font size="5" color="#3A2872">{steps[activeStep].text4}</font><br />
          </div><br /><br />
        </div>
      </CardContent>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button sx={{
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }}

            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Siguiente
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button sx={{
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }} onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}