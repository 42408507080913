import * as React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Compartir from '../../Imagenes/Compartir2.svg';
import CompartirPublication from '../Extras/compartir.js'
import Descarga from '../../Imagenes/Descarga.svg';
import { Alert, CardMedia, IconButton, Modal, Snackbar } from '@mui/material';
import { useState } from 'react';
import { Base } from '../../Control/Autentication/urlsServer';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
function FeaturedPost(props) {
  const { post } = props;
  const [showMedia, setShowMedia] = useState(false);

  const [modalCompartir, setModalCompartir] = useState(false);
  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }
  const mediaPlay = (
    <div style={{
      width: '80%',
      height: '50%',
      position: 'absolute',
      top: '5%',
      left: '10%',
      borderStyle: 'solid',
      borderColor: '#fff',
      backgroundColor: "#fff",
    }}>
      <CardMedia
        height='150%'
        component="img"
        image={Base + post.imagenMaterial}
        alt={post.titleMaterial}
      />
    </div>)

  const bodyCompartir = (
    <div>
      <CompartirPublication key={post.id} post={post} />

    </div>)

  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <Grid item xs={1} md={20}>
        <div style={{ maxWidth: 1505, display: 'flex' }}>
          <CardMedia
            height={150}
            component="img"
            sx={{ width: 200, cursor: 'pointer' }}
            onClick={() => { setShowMedia(!showMedia) }}
            image={Base + post.imagenMaterial}
            alt={post.titleMaterial}
          />
          <CardContent>
            <div className='topMenuP'>
              <div>
                <font color="#04B5AF" size="5"> <strong> {post.titleMaterial} </strong> </font>
              </div>
              <div >
                {cookies.get('emailVerified') === 'true' ?
                  <IconButton target='_blank' href={Base + post.materiaFile}>
                    <img src={Descarga} width='40' alt='40' />
                  </IconButton> :
                  <IconButton onClick={() => setOpen(!open)} target='_blank' >
                    <img src={Descarga} width='40' alt='40' />
                  </IconButton>}
                <IconButton onClick={abrirCerrarModalCompartir} >
                  <img src={Compartir} width='40' alt='40' />
                </IconButton>
              </div>
            </div>
            <font color='#3A2872' >
              {post.DescriptionMateria}
            </font>
          </CardContent>
          <div />
        </div>
        <br />
      </Grid>

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>¡Debes de iniciar sesión para descargar contenido!</Alert>
      </Snackbar>
    </div>
  );
}
export default FeaturedPost;
