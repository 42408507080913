import React from 'react';
import { Button, Container, CssBaseline, InputLabel, Pagination, Stack } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js';
import Foot from '../../Control/Header-Footer/foot.js';
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import Grid from '@mui/material/Grid';
import ProyectCtei from './proyectCienc.js';
import ProyectCteiMovil from './proyectCiencMovil.js';
import notfound from '../../Imagenes/404-error.png'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { Subregion } from '../Extras/Referencias.js'
import { infografiasUrl, infografias, compUrls } from '../../Control/Autentication/urlsServer.js';
import './stylesProyecto.css'
import { useState } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axios from 'axios';
import { useEffect } from 'react';

export default function ProyectsCtel() {
  const [dataSubregion, setDataSubregion] = useState('Pacífico')
  const [DataProyectCTeI, setDataProyectCTeI] = useState([])
  const [indexlist, setindexlist] = useState(0)
  const [DataIndex, setDataIndex] = useState(0)
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const resize = () => {
    setWidthResize(window.innerWidth)
  }
  const GetInfografia = async () => {
    let listdata = []
    let listPodcats = []
    let listNumber = 0
    await axios.get(infografiasUrl + dataSubregion, compUrls)
      .then(response => {
        listNumber = response.data.length / 3
        listPodcats = response.data.reverse()
        for (let index = 0; index < listNumber; index++) {
          listdata.push(listPodcats.splice(0, 3));
        }

        setDataIndex(listdata.length)
        setDataProyectCTeI(listdata)
      });
  }

  const GetInfografiaAll = async () => {
    let listdata = []
    let listPodcats = []
    let listNumber = 0
    await axios.get(infografias, compUrls)
      .then(response => {

        listNumber = response.data.length / 3
        listPodcats = response.data.reverse()
        for (let index = 0; index < listNumber; index++) {
          listdata.push(listPodcats.splice(0, 3));
        }
        setDataIndex(listdata.length)
        setDataProyectCTeI(listdata)
      });
  }

  const onChangePagination = (e) => {
    setindexlist(e.target.innerText - 1);
  }

  const handleChange = () => {
    setDataSubregion(document.getElementById("selecteList").value)
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    height: 50,
    top: 28,
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
    fontStyle: 'normal',
    fontFamily: 'Sofia Sans'
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });

  useEffect(() => {
    GetInfografiaAll();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <CssBaseline />
      <div align="center">
        {widthResize > 750 ?
          <Header post={"proyectCtel"} /> : <HeaderMovil />}
      </div>
      <Container >
        <br /><br />
        <div align="start">
          <font className="title" size={widthResize > 750 ? "10" : "7"}>Infografías de proyectos CTeI en el Cauca</font>
          <font style={{ fontWeight: 'lighter', fontStyle: 'italic' }} color="#4EBBB7" size={widthResize > 750 ? "7.5" : "5"}>Conoce nuestra comunidad en práctica</font><br />
          <font size="5" color="#3A2872">Para hacer ciencia se necesita de una comunidad. Es por eso que en Ciencia&Esencia nos preocupamos por construir una comunidad de práctica en cada subregión caucana, para así conocer todos los proyectos, procesos y saberes en rededor a la Ciencia, Tecnología e Innovación (CTeI) .</font>
        </div>
        <br /><br />
        <div className='topMenuInputs'>
          <div>
            <InputLabel shrink >
              <font className="inputLabel" size="6">Subregión</font>
            </InputLabel>
            <select
              id='selecteList'
              className='selecteList'
              defaultValue="Cauca"
              onChange={handleChange}
            >
              {Subregion.map((data) => (
                <option disabled={data === 'Cauca' ? true : false}
                  key={data} value={data}>
                  {data}
                </option>
              ))}
            </select>
          </div>
          <ThemeProvider theme={customTheme}>
            {widthResize > 750 ?
              <BootstrapButton sx={{ marginLeft: '5px' }} onClick={GetInfografia} variant="contained" >
                Buscar
              </BootstrapButton>
              : <BootstrapButton sx={{ marginLeft: '5px' }} onClick={GetInfografia} variant="contained" >
                Buscar
              </BootstrapButton>}
          </ThemeProvider>
        </div>
        <br /><br />
        <br /><br />
        {DataProyectCTeI.length !== 0 ? (
          <Grid container spacing={1}>
            {DataProyectCTeI[indexlist].map((dataCTeI, i) =>
              dataCTeI.disablePublication === true ?
                widthResize > 750 ?
                  <div>
                    <ProyectCtei key={i} post={dataCTeI} /> </div>
                  :
                  <Container>
                    <ProyectCteiMovil key={i} post={dataCTeI} index={i} />
                    {i + 1 !== DataProyectCTeI.length ?
                      <div>
                        <div style={{ background: '#4EBBB7', color: '#fff', height: '5px' }}>.</div>
                        <br /><br />
                      </div>
                      : ""}
                  </Container>
                : ""
            )}
            <div style={{ marginLeft: widthResize > 750 ? '45%' : '35%' }}>
              <Stack spacing={2}>
                <Pagination hideNextButton={true} hidePrevButton={true} count={DataIndex} variant="outlined" color="secondary" onChange={onChangePagination} />
              </Stack>
            </div>

          </Grid>
        ) :
          widthResize > 750 ?
            <img width='50%' style={{ marginLeft: '300px' }} src={notfound} alt={'banner'} /> :
            <img width='100%' src={notfound} alt={'banner'} />
        }
      </Container>
      {widthResize > 750 ? <Foot /> : <FootMovil />}
    </div>
  );
}