import React, { useEffect, useState } from 'react';
import { Box, Button, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import { Baneer, compUrls, Base } from '../../Control/Autentication/urlsServer'
import '../MenuInicial/stylesInicio.css'
import axios from 'axios';

export default function Index() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setsteps] = React.useState([]);
  const maxSteps = steps.length;


  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const playProgress = (
    <Box sx={{ maxWidth: '100%' }}>
      {
        steps.length !== 0 ?
        steps[activeStep].id===36 ?
            <img width='100%' style={{ cursor:'pointer'}} height='50%' src={Base + steps[activeStep].imagen} onClick={()=>{window.location.href = steps[activeStep].id===36 ? "/Menu/evento" : ""}} alt={'banner'} />:
            <img width='100%' height='50%' src={Base + steps[activeStep].imagen} alt={'banner'} />

          : ""
      }
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button sx={{
            marginTop: widthResize > 750 ? '-38%' : '-50%',
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            ) : (
              <KeyboardArrowRight sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            )}
          </Button>
        }

        backButton={
          <Button sx={{
            marginTop: widthResize > 750 ? '-38%' : '-50%',
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }} onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            ) : (
              <KeyboardArrowLeft sx={{ fontSize: widthResize > 750 ? '80px' : '40px' }} />
            )}
          </Button>
        }
      />
    </Box>
  )
  const GetBanner = async () => {
    await axios.get(Baneer, compUrls)
      .then(response => {
        setsteps(response.data);
      });
  }

  if (steps.length === 0) {
    GetBanner()
  }
  useEffect(() => {
    window.addEventListener('resize', resize);
    const timer = setInterval(() => {
      setActiveStep(activeStep <= 2? activeStep + 1 : 0)
    }, 10000);
    return () => {
      clearInterval(timer);
    };

  }, [activeStep]);

  return (
    <div>
      {playProgress}
    </div>
  );
}