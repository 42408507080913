import React, { useState } from 'react';
import { Badge, Button, Card, CardContent, Checkbox, CircularProgress, Container, IconButton, Typography } from '@mui/material';
import MmsIcon from '@mui/icons-material/Mms';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Descarga from '../../Imagenes/Descarga.svg';
import { Base, imagenInfografiasUp, infografias, infografiasUp, compUrls, imagenInfografias } from '../../Control/Autentication/urlsServer';
import axios from 'axios';
import { Subregion, Color } from '../Extras/Referencias.js'
import './stylesCms.css'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function Visualizador(data) {
  const selection = data.new === true ? data.rowId : data.row.rowId.row
  const [consolaSeleccionada, setConsolaSeleccionada] = useState(selection)
  const [estadoConsulta, setEstadoConsulta] = useState(false)

  const [media, setMedia] = useState(false)
  const [fileData, setfileData] = useState('');
  const [fileData2, setfileData2] = useState('');

  const [fileinfografias, setfileinfografias] = useState({
    'infografiasFile': '',
    'infografiasImagen': '',
    'infografia': consolaSeleccionada.id
  })

  const GetimagenInfografias = async () => {
    await axios.get(imagenInfografiasUp + consolaSeleccionada.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data)
        setfileinfografias(response.data);
      })
  }
  const handleChangeRegister = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value,
    }
    ))
  }

  
  const  handleChangeRegisterfile=(e) =>{
    setfileinfografias(prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0]
    }

    ))
    var files = e.target.files;
    for (var i = 0, f; f = files[i]; i++) {
         if (f.type!=='image/jpeg') {
              continue;
         }
     
         var reader = new FileReader();
         
         reader.onload = (function(theFile) {
             return function(e) {
              setfileData2(e.target.result)
             };
         })(f);

         reader.readAsDataURL(f);
     }
}
  const handleChangeCheck = e => {
    const { name, checked } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: checked,
    }
    ))
  }

  const restData = () => {
    setConsolaSeleccionada(
      {
        'Descriptioninfografias': '',
        'infografiasFile': '',
        'infografiasImagen': '',
        'SubRegionRegister': '',
        'colorPublication': '#F7AC5B',
        'dateLastUpdate': '',
        'dateinfografias': '',
        'disablePublication': false,
        'id': '',
        'palabrasclaves': '',
        'titleinfografias': '',
        'userCreator': 1
      }
    )
    setfileinfografias({
      'infografiasFile': '',
      'infografiasImagen': '',
      'infografia': consolaSeleccionada.id
    });
  }

  const Putinfografias = () => {
    axios.put(infografiasUp + consolaSeleccionada.id, consolaSeleccionada, compUrls)
      .then(response => {
        if (media === true) {
          deleteimagenInfografias()
        }
        else {
          restData()
          alert('Se registro correctamente, actualiza la tabla')
          setEstadoConsulta(false)
        }
      });
  }

  const Postinfografias = async () => {
    await axios.post(infografias, consolaSeleccionada, compUrls)
      .then(response => {
        if (media === true) {
          deleteimagenInfografias(response.data.id)
        }
        else {
          setEstadoConsulta(false)
          alert('Se registro correctamente, actualiza la tabla')
        }
      })

  }

  const deleteimagenInfografias = async (dataid) => {
    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    await axios.delete(imagenInfografiasUp + id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { PostimagenInfografias(id) })
      .catch(error => { PostimagenInfografias(id) })
  }

  const PostimagenInfografias = async (dataid) => {

    var id = consolaSeleccionada.id !== undefined ? consolaSeleccionada.id : dataid
    var dataimg = {
      'infografiasFile': fileinfografias.infografiasFile,
      'infografiasImagen': fileinfografias.infografiasImagen,
      'infografias': id
    }
    await axios.post(imagenInfografias, dataimg, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        restData();
        setEstadoConsulta(false)
        alert('Se registro correctamente, actualiza la tabla');
      })
  }

  if (fileData === '') {
    GetimagenInfografias();
  }

  const podcast = (
    <div style={{ display: 'flex' }}>
      
      <Card
        sx={{
          borderRadius: 8,
          width: 450,
          minWidth: 250,
          minHeight: 150,
          height: 340,
          cursor: 'pointer',
          borderStyle: 'solid',
          borderColor: consolaSeleccionada.colorPublication,
        }}>
        {fileinfografias.infografiasImagen !== undefined ?
          <img src={media === true && fileData2 !== '' ? fileData2: Base +fileData.infografiasImagen} width='105%' alt={consolaSeleccionada.titleinfografias} />
          : ""}
      </Card>
      <CardContent>
        <Typography variant="h5">
          <div className='topMenuP'>
            <div>
              <font className='titleProyect' color="#3A2872" size="5"><strong>{consolaSeleccionada.titleinfografias}</strong> </font>
            </div>
            <div>
              <IconButton >
                <img src={Descarga} width='40' alt='40' />
              </IconButton>

              <IconButton >
                <img src={coment} width='40' alt='40' />
              </IconButton>

              <IconButton   >
                <Badge badgeContent={0} color='error'>
                  <img src={Interativo} width='40' alt='40' />
                </Badge>
              </IconButton>
              <IconButton  >
                <img src={Compartir} width='40' alt='40' />
              </IconButton>
            </div>
          </div>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <font className='h1' size="5" color='#3A2872' > {consolaSeleccionada.Descriptioninfografias}</font>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <font className='titleProyect' color="#3A2872" size="5"> <strong>Palabras claves:</strong> </font>
          <font className='h1' size="5" color='#3A2872' >{consolaSeleccionada.palabrasclaves} </font>
        </Typography>
      </CardContent>
      <div />
    </div>
  )

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 25,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  return (

    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div style={{ display: 'flex' }}>
        <Container>
      {estadoConsulta === true ?
          <div style={{position:'absolute', marginLeft:'47%', marginTop:'40%'}} >
            <CircularProgress size={70}/>
          </div> : ""}
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Titulo </font><br />
              <input className='inputs' type='text' variant="standard" name="titleinfografias" required label='Titulo' value={consolaSeleccionada && consolaSeleccionada.titleinfografias} onChange={handleChangeRegister} />
            </div>
            <div>

              <font position='start' size="4" color="#3A2872"> Color</font><br />
              <select
                id='colorPublication'
                className='inputs'
                name='colorPublication'
                defaultValue={consolaSeleccionada.colorPublication}
                style={{ width: '100%', fontFamily: 'Sofia Sans' }}
                onChange={handleChangeRegister}
              >
                {Color.map((options) => (
                  <option value={options.value}>
                    {options.color}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginLeft: '2%' }}>
              <font position='start' size="4" color="#3A2872"> SubRegion</font><br />
              <select
                id='SubRegionRegister'
                className='inputs'
                name='SubRegionRegister'
                defaultValue={consolaSeleccionada.SubRegionRegister}
                style={{ width: '100%', fontFamily: 'Sofia Sans' }}
                onChange={handleChangeRegister}
              >
                {Subregion.map((options, i) => (
                  <option
                    disabled={options === 'Cauca' ? true : false}
                    key={i} value={options}>
                    {options}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Palabras claves </font><br />
              <input className='inputs' type='text' style={{ width: '195%' }} variant="standard" name="palabrasclaves" required label='palabrasclaves' value={consolaSeleccionada && consolaSeleccionada.palabrasclaves} onChange={handleChangeRegister} />
            </div>
          </div>
          <br />
          <div >
            <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
            <textarea style={{ height: '152px', width: '70%', maxHeight: '152px', maxWidth: '70%' }} value={consolaSeleccionada && consolaSeleccionada.Descriptioninfografias} type='text' variant="standard" name="Descriptioninfografias" required className="inputAreatext" label='Descripción' onChange={handleChangeRegister} />
            <br />
          </div>
          <ThemeProvider theme={customTheme}>
            <BootstrapButton onClick={() => { setMedia(!media) }} variant="contained">{media === false ? "Editar Archivos media" : "No editar Archivos media"}</BootstrapButton>
          </ThemeProvider>
          <br />
          {media === true ?
            <div style={{ display: 'flex' }}>
              <div>
                <font position='start' size="4" color="#3A2872"> Subir imagen </font><br />
                <IconButton component="label">
                  <input name='infografiasImagen' onChange={handleChangeRegisterfile} hidden accept="image/x-png,image/gif,image/jpeg" type="file" />
                  <MmsIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div>
              <font position='start' size="4" color="#fff"> ____ </font><br />
              <div>
                <font position='start' size="4" color="#3A2872"> Subir Archivo</font><br />

                <IconButton component="label">
                  <input name='infografiasFile' onChange={handleChangeRegisterfile} hidden accept="application/pdf" type="file" />
                  <PictureAsPdfIcon sx={{ fontSize: '50px' }} />
                </IconButton>
              </div>
            </div>
            : ""}
          <br />
        </Container>
        <div style={{ marginTop: '2%' }}>
          {podcast}
        </div>
      </div>

      <div style={{ marginLeft: '10%' }}>
        <Checkbox
          onChange={handleChangeCheck}
          name='disablePublication'
          checked={consolaSeleccionada.disablePublication}
          label="Disponible al publico."
        />
        <font size="3" color="#3A2872">Disponible al publico.</font>
      </div>
      <div align="center" style={{ marginTop: '-5%' }}>
        <ThemeProvider theme={customTheme}>
          {data.new === true ?
            <BootstrapButton disabled={estadoConsulta} onClick={()=>{Postinfografias();setEstadoConsulta(true)}} variant="contained">Guardar</BootstrapButton> :
            <BootstrapButton disabled={estadoConsulta} onClick={()=>{Putinfografias();setEstadoConsulta(true)}} variant="contained">Guardar</BootstrapButton>}
        </ThemeProvider>
      </div>
    </div>
  );
}
