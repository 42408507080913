import * as React from 'react';
import { useEffect, useState } from 'react';
import Listproyect from './listproyect.js'
import notfound from '../../Imagenes/404-error.png'
import { Container } from '@mui/material';
export default function MusicPlayerSlider(options) {

  const DataProyectCTeI = options.props
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);

  return (
    <>
      <Container sx={{ width: '106%', height: '100%' }} >
        <div style={{ overflowX: 'hidden', overflowY: 'scroll', height: widthResize > 1721 ? 920 : 600, width: '100%', display: 'flex' }}>
          <div style={{ width: '95%' }}>
            {DataProyectCTeI.length !== 0 ? (
              DataProyectCTeI.map((dataCTeI, i) => 
              
              (
                dataCTeI.disablePublication === true ?
                widthResize > 750 ?
                  <div>
                    <Listproyect key={dataCTeI.id} post={dataCTeI} index={i} />
                    {i + 1 !== DataProyectCTeI.length ?
                      <div>
                        <div style={{ background: '#4EBBB7', color: '#fff', height: '5px' }}>.</div>
                        <br /><br />
                      </div>
                      : <div><br /><br /><br /><br /><br /><br /> </div>}
                  </div>
                  :
                  <Container>
                    <Listproyect key={dataCTeI.id} post={dataCTeI} index={i} />
                    {i + 1 !== DataProyectCTeI.length ?
                      <div>
                        <div style={{ background: '#4EBBB7', color: '#fff', height: '5px' }}>.</div>
                        <br /><br />
                      </div>
                      : ""}
                  </Container>
                : ""
              )

              )
            )
              : <div align="center"> <img width='100%' src={notfound} alt={'notfound'} /> </div>
            }
          </div>
        </div>
      </Container>
    </>
  );
}