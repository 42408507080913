import  React, { useEffect,useState} from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Alert, Badge, Card, Container, Grid, IconButton, Modal, Snackbar } from '@mui/material';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Descarga from '../../Imagenes/Descarga.svg';
import CompartirPublication from '../Extras/compartir.js'
import Comments from '../Extras/Comments'
import { ReactionsCteiUrl, ReactionsCteiPostUrl, compUrls, Base, imagenInfografiasUp } from '../../Control/Autentication/urlsServer';
import './stylesProyecto.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function FeaturedPost(props) {
  const { post } = props;
  const [like, setLike] = useState(null);
  const [status, setstatus] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileData, setfileData] = useState('');
  const [modalComent, setModalComent] = useState(false);
  const [modalCompartir, setModalCompartir] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }

  const GetRectionsinition = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsCteiUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsCteiPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const GetimagenInfografias = async () => {
    await axios.get(imagenInfografiasUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }

  const mediaPlay = (
    <div>
      <div style={{
        width: '40%',
        height: '95%',
        position: 'absolute',
        top: '1%',
        left: '32%',
        borderStyle: 'solid',
        borderColor: '#fff',
        backgroundColor: "#fff",
      }}>
        <img src={Base + fileData.infografiasImagen} width='100%' height='100%' alt={post.titleinfografias} />
      </div>
    </div>
  )
  const bodyComent = (
    <div>
      <Comments key={post.id} post={post} />
    </div>
  )


  const bodyCompartir = (
    <div>
      <CompartirPublication key={post.id} post={post} />
    </div>)

  if (fileData === ''||fileData.infografias!==post.id) {
    GetimagenInfografias();
  }


  useEffect(() => {
    GetRectionsinition();
  });

  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <br />
      <Container>
        <Grid item xs={50} md={120}>
          <div style={{ display: 'flex' }}>
            <Card
              onClick={() => { setShowMedia(!showMedia) }}
              sx={{
                borderRadius: 8,
                width: 450,
                minWidth: 250,
                minHeight: 150,
                height: 340,
                cursor: 'pointer',
                borderStyle: 'solid',
                borderColor: post.colorPublication,
              }}>
              <img src={Base + fileData.infografiasImagen} width='105%' alt={post.titleinfografias} />
            </Card>
            <CardContent>
              <Typography variant="h5">
                <div className='topMenuP'>
                  <div>
                    <font className='titleProyect' color="#3A2872" size="5"><strong>{post.titleinfografias}</strong> </font>
                  </div>
                  <div>

                    {cookies.get('emailVerified') === 'true' ?
                      <IconButton target='_blank' href={Base + fileData.infografiasFile}>
                        <img src={Descarga} width='40' alt='40' />
                      </IconButton> :
                      <IconButton onClick={() => setOpen(!open)} target='_blank' >
                        <img src={Descarga} width='40' alt='40' />
                      </IconButton>}

                    <IconButton onClick={abrirCerrarModalComent}>
                      <img src={coment} width='40' alt='40' />
                    </IconButton>

                    <IconButton disabled={status} onClick={GetRections}  >
                      <Badge badgeContent={like} color='error'>
                        <img src={Interativo} width='40' alt='40' />
                      </Badge>
                    </IconButton>
                    <IconButton onClick={abrirCerrarModalCompartir} >
                      <img src={Compartir} width='40' alt='40' />
                    </IconButton>
                  </div>
                </div>
              </Typography>
              <Typography variant="subtitle1" paragraph>
                <font className='h1' size="5" color='#3A2872' > {post.Descriptioninfografias}</font>
              </Typography>
              <Typography variant="subtitle1" paragraph>
                <font className='titleProyect' color="#3A2872" size="5"> <strong>Palabras claves:</strong> </font>
                <font className='h1' size="5" color='#3A2872' >{post.palabrasclaves} </font>
              </Typography>
            </CardContent>
            <div />
          </div>
        </Grid>
      </Container>

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>¡Debes de iniciar sesión para descargar contenido!</Alert>
      </Snackbar>
    </div>
  );
}

export default FeaturedPost;
