import React, { useState } from "react";
import mPacífico from "./GeoJson/RegionP.json"
import mNorte from "./GeoJson/RegionN.json"
import mSur from "./GeoJson/RegionS.json"
import mAmazonico from "./GeoJson/RegionA.json"
import mOriente from "./GeoJson/RegionO.json"
import mCauca from "./GeoJson/RegionCA.json"
import mCentro from "./GeoJson/RegionC.json"
import mMacizo from "./GeoJson/RegionM.json"
import Media from '../Extras/Media.js'
import CloseIcon from '@mui/icons-material/Close';
import MediaInfo from '../Extras/Mediainfo'
import indice from '../../Imagenes/mapaiconos/gifs-html.gif'
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { Card, IconButton, Modal, } from "@mui/material";
import { SubRegionUrl, compUrls, PodcastUrl, infografiasUrl, infografias } from '../../Control/Autentication/urlsServer.js';
import axios from "axios";
import { useEffect } from "react";

const Pacífico = mPacífico;
const Sur = mSur;
const Macizo = mMacizo;
const Norte = mNorte;
const Amazonico = mAmazonico;
const Oriente = mOriente;
const Centro = mCentro;
const Cauca = mCauca;
const markers = [
  { markerOffset: -29, id: "Pacífico", name: "Pacífico", coordinates: [-74.05089567126196, -2.007755701653] },
  { markerOffset: -29, name: "Bota Caucana", id: "Bota Caucana", coordinates: [-64.95089567126196, -13, 111] },
  { markerOffset: -29, name: "Sur", id: "Sur", coordinates: [-70.05089567126196, -8.19990001653] },
  { markerOffset: -39, name: "Macizo", id: "Macizo", coordinates: [-66.70089567126196, -8, 1] },
  { markerOffset: -29, name: "Norte", id: "Norte", coordinates: [-64.9989567126196, -0.55701653] },
  { markerOffset: -29, name: "Centro", id: "Centro", coordinates: [-68.05089567126196, -3.007755701653] },
  { markerOffset: -29, name: "Oriente", id: "Oriente", coordinates: [-61.25000567126196, -3.600000701653] },
  { markerOffset: -29, name: "Departamento del Cauca", id: "Cauca", coordinates: [-69.05089567126196, -4.007755701653] },
];
const MapChart = (prop) => {
  const typeData = prop.type === '' ? 'Podcast' : prop.type;
  var optionData = prop.options === '' ? 'Cauca' : prop.options;
  const [audiosPod, setlistAudiosPod] = useState([]);
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const [showMedia, setShowMedia] = useState(false);

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
    if (typeData === 'Infografía') {
      Getinfofrafias()
    }
    else {
      GetPodcast()
    }
  }
  const GetMedia = () => {
    if (typeData === 'Infografía') {
      Getinfofrafias()
    }
    else {
      GetPodcast()
    }
  }

  const GetPodcast = async () => {
    setlistAudiosPod([]);
    if (prop.options !== 'Cauca' && prop.options !== '') {
      await axios.get(SubRegionUrl + prop.options, compUrls)
        .then(response => {
          setlistAudiosPod(response.data);
        });
    }
    else {
      GetPublications();
    }
  }

  const Getinfofrafias = async () => {
    setlistAudiosPod([]);
    if (prop.options !== 'Cauca' && prop.options !== '') {
      await axios.get(infografiasUrl + prop.options, compUrls)
        .then(response => {
          setlistAudiosPod(response.data);
        });
    }
    else {
      GetInfografiaAll();
    }
  }

  const GetInfografiaAll = async () => {
    await axios.get(infografias, compUrls)
      .then(response => {
        setlistAudiosPod(response.data);
      });
  }

  const GetPublications = async () => {
    await axios.get(PodcastUrl, compUrls)
      .then(response => {
        setlistAudiosPod(response.data);
      });
  }

  const mediaPlay = (
    <div style={{ width: '50%' }}>
      <Card sx={{
        borderRadius: 8,
        width: '40%',
        height: '95%',
        backgroundColor: "#fff",
        position: 'absolute',
        top: '3%',
        left: '30%'
      }}>
        <div style={{ background: '#4EBBB7', borderColor: '#4EBBB7' }}>
          <div align='center'>
            <br />
            <font style={{ fontFamily: 'Sofia Sans' }} size="6" color="#fff">{prop.type === "Podcast" ? 'Podcast' : 'Infografías'}</font>
          </div>
          <div align='end' style={{ marginTop: -50, }} >
            <IconButton onClick={() => { setShowMedia(!showMedia); setlistAudiosPod([]) }}>
              <CloseIcon sx={{ fontSize: 35, color: '#fff' }} />
            </IconButton>
          </div>
        </div>
        <div>
          {prop.type === "Podcast" ?
            <Media props={audiosPod} dataregion={prop.options} />
            :
            <MediaInfo props={audiosPod} />}
        </div>
      </Card>
    </div>
  )

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <>
      {optionData === 'Sur' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 440 : 430, marginLeft: widthResize > 1093 ? 245 : 190, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
        optionData === 'Pacífico' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 240 : 270, marginLeft: widthResize > 1093 ? 110 : 90, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
          optionData === 'Bota Caucana' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 600 : 560, marginLeft: widthResize > 1093 ? 405 : 325, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
            optionData === 'Macizo' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 435 : 428, marginLeft: widthResize > 1093 ? 355 : 280, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
              optionData === 'Norte' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 195 : 235, marginLeft: widthResize > 1093 ? 410 : 325, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
                optionData === 'Centro' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 270 : 295, marginLeft: widthResize > 1093 ? 310 : 245, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
                  optionData === 'Oriente' ? <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 290 : 315, marginLeft: widthResize > 1093 ? 530 : 420, position: 'absolute' }} src={indice} alt='img' width="50" height="50" /> :
                    <img onClick={() => { setShowMedia(!showMedia); GetMedia(); }} style={{ cursor: 'pointer', marginTop: widthResize > 1093 ? 300 : 310, marginLeft: widthResize > 1093 ? 460 : 405, position: 'absolute' }} src={indice} alt='img' width="50" height="50" />
      }
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [67.8, 7, 0],
          scale: optionData === '' || optionData === 'Cauca' ? 1500 : 2250
        }}
      >
        <Geographies geography={optionData === 'Sur' ? Sur : optionData === 'Pacífico' ? Pacífico
          : optionData === 'Bota Caucana' ? Amazonico : optionData === 'Macizo' ? Macizo
            : optionData === 'Norte' ? Norte : optionData === 'Centro' ? Centro
              : optionData === 'Oriente' ? Oriente : Cauca
        }>
          {({ geographies }) =>
            geographies.map((geo) =>
            (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={
                  geo.rsmKey === 'geo-1' ? "#fff" : geo.rsmKey === 'geo-0' ?
                    optionData === 'Pacífico' ? "#EA5D5D" :
                      optionData === 'Sur' ? "#3A2872" :
                        optionData === 'Centro' ? "#F7AC5B" :
                          optionData === 'Macizo' ? "#941B80" :
                            optionData === 'Bota Caucana' ? "#37c2da" :
                              optionData === 'Oriente' ? "#8f9393" :
                                optionData === 'Norte' ? "#AFC628"
                                  : '#4EBBB7'
                    : '#fff'}
                stroke={
                  optionData === 'Pacífico' ? "#EA5D5D" :
                    optionData === 'Sur' ? "#3A2872" :
                      optionData === 'Centro' ? "#F7AC5B" :
                        optionData === 'Macizo' ? "#941B80" :
                          optionData === 'Bota Caucana' ? "#37c2da" :
                            optionData === 'Oriente' ? "#8f9393" :
                              optionData === 'Norte' ? "#AFC628"
                                : "#0ee4d9"}
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates, markerOffset, id }) =>
        (optionData !== undefined ?
          id === optionData ?
            <Marker key={name} coordinates={coordinates} onClick={() => { hiddenMedia(); GetMedia(); }}>
              <circle style={{ cursor: 'pointer' }} r={19} fill="#fff" strokeWidth={5} onClick={() => { hiddenMedia(); GetMedia(); }} />
              <circle style={{ cursor: 'pointer' }} r={16} fill='#fff'
                stroke={
                  optionData === 'Pacífico' ? "#EA5D5D" :
                    optionData === 'Sur' ? "#3A2872" :
                      optionData === 'Centro' ? "#F7AC5B" :
                        optionData === 'Macizo' ? "#941B80" :
                          optionData === 'Bota Caucana' ? "#37c2da" :
                            optionData === 'Oriente' ? "#8f9393" :
                              optionData === 'Norte' ? "#AFC628" :
                                "#4EBBB7"}
                strokeWidth={5} />
              <text
                onClick={() => { hiddenMedia(); GetMedia(); }}
                textAnchor="middle"
                y={markerOffset}
                style={{ cursor: 'pointer', fontFamily: 'Sofia Sans', fontWeight: '690', fontSize: '20px', fill: "#fff" }}
              >
                {name}
              </text>
            </Marker>
            : "" : ""
        )
        )}
      </ComposableMap>

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>
    </>
  );
};

export default MapChart;
