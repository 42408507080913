import React, { useState } from 'react';
import { AppBar, Box, CssBaseline, IconButton, Tab, Toolbar, Typography } from '@mui/material'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Tabs } from '@mui/material'
import logo from '../../Imagenes/LogoHeader.png'
import InicioFalse from '../../Imagenes/menu/IconoinicioFalse.png';
import InicioTrue from '../../Imagenes/menu/IconoinicioTrue.png';
import { authentication } from '../Autentication/ManageFirebase';
import { baseUrlEntry, compUrls } from '../Autentication/urlsServer'
import './stylesHeader.css'
const cookies = new Cookies();
function Header(props) {
  const [auth, setAuth] = useState(cookies.get('emailVerified'))
  const [value, setValue] = useState(props.post !== undefined & props.post !== 'Inicio' ? props.post : false);
  const handleAuth = (usuario) => {
    cookies.set('user', usuario, { path: "/" });
    cookies.set('displayName', usuario.displayName, { path: "/" });
    cookies.set('email', usuario.email, { path: "/" });
    cookies.remove('idUser');
    peticionGet();
  }

  const peticionGet = async () => {
    if (cookies.get('email') !== "") {
      await axios.get(baseUrlEntry + cookies.get('email'), compUrls)
        .then(response => {
          if (response.status === 200) {
            var respuesta = response.data;
            cookies.set('idUser', respuesta.userId);
            cookies.set('emailVerified', true);
            setAuth(cookies.get('emailVerified'))
            window.location.href = "/Menu/Inicio"
          }
        })
        .catch(error => {
          if (error.response.status === undefined || error.response.status === 404) {
            window.location.href = "/Menu/Suscripcion"
            ErrorEntry();
          }
        })
    }
  }

  const ErrorEntry = () => {
    cookies.set('emailVerified', false);
    cookies.remove('user');
    cookies.remove('displayName');
    cookies.remove('idUser');
    setAuth(cookies.get('emailVerified'))
  }

  const closeEntry = () => {
    window.location.href = "/Menu/Inicio"
    cookies.set('emailVerified', false);
    cookies.remove('user');
    cookies.remove('displayName');
    cookies.remove('idUser');
    setAuth(cookies.get('emailVerified'))
  }


  return (
    <div style={{ background: '#4EBBB7' }}>
      <CssBaseline />
      <div>
        <img style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "/Menu/Inicio" }} className='imag' alt="inicio" src={logo} width={420} />
      </div>
      <Box sx={{ display: 'flex', background: '#4EBBB7' }}>
        <AppBar position="relative" sx={{ height: '90px', background: '#4EBBB7', borderStyle: 'solid', borderColor: '#4EBBB7' }} elevation={0} component="nav">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <IconButton sx={{ marginLeft: '-71%' }} href="/Menu/Inicio" >
                {props.post === 'Inicio' ?
                  <img style={{ cursor: 'pointer' }} src={InicioTrue} width={70} alt='Inicio' title='Inicio' /> :
                  <img style={{ cursor: 'pointer' }} src={InicioFalse} width={50} alt='Inicio' title='Inicio' />}
              </IconButton>
            </Typography>
            <Tabs
              value={value}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab title='Descubre todos los proyectos en CteI qué hay en cada una de las subregiones caucanas, a través de Podcast e infografías.' value="Mapa interativo" sx={{ cursor: 'pointer' }} href="/Menu/Mapa" onClick={() => { setValue("Mapa interativo") }} label={<font style={{ fontFamily: 'Sofia Sans' }} className={value === 'Mapa interativo' ? 'textSelectionHeader' : 'text'} >Mapa interactivo</font>} />
              <Tab title='Ponte en frecuencia con el conocimiento y escucha los Podcast de Ciencia&Esencia, en materia de CteI.' value="PodCats" sx={{ cursor: 'pointer' }} href="/Menu/Podcast" onClick={() => { setValue("PodCats") }} label={<font style={{ fontFamily: 'Sofia Sans' }} className={value === 'PodCats' ? 'textSelectionHeader' : 'text'} >Nuestro Canal</font>} />
              <Tab title='Encuentra información complementaria de los proyectos en CTeI 2016-2021 en el departamento del Cauca.' value="proyectCtel" sx={{ cursor: 'pointer' }} href="/Menu/CtelP" onClick={() => { setValue("proyectCtel") }} label={<font style={{ fontFamily: 'Sofia Sans' }} className={value === 'proyectCtel' ? 'textSelectionHeader' : 'text'} >+CTeI</font>} />
              <Tab title='Conoce datos curiosos, artículos de interés, jerga y/o términos en materia de CteI del departamento del Cauca.' value="Cauca+Ciencia" sx={{ cursor: 'pointer' }} href="/Menu/Blog" onClick={() => { setValue("Cauca+Ciencia") }} label={<font style={{ fontFamily: 'Sofia Sans' }} className={value === 'Cauca+Ciencia' ? 'textSelectionHeader' : 'text'}  >Infociencia</font>} />
              <Tab title='Accede a contenidos y material académico sobre Periodismo Científico.' value="PeriodismoCientífico" sx={{ cursor: 'pointer' }} href="/Menu/Material" onClick={() => { setValue("PeriodismoCientífico") }} label={<font style={{ fontFamily: 'Sofia Sans' }} className={value === 'PeriodismoCientífico' ? 'textSelectionHeader' : 'text'} >Entrénate</font>} />
              {auth === 'true' ?
                <Tab title='Cerrar tu perfil' value="Suscribirse" sx={{ cursor: 'pointer' }} onClick={closeEntry} label={<font style={{ fontFamily: 'Sofia Sans' }} className='text'>Cerrar Sesión</font>} />
                :
                <Tab title=' Descarga contenidos de forma ilimitada al registrarte.'  value="Suscribirse" sx={{ cursor: 'pointer' }} onClick={() => { authentication("Google", handleAuth); setValue("Suscribirse"); }} label={<font style={{ fontFamily: 'Sofia Sans' }} className='textRegister'>Regístrate</font>} />
              }
            </Tabs>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
export default Header;
