import React, { useState } from 'react';
import { Badge, Button, CardActions, Modal } from '@mui/material'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { IconButton } from '@mui/material';
import CompartirPublication from '../Extras/compartir.js'
import CompartirPublicationMovil from '../Extras/compartirMovil'
import Comments from '../Extras/Comments'
import CommentsMovil from '../Extras/CommentsMovil'
import ACompartir from '../../Imagenes/Iconos/amarillo/iconocompartir.png'
import AInterativo from '../../Imagenes/Iconos/amarillo/Iconosmegusta.png'
import AComenten from '../../Imagenes/Iconos/amarillo/Comenten.png'
import VCompartir from '../../Imagenes/Iconos/verde/iconocompartir.png'
import VInterativo from '../../Imagenes/Iconos/verde/Iconosmegusta.png'
import VComenten from '../../Imagenes/Iconos/verde/Comenten.png'
import AZCompartir from '../../Imagenes/Iconos/azul/iconocompartir.png'
import AZInterativo from '../../Imagenes/Iconos/azul/Iconosmegusta.png'
import AZComenten from '../../Imagenes/Iconos/azul/Comenten.png'
import RCompartir from '../../Imagenes/Iconos/rojo/iconocompartir.png'
import RInterativo from '../../Imagenes/Iconos/rojo/Iconosmegusta.png'
import RComenten from '../../Imagenes/Iconos/rojo/Comenten.png'
import AACompartir from '../../Imagenes/Iconos/azulAguamarina/iconocompartir.png'
import AAInterativo from '../../Imagenes/Iconos/azulAguamarina/Iconosmegusta.png'
import AAComenten from '../../Imagenes/Iconos/azulAguamarina/Comenten.png'
import mCompartir from '../../Imagenes/Iconos/morado/iconocompartir.png'
import mInterativo from '../../Imagenes/Iconos/morado/Iconosmegusta.png'
import mComenten from '../../Imagenes/Iconos/morado/Comenten.png'
import { ReactionsUrl, ReactionsPostUrl, compUrls, Base, imagenPublicationsUp } from '../../Control/Autentication/urlsServer';
import axios from 'axios';
import './stylesBlog.css'

function FeaturedPostInfo(props) {

  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const { post } = props;

  const CompartirImag = post.colorPublication === "#F7AC5B" ? ACompartir :
    post.colorPublication === '#941B80' ? mCompartir :
      post.colorPublication === '#3A2872' ? AZCompartir :
        post.colorPublication === '#EA5D5D' ? RCompartir :
          post.colorPublication === '#4CBAB7' ? AACompartir :
            VCompartir

  const InterativoImag =
      post.colorPublication === '#F7AC5B' ? AInterativo :
      post.colorPublication === '#941B80' ? mInterativo :
        post.colorPublication === '#3A2872' ? AZInterativo :
          post.colorPublication === '#EA5D5D' ? RInterativo :
            post.colorPublication === '#4CBAB7' ? AAInterativo :
              VInterativo

  const ComentsImag =
    post.colorPublication === '#F7AC5B' ? AComenten :
      post.colorPublication === '#941B80' ? mComenten :
        post.colorPublication === '#3A2872' ? AZComenten :
          post.colorPublication === '#EA5D5D' ? RComenten :
            post.colorPublication === '#4CBAB7' ? AAComenten :
              VComenten
  const [like, setLike] = useState(null);
  const [fileData, setfileData] = useState('');

  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }

  const [status, setstatus] = useState(false);

  const [modalvisualizerPodcats, setModalvisualizerPodcats] = useState(false);
  const abrirCerrarModalVisualizerPodcats = () => {
    setModalvisualizerPodcats(!modalvisualizerPodcats)
  }

  const [modalCompartir, setModalCompartir] = useState(false);
  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }


  const [modalComent, setModalComent] = useState(false);
  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const visualizerPodcats = (
    <div>
      <Card sx={{
        borderRadius: 8,
        width: '40%',
        height: '90%',
        backgroundColor: "#fff",
        position: 'absolute',
        top: '3%',
        left: '30%',
        borderStyle: 'solid',
        borderColor: post.colorPublication
      }}>
        <CardMedia
          height="35%"
          component="img"
          image={Base + fileData.PublicationsImagen}
          alt={post.titlePublication}
        />
        <CardActions>
          <div className='topMenuBlog'>
            <div>
              <font className='h3' width="100px" size="3" >{post.mesPublication} </font>
            </div>
            <div>
              <IconButton onClick={abrirCerrarModalComent}>
                <img src={ComentsImag} width='50' alt='Coments' />
              </IconButton>
              <IconButton onClick={abrirCerrarModalCompartir} >
                <img src={CompartirImag} width='75' alt='Compartir' />
              </IconButton>
            </div>
          </div>
        </CardActions>
        <CardContent >
          <Typography component="h2" variant="h5" fontFamily={'Sofia Sans'}>
            <font className='titleInitio' size="5" color={post.colorPublication}> {post.titlePublication} </font>
          </Typography>
          <Typography variant="subtitle1" fontFamily={'Sofia Sans'}>
            <font className='h3' size="2" >{post.cityRegister}</font>
          </Typography>
          <TextareaAutosize
            maxRows={9}
            className='textModal'
            defaultValue={post.DescriptionPublication}
            style={{
              width: widthResize > 750 ? '100%' : 390,
              maxWidth: widthResize > 750 ? '100%' : 390,
              minWidth: widthResize > 750 ? '100%' : 390,
              Height: '80%',
              maxHeight: '90%',
              minHeight: '5%',
            }}
            disabled
          />
          <div align='end'>
            <Button sx={{ fontFamily: 'Sofia Sans', textTransform: 'capitalize', margin: '2px' }}
              onClick={abrirCerrarModalVisualizerPodcats}>
              <font className='titleInitio' size="4" color={post.colorPublication}> Cerrar</font>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )

  const bodyComent = (
    <div>
      {widthResize > 750 ?
        <div>
          <Comments key={post.id} post={post} />
        </div> :
        <div style={{
          height: 700,
          position: 'absolute',
          bottom: '10%',
          right: '100%',
          borderStyle: 'solid',
        }}>
          <CommentsMovil key={post.id} post={post} />
        </div>}
    </div>
  )




  const bodyCompartir = (
    <div>
      {widthResize > 750 ?
        <div>
          <CompartirPublication key={post.id} post={post} />
        </div> :
        <div >
          <CompartirPublicationMovil key={post.id} post={post} />
        </div>}
    </div>)

  const GetRectionsinition = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }


  const GetRections = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  if (fileData === '') {
    GetImagenPublications();
  }

  React.useEffect(() => {
    GetRectionsinition();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  });



  return (
    <div className='textCont'>
      <Grid marginBottom={5} marginRight={4.3}>
        <Card sx={{
          borderRadius: 8, minWidth: 345,
          maxWidth: 355,
          height: 500,
          maxHeight: 500,
          borderStyle: 'solid',
          borderColor: post.colorPublication
        }}>
          {fileData.PublicationsImagen !== undefined ?
            widthResize > 750 ?
              <CardMedia
                height={190}
                sx={{ cursor: 'pointer' }}
                onClick={abrirCerrarModalVisualizerPodcats}
                component="img"
                title={post.titlePublication}
                image={Base + fileData.PublicationsImagen}
                alt={post.titlePublication}
              /> :
              <CardMedia
                height={190}
                sx={{ cursor: 'pointer' }}
                component="img"
                title={post.titlePublication}
                image={Base + fileData.PublicationsImagen}
                alt={post.titlePublication}
              />
            : ''
          }
          <CardActions>
            <div className='topMenuBlog'>
              <div>
                <font className='h3' width={100} size="3" > {post.mesPublication} </font>
              </div>
              <div>
                <IconButton sx={{width:65}} onClick={abrirCerrarModalComent}>
                  <img src={ComentsImag} width={44} alt='Comentar' />
                </IconButton>
                <IconButton sx={{width:65}} onClick={abrirCerrarModalCompartir} >
                  <img src={CompartirImag} width={60} alt='Compartir' />
                </IconButton>
                <IconButton disabled={status} sx={{width:65}} onClick={GetRections}  >
                  <Badge badgeContent={like} color='error'>
                    <img src={InterativoImag} width={60} alt='Interacion' />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </CardActions>
          <br/>
          <CardActions>
            <div className='topMenuBlog'>

              {widthResize > 750 ? <Button sx={{ fontFamily: 'Sofia Sans', textTransform: 'capitalize' }}
                onClick={abrirCerrarModalVisualizerPodcats}>
                <font className='titleInitio' style={{ textTransform: 'none' }} size="5" color={post.colorPublication}> {post.titlePublication} </font>
              </Button> : <Button sx={{ fontFamily: 'Sofia Sans', textTransform: 'capitalize' }}>
                <font className='titleInitio' style={{ textTransform: 'none' }} size="5" color={post.colorPublication}> {post.titlePublication} </font>
              </Button>}
            </div>
          </CardActions>
          <CardContent >
            <Typography variant="subtitle1" fontFamily={'Sofia Sans'}>
              <font className='h3' size="2" >{post.cityRegister}</font>
            </Typography><div height='1px'>
              {widthResize > 750 ?
                <TextareaAutosize
                  maxRows={6}
                  className='textModal2'
                  defaultValue={post.DescriptionPublication}
                  style={{
                    width: 310, maxWidth: 310, minWidth: 310,
                    height: 155, maxHeight: 155, minHeight: 155
                  }}
                  disabled
                /> :
                <TextareaAutosize
                  maxRows={9}
                  className='textModal'
                  defaultValue={post.DescriptionPublication}
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    minWidth: '100%',
                    Height: widthResize > 750 ? '80%' : '50%',
                    maxHeight: widthResize > 750 ? '90%' : '50%',
                  }}
                  disabled
                />}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Modal
        open={modalvisualizerPodcats}
        onClose={abrirCerrarModalVisualizerPodcats}>
        {visualizerPodcats}
      </Modal>

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>
    </div>
  );
}
export default FeaturedPostInfo;
