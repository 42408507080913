import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Badge, CardMedia, IconButton, Modal } from '@mui/material';
import Musik from '../../Imagenes/Spotify2.png';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Comments from '../Extras/CommentsMovil'
import CompartirPublication from '../Extras/compartirMovil'
import { ReactionsUrl, ReactionsPostUrl, compUrls } from '../../Control/Autentication/urlsServer';
import './stylesPodcats.css'
import { useState, useEffect } from 'react';
import axios from 'axios';

function FeaturedPost(props) {
  const { post } = props;
  const [showMedia, setShowMedia] = useState(false);
  const [like, setLike] = useState(null);

  const [status, setstatus] = useState(false);

  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }

  const [modalComent, setModalComent] = useState(false);
  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const [modalCompartir, setModalCompartir] = useState(false);
  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const GetRectionsinition = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction + 1)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const mediaPlay = (
    <div style={{
      width: 800,
      height: 500,
      position: 'absolute',
      bottom: '25%',
      right: '25%',
      borderStyle: 'solid',
      borderColor: '#fff',
      backgroundColor: "#fff",
    }}>
      <CardMedia
        component='iframe'
        height="500"
        image={'https://www.youtube.com/embed/' + post.codeVideoPublication}
        alt={post.titlePublication}
      />
    </div>)

  const bodyComent = (<div style={{
    height: 700,
    position: 'absolute',
    bottom: '15%',
    right: '100%',
    borderStyle: 'solid',
  }}>
    <Comments key={post.id} post={post} />
  </div>
  )


  const bodyCompartir = (
    <div>
      <CompartirPublication key={post.id} post={post} />
    </div>)

  useEffect(() => {
    GetRectionsinition();
  });

  return (
    <div >
      <Typography variant="subtitle1" >
        <font style={{ fontFamily: 'Sofia Sans' }} className='h1' size="4" color="#c7c7c9">Publicación: {post.datePublication}</font>
      </Typography>
      <div>
        <font style={{ fontFamily: 'Sofia Sans', fontWeight: 600 }} color="#4EBBB7" size="5"  >{post.titlePublication} | </font>  <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#c7c7c9"> {post.durationPublication}min</font>
      </div>
      <CardMedia
        component='iframe'
        height={190}
        sx={{ minWidth: 340, maxWidth: 340, borderRadius: 3 }}
        image={'https://www.youtube.com/embed/' + post.codeVideoPublication}
        alt={post.titlePublication}
      />
      <div style={{ marginLeft: '15%' }}>
        <IconButton onClick={abrirCerrarModalComent}>
          <img src={coment} width='40' alt='40' />
        </IconButton>
        <IconButton onClick={abrirCerrarModalCompartir}>
          <img src={Compartir} width='40' alt='40' />
        </IconButton>
        <IconButton target='_blank' href={post.urlAudioPublication}>
          <img src={Musik} width='40' alt='40' />
        </IconButton>
        <IconButton disabled={status} onClick={GetRections} >
          <Badge badgeContent={like} color='error'>
            <img src={Interativo} width='40' alt='40' />
          </Badge>
        </IconButton>
      </div>
      <Typography variant="subtitle1" paragraph>
        <font style={{ fontFamily: 'Sofia Sans' }} className='h1' size="4" color='#23088E' > {post.DescriptionPublication}</font>
      </Typography>
      <div />

      <br />

      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {mediaPlay}
      </Modal>

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>


      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>
    </div>
  );
}

export default FeaturedPost;
