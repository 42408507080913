import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button, Card, CardContent } from '@mui/material';
import PodCastInt from './PodCastInitions.js';
import PodCastIntMovil from './PodCastInitionMovil.js';
// import notfound from '../../Imagenes/404-error.png'
import Header from '../../Control/Header-Footer/Header.js'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import Foot from '../../Control/Header-Footer/foot.js'
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import PlayProgress from '../Extras/Banner.js'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Informativo from './Informativo.js'
import InformativoMovil from './InformativoMovil.js'
import './stylesInicio.css'
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import { podcastTop, compUrls, ReactionsUrl, imagenPublicationsUp } from '../../Control/Autentication/urlsServer.js';

export default function Index() {

  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [fileData, setfileData] = useState([]);
  const [like, setLike] = useState([]);
  const [listaid, setListaid] = useState('');
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const [featuredPosts, setFeaturedPosts] = useState([])

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#1a928c',
    color: '#fff',
    fontStyle: 'normal',
    fontFamily: 'Sofia Sans'
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#00A3AD',
      },
    },
  });


  const GetPublications = async () => {
    var dataid = []
    await axios.get(podcastTop, compUrls)
      .then(response => {
        setFeaturedPosts(response.data);
        response.data.map((data) => dataid.push(data.id))
      });
    setListaid(dataid)
  }

  const GetImagenPublications = () => {
    var dataimagenes = []
    listaid.forEach(id => {
      axios.get(imagenPublicationsUp + id, {
        headers: {
          'content-type': 'multipart/form-data',
          'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
        }
      })
        .then(response => {
          dataimagenes.push(response.data)
          if (dataimagenes.length === 3 && fileData.length === 0) {
            setfileData(dataimagenes)
          }
        })
    })
  }

  const GetRectionsinition = () => {
    var resactionsList = []
    listaid.forEach(id => {
      axios.get(ReactionsUrl + id, compUrls)
        .then(response => {
          resactionsList.push(response.data)
          if (resactionsList.length === 3 && fileData.length === 0) {
            setLike(resactionsList)
          }
        })
    })
  }

  if (listaid !== '' && fileData.length === 0) {
    GetImagenPublications();
    GetRectionsinition();
  }

  useEffect(() => {
    GetPublications();
    window.addEventListener('resize', resize);
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <CssBaseline />
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Inicio"} /> : <HeaderMovil />}
      </div>
      <div>{  widthResize > 750 ?"":<br />}
        <PlayProgress />
        <br />
        <Container >
          {
            featuredPosts.length !== 0 && like.length !== 0 && fileData.length !== 0 ?
              widthResize > 750 ?
                <Grid container spacing={1}>
                  {featuredPosts.map((post, i) => (
                    post.disablePublication === true ?
                      like.map((likesd) => (
                        post.id === likesd.publicationRelations ?
                          fileData.map((fileDataa) => (
                            post.id === fileDataa.publication ?
                              <PodCastInt key={post.id} imgen={fileDataa} likes={likesd} post={post} index={i} />
                              : ""
                          ))
                          : ""
                      ))
                      : ""
                  ))
                  }
                </Grid>
                :
                <PodCastIntMovil imgen={fileData} likes={like} post={featuredPosts} />
              :
              ""
          }
        </Container>
        <br /><br />
        <Card>
          <CardContent sx={{ flex: 1, backgroundColor: "#4EBBB7" }}>
            <br />
            <Container>
              <div align="center">
                <div style={{ marginBottom: '2%' }}>
                  <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#fff"> <strong> ¿Qué es Ciencia&Esencia? </strong> </font><br />
                </div>
                <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#fff">Es un proyecto de comunicación de la Ciencia, Tecnología e Innovación que busca a través del formato podcast divulgar las diferentes prácticas que se desarrollan alrededor de la ciencia en las 7 subregiones del Departamento del Cauca. </font><br />
                <br /><br />
              </div>
            </Container>
          </CardContent>
        </Card>
        {widthResize > 750 ? <Informativo /> : <div align="center"> <InformativoMovil /> </div>}
        <Card sx={{ maxWidth: '100%' }}>
          <CardContent sx={{ maxWidth: '100%', flex: 1, backgroundColor: "#4EBBB7" }}>
            <br />
            <Container>
              <div align="center">
                <div style={{ marginBottom: '2%' }}>
                  <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#fff"> <strong> ¿Conoces o haces parte de procesos y/o proyectos sobre Ciencia, Tecnología e Innovación en el Cauca? </strong> </font><br />
                </div>
                <font style={{ fontFamily: 'Sofia Sans' }} className='textInformativo' size="5" color="#fff">Cuéntanos sobre tu proyecto y en nuestro portal web de Ciencia&Esencia publicaremos contenido para que más personas conozcan de él y su impacto en la comunidad.</font><br /><br />
                <ThemeProvider theme={customTheme}>
                  <BootstrapButton href='/Menu/ProyectsCtel' variant="contained" >
                    Quiero participar
                  </BootstrapButton>
                </ThemeProvider>
                <br /><br />
              </div>
            </Container>
          </CardContent>
        </Card>
        {widthResize > 750 ? <Foot /> : <FootMovil />}
      </div>
    </div>
  );
}