import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import '../MenuInfociencia/stylesBlog.css'
import { Card, Container, IconButton } from '@mui/material';
export default function compartirPublication(props) {
  const { post } = props;
  console.log(post);
  return (

    <Card sx={{
      borderRadius: 8,
      backgroundColor: "#fff",
      position: 'absolute',
      bottom: '45%',
      right: '35%'
    }}>
      <Container>
        <div >
          <div>
            <font style={{ fontFamily: 'Sofia Sans', fontWeight: 600 }} color="#4EBBB7" size="5"  >Redes sociales </font>
          </div>
          <div>
            {
              post.TypePublication === "datosCuriosos" ? <IconButton target='_blank' href="https://www.facebook.com/sharer.php?u=https://cienciayesencia-cauca.com/Menu/Blog">
                <FacebookIcon />
              </IconButton> :
                post.TypePublication === 'Podcast' ? <IconButton target='_blank' href="https://www.facebook.com/sharer.php?u=https://cienciayesencia-cauca.com/Menu/Podcast">
                  <FacebookIcon />
                </IconButton> :
                  post.TypePublication === 'infografia' ? <IconButton target='_blank' href="https://www.facebook.com/sharer.php?u=https://cienciayesencia-cauca.com/Menu/CtelP">
                    <FacebookIcon />
                  </IconButton> :
                    <IconButton target='_blank' href="https://www.facebook.com/sharer.php?u=https://cienciayesencia-cauca.com/Menu/Material">
                      <FacebookIcon />
                    </IconButton>
            }
            {
              post.TypePublication === "datosCuriosos" ? <IconButton target='_blank' href="https://www.linkedin.com/shareArticle?url=https://cienciayesencia-cauca.com/Menu/Blog">
                <LinkedInIcon />
              </IconButton> :
                post.TypePublication === 'Podcast' ? <IconButton target='_blank' href="https://www.linkedin.com/shareArticle?url=https://cienciayesencia-cauca.com/Menu/Podcast">
                  <LinkedInIcon />
                </IconButton> :
                  post.TypePublication === 'infografia' ? <IconButton target='_blank' href="https://www.linkedin.com/shareArticle?url=https://cienciayesencia-cauca.com/Menu/CtelP">
                    <LinkedInIcon />
                  </IconButton> :
                    <IconButton target='_blank' href="https://www.linkedin.com/shareArticle?url=https://cienciayesencia-cauca.com/Menu/Material">
                      <LinkedInIcon />
                    </IconButton>
            }
            {
              post.TypePublication === "datosCuriosos" ? <IconButton target='_blank' href="https://www.twitter.com/intent/tweet?text=Ciencia&Esencia&url=https://cienciayesencia-cauca.com/Menu/Blog">
                <TwitterIcon />
              </IconButton> :
                post.TypePublication === 'Podcast' ? <IconButton target='_blank' href="https://www.twitter.com/intent/tweet?text=Ciencia&Esencia&url=https://cienciayesencia-cauca.com/Menu/Podcast">
                  <TwitterIcon />
                </IconButton> :
                  post.TypePublication === 'infografia' ? <IconButton target='_blank' href="https://www.twitter.com/intent/tweet?text=Ciencia&Esencia&url=https://cienciayesencia-cauca.com/Menu/CtelP">
                    <TwitterIcon />
                  </IconButton>
                    :
                    <IconButton target='_blank' href="https://www.twitter.com/intent/tweet?text=Ciencia&Esencia&url=https://cienciayesencia-cauca.com/Menu/Material">
                      <TwitterIcon />
                    </IconButton>
            }
          </div>
        </div>
      </Container>
    </Card>
  );
}

