
import * as React from 'react';
import { Button, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import './stylesProyecto.css'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Ctel() {
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    lineHeight: 1.5,
    borderRadius: '15px',
    backgroundColor: '#00A3AD',
    color: '#fff',
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Inicio"} /> : <HeaderMovil />}
      </div>
      <Container color='#fff'>
        <br /><br /><br />
        <font className="title" size="7" color="#4EBBB7">¡Quiero dar a conocer mi proyecto con Ciencia&Esencia!</font><br />
        <font size="5" color="#3A2872">En el portal web de Ciencia&Esencia, publicaremos contenido de los proyectos de Ciencia, Tecnología e Innovación que quieran registrarse y darse a conocer a través de nuestro portal.</font><br /><br />
        <font size="5" color="#3A2872">Con la información que nos proporcionen, podremos crear infografías del proyecto, publicar artículos de interés o datos relevantes en materia de CTeI del departamento del Cauca. </font><br /><br />
        <br /><br />

        <div className='topMenuP'>
          <div>
            <font className='title2' size="5" color="#3A2872">¿Cómo participar en proyectos CTeI? </font>
            <font size="5" color="#3A2872">Te contamos en 40 segundos algunos tips para presentar tu proyecto en las convocatorias de Ciencia, Tecnología e Innovación.</font><br /><br />
          </div>
        </div><br />
        {widthResize > 750 ?
          <div align='center'>
          <iframe  title="Diplomado" width="70%" height="455" src={'https://www.youtube.com/embed/M-s2HXgj6ko'}></iframe> </div>: <div>
            <iframe title="Diplomado" width="100%" height="255" src={'https://www.youtube.com/embed/M-s2HXgj6ko'}></iframe>

          </div>
        }
        <br /><br />
      </Container>
      <Container color='#fff'>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ExpandMoreIcon sx={{ color: '#4441cf' }} />
            <font className='title2' size="5" color="#3A2872">Formulario para participar de Ciencia&Esencia</font>
          </AccordionSummary>
          <AccordionDetails>
            <Container >

              <ThemeProvider theme={customTheme}>
                <BootstrapButton href='/Menu/ProyectsParticipacion' variant="contained" >
                  Quiero participar
                </BootstrapButton>
              </ThemeProvider>
              <br /><br /><br /><br />
            </Container>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ExpandMoreIcon sx={{ color: '#4441cf' }} />
            <font className='title2' size="5" color="#3A2872">Preguntas frecuentes</font>
          </AccordionSummary>
          <AccordionDetails>
            <Container >
              <font className='title2' size="5" color="4EBBB7">¿Cuáles son los requisitos mínimos que debo de cumplir para poder participar?</font><br />
              <font className='h5' size="4.8" color="#3A2872">1) El proyecto no debe de ser inferior al año 2016 y no superar el año 2022</font><br />
              <font className='h5' size="4.8" color="#3A2872">2) Debe de haberse ejecutado en su totalidad y contar con evidencias del proceso.</font><br />
              <font className='h5' size="4.8" color="#3A2872">3) Presentar evidencias o métricas de impacto en la región en donde fue ejecutado.</font>

              <br /><br /><br />
              <font className='title2' size="5" color="4EBBB7">¿Debo de pagar algo para dar a conocer mi proyecto?</font>
              <font className='h5' size="4.8" color="#3A2872">No, el proceso de contacto con el equipo de Ciencia&Esencia es totalmente gratuito. No se necesitan intermediarios para realizar esta gestión. Recepción de documentos únicamente mediante el portal web oficial de Ciencia&Esencia.</font>
              <br /><br /><br />
              <font className='title2' size="5" color="4EBBB7">¿Debo tener algo ya elaborado para que mi experiencia sea compartida?</font>
              <font className='h5' size="4.8" color="#3A2872">Para poder realizar el material de divulgación dentro de la plataforma Web de Ciencia&Esencia, es necesario contar con información del proyecto como:</font>
              <Container>
                <font className='h5' size="4.8" color="#3A2872"> - Información de contexto del proyecto (año de inicio, año de finalización, objetivos y resultados esperados).</font><br />
                <font className='h5' size="4.8" color="#3A2872"> - Actores ejecutores principales.</font><br />
                <font className='h5' size="4.8" color="#3A2872"> - Actores beneficiarios.</font><br />
                <font className='h5' size="4.8" color="#3A2872"> - Actores aliados.</font><br />
                <font className='h5' size="4.8" color="#3A2872"> - Evidencias de impacto.</font><br />
                <font className='h5' size="4.8" color="#3A2872"> - Resultados finales.</font><br />
              </Container>
              <br />
              <font className='h5' size="4.8" color="#3A2872">Esta información puede ser suministrada mediante documentos formales del proyecto o un documento creado por el interesado para esta postulación.</font><br />
              <br /><br /><br /><br />
            </Container>
          </AccordionDetails>
        </Accordion>
        <br /><br /><br />
      </Container>
      <Foot />
    </div>
  );
}