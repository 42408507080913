import React, { useState } from 'react';
import { Badge, Card, CardContent, CardMedia, Container, Grid, IconButton, Typography } from '@mui/material';
import Musik from '../../Imagenes/Spotify.png'
import Interativo from '../../Imagenes/interacion.png'
import Youtube from '../../Imagenes/Youtube.png'
import { Base, imagenPublicationsUp, } from '../../Control/Autentication/urlsServer';
import { Subregion } from '../Extras/Referencias.js'
import './stylesCms.css'
import axios from 'axios';


export default function Visualizador(data) {
  var selection = data.row.rowId.row

  const consolaSeleccionada = selection
  
  const [fileData, setfileData] = useState('');
  
  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + consolaSeleccionada.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => { 
        setfileData(response.data);
      })
  }


  const podcast = (

    <Grid item md={4}>
      <Card sx={{
        borderRadius: 8, maxHeight: 620, minHeight: 620,
        minWidth: 290, maxWidth: 355,
        backgroundColor: "#941B80"
      }
      }>
        <CardMedia
          height={260}
          sx={{ marginTop: -3 }}
          component="img"
          image={Base + fileData.PublicationsImagen}
          alt={consolaSeleccionada.titlePublication}
        />
        <CardContent>
          <Typography variant="subtitle1" fontStyle='normal' >
            <div className='topMenu1'>
              <div>
                <font className='textInformativo' width="10%" size="3" color="#fff"> {consolaSeleccionada.datePublication} </font>
              </div>
              <div>
                <IconButton align='center' >
                  <img src={Musik} width='40' alt='40' />
                </IconButton>
                <IconButton >
                  <Badge color='error'>
                    <img src={Interativo} width='40' alt='40' />
                  </Badge>
                </IconButton>
                <IconButton>
                  <img src={Youtube} width='40' alt='40' />
                </IconButton>
              </div>
            </div>
          </Typography>
          <Typography component="h2" variant="h5" fontStyle='normal' >
            <font className='titleInitio' size="5" color="#fff"> {consolaSeleccionada.titlePublication} </font>
          </Typography>
          <Typography variant="subtitle1" fontStyle='normal'>
            <font className='textInformativo' size="3" color="#fff">{consolaSeleccionada.durationPublication} min </font>
          </Typography>
          <font className='textInformativo' size="4" color="#fff">{consolaSeleccionada.DescriptionPublication}</font>
        </CardContent>
      </Card>
    </Grid>
  )

  if (fileData === '') {
    GetImagenPublications();
  }

  return (

    <div style={{ fontFamily: 'Sofia Sans' }}>
      <div style={{ display: 'flex' }}>
        <Container>
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Titulo </font><br />
              <input className='inputs' type='text' variant="standard" name="titlePublication" required label='Titulo' value={consolaSeleccionada && consolaSeleccionada.titlePublication} />
            </div>
            <div>

              <div>
                <font position='start' size="4" color="#3A2872"> SubRegion</font><br />
                <select
                  id='SubRegionRegister'
                  className='inputs'
                  disabled
                  name='SubRegionRegister'
                  defaultValue={consolaSeleccionada.titlePublication}
                  style={{ width: '165%', fontFamily: 'Sofia Sans' }}

                >
                  {Subregion.map((options, i) => (
                    <option
                      disabled={options === 'Cauca' ? true : false}
                      key={i} value={options}>
                      {options}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Duracion </font><br />
              <input className='inputs' type='text' variant="standard" name="durationPublication" required label='Duracion' value={consolaSeleccionada && consolaSeleccionada.durationPublication} />
            </div>
            <div>
              <font position='start' size="4" color="#3A2872"> Tipo de publicacion</font><br />
              <input disabled className='inputs' type='text' variant="standard" name="TypePublication" required label='Tipo de publicacion' value={consolaSeleccionada && consolaSeleccionada.TypePublication} />
            </div>
          </div>
          <br />
          <div >
            <font size="4" color="#3A2872"> Descripción del proyecto:</font><br />
            <textarea style={{ height: '152px', width: '70%', maxHeight: '152px', maxWidth: '70%' }} value={consolaSeleccionada && consolaSeleccionada.DescriptionPublication} type='text' variant="standard" name="DescriptionPublication" required className="inputAreatext" label='Descripción del proyecto' />
            <br />
          </div>
          <div>
            <font position='start' size="4" color="#3A2872"> Url de audio(Spotify)</font><br />
            <input className='inputs' style={{ width: '70%' }} type='text' variant="standard" name="urlAudioPublication" required label='Url de audio(Spotify)' value={consolaSeleccionada && consolaSeleccionada.urlAudioPublication} />
          </div>
          <br />

          <div style={{ display: 'flex' }}>
            <div>
              <font position='start' size="4" color="#3A2872"> Codigo de video (Youtube)</font><br />
              <input className='inputs' type='text' variant="standard" name="codeVideoPublication" required label='Codigo de video (Youtube)' value={consolaSeleccionada && consolaSeleccionada.codeVideoPublication} />
            </div>
          </div>

          <br />
        </Container>
        <Container >
          <div style={{ marginTop: '-5%' }}>
            {podcast}
          </div>
          <br />
        </Container>
      </div>
    </div>
  );
}
