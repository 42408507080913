import React, { useState } from 'react';
import { Container, Box, Modal, Card, IconButton, CssBaseline } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot.js'
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import MapaMovil from './MapChartMovil.js';
import Mapa from './MapChart.js';
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import MediaInfo from './InformativoMovil.js'

import { Base, InfografiaMapaUp, compUrls } from '../../Control/Autentication/urlsServer';
import './stylesMapa.css'
import { useEffect } from 'react';
import axios from 'axios';

const Metadata = [
  {
    value: 'Infografía',
    label: 'Infografía',
  },
  {
    value: 'Podcast',
    label: 'Podcast',
  }
];
const Regiones = [
  {
    value: 'Pacífico',
    label: 'Pacífico',
  },
  {
    value: 'Centro',
    label: 'Centro',
  }, {
    value: 'Norte',
    label: 'Norte',
  },
  {
    value: 'Oriente',
    label: 'Oriente',
  }, {
    value: 'Macizo',
    label: 'Macizo',
  },
  {
    value: 'Sur',
    label: 'Sur',
  },
  {
    value: 'Bota Caucana',
    label: 'Bota Caucana',
  },
  {
    value: 'Cauca',
    label: 'Cauca',
  }
];

export default function MapaI() {
  const [dataRegion, setRegion] = useState('Cauca');
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [fileData, setfileData] = useState('');  
  const [showMedia, setShowMedia] = useState(true);

  const resize = () => {
    setWidthResize(window.innerWidth)
  }
  const handleChange = () => {
    setRegion(document.getElementById("listRegion").value);
  }

  const [dataType, setType] = useState('Podcast');
  const handleChangeType = () => {
    setType(document.getElementById("listtype").value);
  }

  const GetImagenPublications = async () => {
    await axios.get(InfografiaMapaUp + dataRegion, compUrls)
      .then(response => {
        setfileData(response.data);
      })
  }
  const hiddenMedia = () => {
    setShowMedia(!showMedia);
  }
  
  const mediaPlay = (
    <div style={{ width: '50%' }}>
      <Card sx={{
        borderRadius: 8,
        width: '40%',
        height: '90%',
        backgroundColor: "#fff",
        position: 'absolute',
        top: '3%',
        left: '30%'
      }}> 
      <div  align='end' style={{height:'10%',alignItems:'center'}}>
      <IconButton sx={{marginRight:'3%'}} onClick={() => { setShowMedia(!showMedia); }}>
        Omitir
      </IconButton>
    </div>
        <div style={{marginTop:'-17%'}}>
            <MediaInfo />
        </div>
      </Card>
    </div>
  )
  
  const mediaPlayMovil = (
    <div style={{ width: '80%' }}>
      <Card sx={{
        borderRadius: 8,
        width: '85%',
        height: '55%',
        backgroundColor: "#fff",
        position: 'absolute',
        top: '3%',
        left: '8%'
      }}> 
      <div align='end'>
      <IconButton sx={{marginTop:'3%',marginRight:'3%'}} onClick={() => { setShowMedia(!showMedia); }}>
        Omitir
      </IconButton>
    </div>
        <div style={{marginTop:'-10%'}}>
            <MediaInfo />
        </div>
      </Card>
    </div>
  )

  if (dataRegion !== 'Cauca' && fileData.Subregion !== dataRegion) {
    GetImagenPublications();
  }

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);

  return (
    <div>
      <CssBaseline/>
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Mapa interativo"} /> : <HeaderMovil />}
      </div>
      <Container >
        <br /><br />
        <div align="start">
          <font style={{ fontFamily: 'Sofia Sans' }} className="title" size="10"> Mapa interactivo</font>
          <font style={{ fontFamily: 'Sofia Sans', fontWeight: '600' }} color='#4EBBB7' size="6" > ¿Qué puedes encontrar aquí?</font>
          <br /> <br />
          <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E">En este mapa puedes encontrar material interactivo como Podcast, infografías y/o fotografías de cada una de las subregiones del Cauca. </font><br />
          <font style={{ fontFamily: 'Sofia Sans' }} size="5" color="#23088E">Selecciona el formato que sea de interés y elige la subregión de donde quieres que sea dicho contenido.</font>
        </div><br />
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div className='topMenuInputs'>
            <div>
              <font style={{ fontFamily: 'Sofia Sans' }} className="inputLabel" size="4">Tipo de contenido</font>
              <select
                id='listtype'
                style={{ marginTop: widthResize > 750 ? '' : '7px', fontFamily: 'Sofia Sans', width: widthResize > 750 ? '190px' : '150px' }}
                className='selecteList'
                defaultValue="Podcast"
                onChange={handleChangeType}
              >
                {Metadata.map((options) => (
                  <option
                    key={options.value} value={options.value}>
                    {options.label}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ color: '#fff' }}>.</div>
            <div style={{ marginLeft: widthResize > 750 ? '10px' : '10px' }}>
              <font style={{ fontFamily: 'Sofia Sans' }} className="inputLabel" size="4">Subregión</font>

              <select
                style={{ marginTop: widthResize > 750 ? '' : '7px', fontFamily: 'Sofia Sans', width: widthResize > 750 ? '190px' : '150px' }}
                className='selecteList'
                id='listRegion'
                defaultValue="Cauca"
                onChange={handleChange}
              >
                {
                  Regiones.map((options) => (
                    <option key={options.value} value={options.value}>
                      {options.label}
                    </option >
                  ))}
              </select >
            </div>
          </div>
        </Box>
        {widthResize > 750 ?
          <Container sx={{ marginLeft: '-5%', display: 'flex', maxWidth: '100%', width: '100%', height: '810px' }}>
            <Mapa options={dataRegion} type={dataType} />
            <p style={{ color: '#fff' }}>_______</p>
            {dataRegion !== 'Cauca' && fileData.imagen !== undefined ? <img src={Base + fileData.imagen} style={{ marginRight: '-10%', marginTop: '7%' }} width='50%' height='80%' alt='Pesca' /> : ""}
          </Container>
          :
          <div>
            <MapaMovil options={dataRegion} type={dataType} />
            {dataRegion !== 'Cauca' && fileData.imagen !== undefined ? <img src={Base + fileData.imagenResponsi} style={{ marginTop: '-30%' }} width='100%'  alt='Pesca' /> : ""}
          </div>
        }
        <br />
      </Container>
      {widthResize > 750 ? <Foot /> : <FootMovil />}
      
      <Modal
        open={showMedia}
        onClose={hiddenMedia}>
        {widthResize > 750 ?mediaPlay:mediaPlayMovil}
      </Modal>
    </div>
    
  );
}