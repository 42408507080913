import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Badge, IconButton } from '@mui/material';
import Interativo from '../../Imagenes/interacion2.png';
import { ReactionsUrl, ReactionsPostUrl, compUrls, Base, imagenPublicationsUp } from '../../Control/Autentication/urlsServer';
import '../MenuMapa/stylesMapa.css'
import Youtube from '../../Imagenes/youtube2.svg'
import axios from 'axios';

function ListAudios(props) {
  const { post } = props;
  const [status, setstatus] = React.useState(false);
  const [like, setLike] = React.useState(null);
  const [fileData, setfileData] = React.useState('');

  const GetImagenPublications = async () => {
    await axios.get(imagenPublicationsUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }

  const [statusAudio, setStatusAudio] = React.useState(false)
  const playAudio = () => {
    setStatusAudio(!statusAudio)
  }

  const GetRectionsinition = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  if (fileData === '') {
    GetImagenPublications();
  }

  React.useEffect(() => {
    GetRectionsinition();
  });

  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>
      <br />
      {statusAudio === true ?

        <iframe
          title={post.titlePublication}
          width={0}
          height={0}
          allow='autoplay'
          src={'https://www.youtube.com/embed/' + post.codeVideoPublication + '?autoplay=1'}
          alt={post.titlePublication}
        />
        :
        ""
      }

      <div style={{ marginBottom: '-5%' }}>
        <font style={{ fontFamily: 'Sofia Sans' }} size="2" > {post.datePublication}</font>
      </div>
      <div style={{ display: 'flex', width: '110%', maxWidth: '110%' }}>
        <div style={{ height: '5%', maxHeight: '5%' }}><br />
          {fileData.PublicationsImagen !== undefined ?
            <img height='160px' src={Base + fileData.PublicationsImagen} style={{ borderRadius: '10px', fontFamily: 'Sofia Sans' }} width='160px' alt={post.titlePublication} />
            : ""}
        </div>
        <div >
          <img src={Youtube} style={{
            cursor: 'pointer', marginLeft: '-220%', marginTop: '170%'
          }} onClick={() => { playAudio() }} alt={post.titlePublication} />
        </div>
        <div style={{ marginRight: '70px', marginLeft: '-2rem' }}>
          <Typography >
            <div className='toplist'>
              <div>
                <font style={{ cursor: 'pointer' }} color={statusAudio === true ? 'secondary' : '#3A2872'} className='listtitle' onClick={() => { playAudio() }} size="3"  >{post.titlePublication}</font>
              </div>
              <div>
                <IconButton disabled={status} onClick={GetRections} >
                  <Badge badgeContent={like} color='error'>
                    <img src={Interativo} width='30' alt='30' />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </Typography>
          <Typography variant="body2" >
            <font style={{ fontFamily: 'Sofia Sans', color: '#3A2872' }} size="2"  > {post.DescriptionPublication}</font>
          </Typography>
        </div>
      </div>
      <div className='toplist' style={{ marginTop: '1%' }}>
        <div />
        <div>
          <font size="2" style={{ color: '#3A2872' }} > {post.durationPublication}min</font>
        </div>
      </div>
      <div style={{ marginTop: '-7%' }}>
        <font color='#59E2EB' size="80" >___________________________________________________________________________________</font>
      </div>
    </div>
  );
}

export default ListAudios;
