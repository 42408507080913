import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { CardContent } from '@mui/material';
import PodCastBlog from './PodCastBlog.js';


export default function TextMobileStepper(props) {
  const theme = useTheme();
  const { post } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = post.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <br />
        <div align="center">
          <PodCastBlog key={activeStep} post={post[activeStep]} index={activeStep} />
        </div>
      </CardContent>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button sx={{
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }}

            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Siguiente
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button sx={{
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '20px',
            fontFamily: 'Sofia Sans'
          }} onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}