import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, CssBaseline, IconButton, Link, ThemeProvider } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js';
import Foot from '../../Control/Header-Footer/foot.js';
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import PodCastBlog from './PodCastBlog.js';
import PodCastBlogMovil from './PodCastBlogMovil.js';
import Grid from '@mui/material/Grid';
import calendario from '../../Imagenes/Calendario.png'
import { styled, createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Meses } from '../Extras/Referencias.js'
import notfound from '../../Imagenes/404-error.png'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import { DatosCuriososUrl, publicationsDatosCuriosos, compUrls, linkInfociencia } from '../../Control/Autentication/urlsServer.js';
import './stylesBlog.css'
import axios from 'axios';

export default function Blog() {
  const [value, setvalue] = useState(false)
  const [mes, setMes] = useState('Enero')
  const [featuredPosts, setFeaturedPosts] = useState([])
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [InfografiaMapa, setInfografiaMapa] = useState([])

  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const GetPublications = async () => {
    await axios.get(DatosCuriososUrl, compUrls)
      .then(response => {
        setFeaturedPosts(response.data);
      });
  }

  const GetPublicationsMes = async (mesfilter) => {
    await axios.get(publicationsDatosCuriosos + mesfilter, compUrls)
      .then(response => {
        setFeaturedPosts(response.data);
      });
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    color: '#fff',
    lineHeight: 1.5,
    fontFamily: [
      'Sofia Sans',
    ].join(','),
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  const [optionButton, setOptionButton] = useState(true);

  const dataOption = (auxi) => {
    setOptionButton(auxi);
  }

  const handleChangeMeses = () => {
    setMes(document.getElementById("selecteListMeses").value)
    if(document.getElementById("selecteListMeses").value!== undefined && document.getElementById("selecteListMeses").value !==''){
      GetPublicationsMes(document.getElementById("selecteListMeses").value)
    }

  }

  const GetInfografiaMapa = async () => {
    await axios.get(linkInfociencia, compUrls)
      .then(response => {
        setInfografiaMapa(response.data);
      });
  }

  const MasCteiModulo = (
    <Container sx={{ width: '102%' }}>
      <font className='subtitle' size="5" color="#23088E">En el departamento del Cauca existe una gran variedad de proyectos, saberes y prácticas. En Ciencia&Esencia te ayudamos a conocer cómo se vive la CTeI en este departamento y cómo enterarte de otros procesos que se vienen desarrollando en la región.</font>
      <Accordion defaultExpanded={true}>
        <AccordionSummary          >
          <ExpandMoreIcon sx={{ color: '#23088E' }} />
          <Container >
            <font className='subtitle' size="5.3" color="#23088E"><strong>Artículos de interés de proyectos de CTeI en el Cauca</strong></font>
          </Container>
        </AccordionSummary>
        <AccordionDetails>
          <Container >
            {InfografiaMapa.map((info, i) => (
              info.Categoria === 'Artículos de interés de proyectos de CTeI en el Cauca' ?
                <div>
                  <Link target='_blank' color="#3A2872" href={info.link}>
                    <font className='textCont' size="5" color="#4EBBB7">{info.titulo}</font>
                  </Link>
                  <br /><font size="4" color="#23088E">{info.descripcion}</font>
                  <br /><br />
                </div>
                : ""

            ))}
          </Container>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary          >
          <ExpandMoreIcon sx={{ color: '#23088E' }} />
          <Container >
            <font className='subtitle' size="5.3" color="#23088E"><strong>Páginas de referencia</strong></font>
          </Container>
        </AccordionSummary>
        <AccordionDetails>
          <Container >
            {InfografiaMapa.map((info, i) => (
              info.Categoria !== 'Artículos de interés de proyectos de CTeI en el Cauca' ?
                <div>
                  <Link target='_blank' color="#3A2872" href={info.link}>
                    <font className='textCont' size="5" color="#4EBBB7">{info.titulo}</font>
                  </Link>
                  <br /><font size="4" color="#23088E">{info.descripcion}</font>
                  <br /><br />
                </div>
                : ""
            ))}
          </Container>
          <br />
        </AccordionDetails>
      </Accordion>
      <br /><br /><br />
    </Container>
  )

  useEffect(() => {
    GetPublications();
    GetInfografiaMapa();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div style={{ overflowX: 'hidden' }}>
      <CssBaseline />
      <div align="center">
        {widthResize > 750 ?
          <Header post={"Cauca+Ciencia"} /> : <HeaderMovil />}
      </div>
      <div className='textCont'>
        <Container >
          <br /><br />
          <div align="start">
            <font className="title" size="10">Hacer ciencia tiene su ciencia </font>
            <font size="5" color="#23088E">En el departamento del Cauca, la ciencia se vive día a día de muchas formas. Cada comunidad la ve y la interpreta de diferentes maneras según sus prácticas y saberes. </font>
            <br /><br />
            {widthResize < 750 ?
              value === true ?
                <div>
                  <font className='subtitle' size="5" color="#23088E">Es por eso que en esta sección podrás encontrar: datos curiosos o de interés en relación con proyectos CTeI, definición de términos o jerga local que usan los proyectos protagonistas de los Podcast. </font>

                </div> : "" : <font className='subtitle' size="5" color="#23088E">Es por eso que en esta sección podrás encontrar: datos curiosos o de interés en relación con proyectos CTeI, definición de términos o jerga local que usan los proyectos protagonistas de los Podcast. </font>}

            {widthResize > 750 ?
              "" : <Button sx={{
                textTransform: 'none',
                color: '#3A2872',
                fontSize: '25px',
                fontStyle: 'normal',
                fontFamily: 'Sofia Sans'
              }}
                onClick={() => setvalue(!value)} color="secondary">{value === true ? '...Leer menos' : '...Leer mas'}</Button>}
          </div>
          <br />   <br />
        </Container>
        <Container>
          <ThemeProvider theme={customTheme}>
            {widthResize > 750 ? <div>
              <BootstrapButton onClick={() => { dataOption(true) }} sx={{ width: 260, borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginLeft: '-0.3%', backgroundColor: optionButton === false ? '#00A3AD' : '#249993', height: optionButton === false ? '47px' : '57px' }} variant="contained"> Cómo vivimos la ciencia</BootstrapButton>
              <BootstrapButton onClick={() => { dataOption(false) }} sx={{ width: 260, borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginLeft: '1%', backgroundColor: optionButton === true ? '#00A3AD' : '#249993', height: optionButton === true ? '47px' : '57px' }} variant="contained">Otros datos de interés</BootstrapButton>
            </div>
              :
              <div style={{ display: 'flex' }}>
                <BootstrapButton onClick={() => { dataOption(true) }} sx={{ width: optionButton === false ? 180 : 190, borderRadius: '20px', backgroundColor: optionButton === false ? '#00A3AD' : '#1d847f' }} variant="contained"> Cómo vivimos la ciencia</BootstrapButton>
                <BootstrapButton onClick={() => { dataOption(false) }} sx={{ marginLeft: '5%', width: optionButton === false ? 180 : 190, borderRadius: '20px', backgroundColor: optionButton === true ? '#00A3AD' : '#1d847f' }} variant="contained">Otros datos de interés</BootstrapButton>
                <br />
              </div>
            }
          </ThemeProvider>
        </Container>
        {widthResize > 750 ?
          <div style={{ marginTop: '-4.5px', height: '5px', backgroundColor: '#249993', color: '#fff' }}>
            .
          </div> : ""}
        <Container>
          <br />
          {optionButton === true ?
            <div>
              <div className='topMenuInputs'>
                <div>
                  <select
                    id='selecteListMeses'
                    className='selecteListMeses'
                    defaultValue='Seleccionar'
                    onChange={handleChangeMeses}
                  >
                    {Meses.map((options) => (
                      <option
                      disabled={options==='Seleccionar'?true:false}
                        key={options} value={options}>
                        {options}
                      </option>
                    ))}
                  </select>
                </div>
                <IconButton onClick={() => { GetPublicationsMes(mes) }}>
                  <img src={calendario} width='50' alt='Busquedad' title='Buscar por mes' />
                </IconButton>
              </div>
            </div> : ""}
          <br />
        </Container>
        {optionButton === true ?
          featuredPosts.length !== 0 ?
            widthResize > 750 ?
              <Container >
                <Grid container spacing={1}>
                  {featuredPosts.map((podCastPost, i) => (
                    podCastPost.disablePublication === true ?
                      <PodCastBlog key={podCastPost.id} post={podCastPost} index={i} />
                      : ""
                  ))}
                </Grid>
              </Container>
              :
              <div align="center" style={{ marginTop: '-10%' }}>
                <PodCastBlogMovil post={featuredPosts} />
              </div>
            :
            <div align='center'> <img width='40%' src={notfound} alt={'notfound'} /> </div>
          : MasCteiModulo}
        {widthResize > 750 ? <Foot /> : <FootMovil />}
      </div>
    </div>
  );
}