import * as React from 'react';
import Compartir from '../../Imagenes/Compartir2.svg';
import Descarga from '../../Imagenes/Descarga.svg';
import CompartirPublicationMovil from '../Extras/compartirMovil'
import { Alert, CardMedia, IconButton, Modal, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { Base } from '../../Control/Autentication/urlsServer';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function FeaturedPost(props) {

  const { post } = props;
  const [modalCompartir, setModalCompartir] = useState(false);
  const [open, setOpen] = useState(false);

  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const handleClose = () => {
    setOpen(!open);
  };

  const bodyCompartir = (
    <div>
      <CompartirPublicationMovil key={post.id} post={post} />
    </div>)

  return (
    <div >
      <Typography variant="subtitle1" >
        <font style={{ lineHeight: '5px' }} color="#04B5AF" size="5"> <strong> {post.titleMaterial} </strong> </font>
      </Typography>

      <CardMedia
        height={290}
        component="img"
        sx={{ width: '100%', cursor: 'pointer' }}
        image={Base + post.imagenMaterial}
        alt={post.titleMaterial}
      />
      <div style={{ marginLeft: '35%' }}>

        {cookies.get('emailVerified') === 'true' ?
          <IconButton target='_blank' href={Base + post.materiaFile}>
            <img src={Descarga} width='40' alt='40' />
          </IconButton> :
          <IconButton onClick={() => setOpen(!open)} target='_blank' >
            <img src={Descarga} width='40' alt='40' />
          </IconButton>}
        <IconButton onClick={abrirCerrarModalCompartir} >
          <img src={Compartir} width='40' alt='40' />
        </IconButton>
      </div>
      <font size="4.8" color='#3A2872' >
        {post.DescriptionMateria}
      </font>
      <br />

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>¡Debes de iniciar sesión para descargar contenido!</Alert>
      </Snackbar>
    </div>
  );
}
export default FeaturedPost;
