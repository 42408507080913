import * as React from 'react';
import logCreatic from '../../Imagenes/LogoCreatic.jpg'
import logminci from '../../Imagenes/Minciencias.svg'
import logGob from '../../Imagenes/LogoGobCauca.jpg'
import logSGR from '../../Imagenes/SGR.jpg'

import { Button, Container } from '@mui/material';

export default function foot() {
  return (
    <div>
      <br />
      <div style={{ height: '10px', backgroundColor: '#4EBBB7' }}>
        .
      </div>
      <br />
      <Container >
        <div align="center">
          <Button target='_blank' href="https://cauca.gov.co/Paginas/Default.aspx"> <img src={logSGR} width='120' alt='logSGR' /> </Button>
          <Button target='_blank' href="https://mintic.gov.co/portal/inicio/"><img src={logminci} width='250' alt='logminci' /></Button>
          <Button target='_blank' href="https://cdtcreatic.com"> <img src={logCreatic} width='210' alt='logCreatic' /></Button>
          <Button target='_blank' href="https://cauca.gov.co/Paginas/Default.aspx"> <img src={logGob} width='230' alt='logGob' /> </Button>
        </div>
        <br />
      </Container>
    </div>
  );
}
