
import * as React from 'react';
import { Box, Button, Container, CssBaseline, Grid, InputLabel } from '@mui/material';
import Header from '../../Control/Header-Footer/Header.js'
import Foot from '../../Control/Header-Footer/foot.js'
import HeaderMovil from '../../Control/Header-Footer/HeaderMovil.js'
import Material from './MaterialApoyo.js';
import MaterialMovil from './MaterialApoyoMovil.js';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { materialFilterUrl, compUrls, materialVideoFilterUrl } from '../../Control/Autentication/urlsServer.js';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FootMovil from '../../Control/Header-Footer/footMovil.js';
import notfound from '../../Imagenes/404-error.png'
import axios from 'axios';
import './stylesMaterial.css'
import { useEffect } from 'react';

const ModulosDiplomado = ['Módulo Inicio', 'Módulo 1', 'Módulo 2', 'Módulo 3', 'Módulo 4', 'Módulo 5', 'Módulo 6', 'Módulo 7',]

export default function Proyectos() {
  const [featuredMaterial, setFeaturedMaterial] = useState([])
  const [modulo, setModulo] = useState('Módulo Inicio')
  const [value, setvalue] = useState(false)
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [video, setVideo] = useState('')

  const resize = () => {
    setWidthResize(window.innerWidth)
  }
  const GetMaterial = async () => {
    await axios.get(materialFilterUrl + 'Módulo Inicio', compUrls)
      .then(response => {
        setFeaturedMaterial(response.data);
      });
  }

  const GetMaterialfilter = async () => {
    await axios.get(materialFilterUrl + modulo, compUrls)
      .then(response => {
        setFeaturedMaterial(response.data);
        Getvideofilter();
      });
  }



  const Getvideofilter = async () => {
    await axios.get(materialVideoFilterUrl + modulo, compUrls)
      .then(response => {
        setVideo(response.data);
      });
  }
  const handleChangeModulo = () => {
    setModulo(document.getElementById("selecteListModulo").value);
  }


  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 20,
    height: 50,
    top: 28,
    lineHeight: 1.5,
    backgroundColor: '#00A3AD',
    color: '#fff',
    fontStyle: 'normal',
    fontFamily: 'Sofia Sans'
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#20b2aa',
      },
    },
  });
  if (video === '') {
    Getvideofilter();
  }
  useEffect(() => {
    GetMaterial();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);
  return (
    <div style={{ fontFamily: 'Sofia Sans' }}>

      <CssBaseline />
      <div align="center">
        {widthResize > 750 ?
          <Header post={"PeriodismoCientífico"} /> : <HeaderMovil />}
      </div>
      <Container color='#fff'>
        <br /><br />
        <font className="title" size="7" color="#4EBBB7">Entrénate en Periodismo Científico</font>
        <font size="5" style={{ fontWeight: '590px' }} color="#3A2872">La comunidad de Ciencia&Esencia, aplica estrategias y conocimientos del Periodismo Científico para la identificación y divulgación de procesos en relación con la Ciencia, Tecnología e Innovación en el Departamento del Cauca.</font><br /><br />
        {widthResize > 750 ?
          <div>
            <font size="5" color="#3A2872">Para ello, con ayuda de profesionales en diferentes áreas de la Universidad Antonio Nariño, se desarrolló un diplomado en Periodismo Científico para garantizar la calidad informativa y la exploración de la información en el departamento.</font><br /><br />
            <font size="5" color="#3A2872">Si deseas comenzar a explorar sobre los procesos que se desarrollan en tu región, te recomendamos este contenido para ayudarte a identificar procesos de gran valor y como fortalecerlos en pro de tu comunidad.</font><br /><br />
            <font className="h4" size="5" color="#3A2872"><strong>Palabras claves: </strong></font>
            <font size="5" color="#3A2872">Periodismo, periodismo científico, comunicación, estrategias, medios de comunicación, storytelling. </font>
          </div> : <div>
            {value === true ?
              <div>
                <font size="5" color="#3A2872">Para ello, con ayuda de profesionales en diferentes áreas de la Universidad Antonio Nariño, se desarrolló un diplomado en Periodismo Científico para garantizar la calidad informativa y la exploración de la información en el departamento. </font><br /><br />
                <font size="5" color="#3A2872">Si deseas comenzar a explorar sobre los procesos que se desarrollan en tu región, te recomendamos este contenido para ayudarte a identificar procesos de gran valor y como fortalecerlos en pro de tu comunidad.</font><br /><br />
                <font className="h4" size="5" color="#3A2872"><strong>Palabras claves: </strong></font>
                <font size="5" color="#3A2872">Periodismo, periodismo científico, comunicación, estrategias, medios de comunicación, storytelling. </font>
              </div> : ""}
          </div>}

        {widthResize > 750 ?
          "" : <Button sx={{
            textTransform: 'none',
            color: '#3A2872',
            fontSize: '25px',
            fontStyle: 'normal',
            fontFamily: 'Sofia Sans'
          }}
            onClick={() => setvalue(!value)} color="secondary">{value === true ? '...Leer menos' : '...Leer mas'}</Button>}

        <br />

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div className='topMenuInputs'>
            <div>
              <InputLabel shrink >
                <font className="inputLabel" size="6">Módulo</font>
              </InputLabel>
              <select
                id='selecteListModulo'
                className='selecteListMaterial'
                defaultValue={modulo}
                onChange={handleChangeModulo}
              >
                {ModulosDiplomado.map((options) => (
                  <option
                    key={options} value={options}>
                    {options}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginLeft: 10 }}>
              <ThemeProvider theme={customTheme}>
                {widthResize > 750 ?
                  <BootstrapButton sx={{
                    borderRadius: '15px'
                  }} onClick={() => { GetMaterialfilter() }} variant="contained" >
                    Buscar
                  </BootstrapButton>
                  : <BootstrapButton sx={{ marginLeft: '1px', borderRadius: '55%' }} onClick={GetMaterialfilter}  >
                    <SearchIcon sx={{ fontSize: '30px' }} />
                  </BootstrapButton>}
              </ThemeProvider>
            </div>
          </div>
        </Box>
        <br /><br />

        {video[0] !== undefined ?
          widthResize > 750 ?          
          <div align='center'>
            <iframe title="Diplomado" width='70%' height="455" src={'https://www.youtube.com/embed/' + video[0].codeVideoMaterial}></iframe> </div>:
            <iframe title="Diplomado" width='100%' height="255" src={'https://www.youtube.com/embed/' + video[0].codeVideoMaterial}></iframe>
          : ""
        }
      </Container>
      <br /><br />
      <Container color='#fff'><br />
        <font className="h4" size="5" color="#3A2872"><strong>Material de apoyo (...)</strong></font>
        <br /><br />
        <Grid container spacing={1}>

          {featuredMaterial.length !== 0 ?
            featuredMaterial.map((post, i) => (
              widthResize > 750 ? <Material key={post.title} post={post} /> : <div><MaterialMovil key={post.title} post={post} />
                {i + 1 !== featuredMaterial.length ?
                  <div><br />
                    <div style={{ background: '#4EBBB7', color: '#fff', height: '5px' }}>.</div>
                    <br /><br />
                  </div>
                  : ""}
              </div>
            )) :
            <div align='center'> <img width='50%' src={notfound} alt={'banner'} /> </div>
          }
        </Grid>
      </Container>
      {widthResize > 750 ? <Foot /> : <FootMovil />}
    </div>
  );
}