import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Badge, CardMedia, IconButton, Modal, Snackbar } from '@mui/material';
import Interativo from '../../Imagenes/interacion2.png';
import Compartir from '../../Imagenes/Compartir.png';
import coment from '../../Imagenes/coment.png';
import Descarga from '../../Imagenes/Descarga.svg';
import CompartirPublication from '../Extras/compartir.js'
import CompartirPublicationMovil from '../Extras/compartirMovil'
import Comments from '../Extras/Comments'
import CommentsMovil from '../Extras/CommentsMovil'
import { ReactionsCteiUrl, imagenInfografiasUp, ReactionsCteiPostUrl, compUrls, Base } from '../../Control/Autentication/urlsServer';
import './stylesProyecto.css'
import { useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function FeaturedPost(props) {
  const { post } = props;

  const [status, setstatus] = useState(false);
  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [like, setLike] = useState(null);
  const [modalComent, setModalComent] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileData, setfileData] = useState('');
  const [modalCompartir, setModalCompartir] = useState(false);

  const abrirCerrarModalComent = () => {
    setModalComent(!modalComent)
  }

  const handleClose = () => {
    setOpen(!open);
  };

  const resize = () => {
    setWidthResize(window.innerWidth)
  }

  const abrirCerrarModalCompartir = () => {
    setModalCompartir(!modalCompartir)
  }

  const GetRectionsinition = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        setLike(response.data.numberReaction)
      })
  }

  const GetRections = async () => {
    await axios.get(ReactionsCteiUrl + post.id, compUrls)
      .then(response => {
        const dataupdate = {
          numberReaction: response.data.numberReaction + 1,
          publicationRelations: response.data.publicationRelations,
          daterections: response.data.daterections,
        }
        axios.put(ReactionsCteiUrl + response.data.publicationRelations, dataupdate, compUrls)
          .then(response => {
            setLike(response.data.numberReaction)
            setstatus(true)
          })
      })
      .catch(error => {
        const datapost = {
          publicationRelations: post.id
        }
        axios.post(ReactionsCteiPostUrl, datapost, compUrls)
          .then(response => {
            setLike(1)
            setstatus(true)
          })
      })
  }

  const GetimagenInfografias = async () => {
    await axios.get(imagenInfografiasUp + post.id, {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': 'token 41f3dbdc5f81ace0229ee506f8eed5e6b3089f7a'
      }
    })
      .then(response => {
        setfileData(response.data);
      })
  }

  const bodyComent = (
    <div>
      {widthResize > 750 ?
        <div>
          <Comments key={post.id} post={post} />
        </div> :
        <div style={{
          height: 700,
          position: 'absolute',
          bottom: '15%',
          right: '100%',
          borderStyle: 'solid',
        }}>
          <CommentsMovil key={post.id} post={post} />
        </div>}
    </div>
  )

  const bodyCompartir = (
    <div>
      {widthResize > 750 ?
        <div>
          <CompartirPublication key={post.id} post={post} />
        </div> :
        <div >
          <CompartirPublicationMovil key={post.id} post={post} />
        </div>}
    </div>)

  if (fileData === '') {
    GetimagenInfografias();
  }

  React.useEffect(() => {
    GetRectionsinition();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  });

  return (
    <div >

      <div align="center">
        <div>
          {cookies.get('emailVerified') === 'true' ?
            <IconButton target='_blank' href={Base + fileData.infografiasFile}>
              <img src={Descarga} width='40' alt='40' />
            </IconButton> :
            <IconButton onClick={() => setOpen(!open)} target='_blank' >
              <img src={Descarga} width='40' alt='40' />
            </IconButton>}
          <IconButton onClick={abrirCerrarModalComent}>
            <img src={coment} width='40' alt='40' />
          </IconButton>
          <IconButton disabled={status} onClick={GetRections}  >
            <Badge badgeContent={like} color='error'>
              <img src={Interativo} width='40' alt='40' />
            </Badge>
          </IconButton>
          <IconButton onClick={abrirCerrarModalCompartir} >
            <img src={Compartir} width='40' alt='40' />
          </IconButton>
        </div>
        <CardMedia
          component='img'
          height={550}
          image={Base + fileData.infografiasImagen}
          alt={post.titleinfografias}
          sx={{
            minWidth: 310, maxWidth: 310, borderRadius: 8, width: 310, cursor: 'pointer', borderStyle: 'solid',
            borderColor: props.index === 0 ? "#EA5D5D" : props.index === 1 ? "#941B80" : "#F7AC5B"
          }} />
        <br />
        <div>
          <font style={{ fontFamily: 'Sofia Sans', fontWeight: 'bold' }} color="#3A2872" size="5">{post.titleinfografias} </font>
        </div>
      </div>
      <Typography variant="subtitle1" paragraph>
        <font style={{ fontFamily: 'Sofia Sans', lineHeight: '1px' }} size="5" color='#3A2872' > {post.Descriptioninfografias}</font>
      </Typography>
      <Typography variant="subtitle1" paragraph>
        <font style={{ fontFamily: 'Sofia Sans', fontWeight: 'bold' }} color="#3A2872" size="5"> Palabras claves: </font>
        <font style={{ fontFamily: 'Sofia Sans', lineHeight: '1px' }} size="5" color='#3A2872' >{post.palabrasclaves} </font>
      </Typography>
      <div />

      <Modal
        open={modalComent}
        onClose={abrirCerrarModalComent}>
        {bodyComent}
      </Modal>

      <Modal
        open={modalCompartir}
        onClose={abrirCerrarModalCompartir}>
        {bodyCompartir}
      </Modal>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>¡Debes de iniciar sesión para descargar contenido!</Alert>
      </Snackbar>
    </div>
  );
}

export default FeaturedPost;
